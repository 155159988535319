import React, { useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { UPDATE_CONTENT } from "./graphql/Mutation";
import { useApolloClient, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const RichContentEditor = ({ page }: any) => {
  const editorRef: any = useRef(null);
  const [updateHeadings] = useMutation(UPDATE_CONTENT);
  const client = useApolloClient();
  const [title, setTitle] = useState(page?.content_heading);
  const [content, setContent] = useState(page?.content_description);
  const saveData = async () => {
    const res = await updateHeadings({
      variables: {
        id: { id: page.id },
        data: {
          content_heading: title,
          content_description: content,
        },
      },
    });
    if (res?.data?.update_brd_pages_by_pk?.id) {
      toast.success("Content updated successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      await client.refetchQueries({
        include: "all",
      });
    }
  };

  return (
    <div className="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800">
      <h3 className="mb-4 text-xl font-semibold dark:text-white">
        Edit Content
      </h3>
      <div className="mb-3">
        <label
          className="block text-black mb-2 undefined"
          htmlFor="page_sub_heading_2"
        >
          Main title
        </label>
        <input
          type="text"
          value={title}
          onChange={(e: any) => setTitle(e.target.value)}
          placeholder="Title"
          className=" self-stretch w-full px-[10px] border py-2 pl-3 pr-10 text-sm leading-5 rounded text-gray-900 focus:ring-0 border-gray-200 shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 py-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
        />
      </div>
      {/* <Editor
        onInit={(evt, editor) => (editorRef.current = editor)}
        initialValue={page?.content_description}
        init={{
          height: 500,
          menubar: false,
          plugins: [
            "a11ychecker",
            "image",
            "advlist",
            "advcode",
            "advtable",
            "autolink",
            "checklist",
            "export",
            "lists",
            "link",
            "image",
            "charmap",
            "preview",
            "anchor",
            "searchreplace",
            "visualblocks",
            "powerpaste",
            "fullscreen",
            "formatpainter",
            "insertdatetime",
            "media",
            "table",
            "help",
            "wordcount",
          ],
          toolbar:
            "undo redo | casechange blocks | bold italic backcolor | " +
            "alignleft aligncenter alignright alignjustify | " +
            "bullist numlist checklist outdent indent | removeformat | a11ycheck code table help",
        }}
      /> */}
      <CKEditor
        editor={ClassicEditor}
        data={content}
        onReady={(editor) => {
          // You can store the "editor" and use when it is needed.
          console.log("Editor is ready to use!", editor);
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          setContent(data);
        }}
      />
      <button
        onClick={saveData}
        className="text-white bg-blue-700 mt-2 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
      >
        saveData
      </button>
    </div>
  );
};

export default RichContentEditor;
