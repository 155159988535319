import React, { useEffect, useState } from "react";
import { CalendarBoxProps } from "./types";
import { Controller } from "react-hook-form";
import { DatePickerInput } from "@mantine/dates";
import moment from "moment";

export const CalendarBox = ({
  control,
  errors,
  setValue,
  type,
  fieldName,
  label,
  labelClasses,
  containerClasses,
  defaultValue,
  inputClasses,
  minDate,
  maxDate,
}: CalendarBoxProps) => {
  const [date, setDate] = useState<any>(type ? [null, null] : null);

  useEffect(() => {
    if (defaultValue) {
      setValue(fieldName, defaultValue);
      setDate(moment(defaultValue).toDate());
    }
  }, []);

  return (
    <div className={`w-full ${containerClasses}`}>
      <Controller
        control={control}
        name={fieldName}
        render={({ field: { onChange } }) => (
          <DatePickerInput
            classNames={{
              input: `${inputClasses}`,
              label: `${labelClasses}`,
            }}
            className="m-0 w-full"
            type={type}
            valueFormat="MMM DD, YYYY"
            label={label}
            placeholder="Pick date"
            value={date}
            onChange={(e: any) => {
              setDate(e);
              onChange(e.toString());
            }}
            minDate={minDate ? moment(minDate).toDate() : undefined}
            maxDate={maxDate ? moment(maxDate).toDate() : undefined}
          />
        )}
      />
    </div>
  );
};
