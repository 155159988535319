import React from "react";
import { useQuery } from "@apollo/client";
// import gql queries
import { GET_PAYMENT_METHODS } from "./graphql/Query";
import travelHouseStore from "../../../../store/travelHouse/travelHouseStore";
import ProfitSlabsCard from "../../components/ProfitSlabsCard";
import { useUserDefaultRole } from "@nhost/react";

export default function ProfitSlabsList() {
  const { travelHouse }: any = travelHouseStore();
  const userDefaultRole = useUserDefaultRole();
  // get data based on query
  const { loading, data, error } = useQuery(GET_PAYMENT_METHODS, {
    variables: { th_id: travelHouse.id, role: userDefaultRole },
  });
  // return loading while loading data
  if (loading) return <div>Loading...</div>;
  // Return error if get gql error
  if (error) return <div>Error in the query {error.message}</div>;

  console.log("dataProfitSlabs", data);
  return (
    <>
      {data.profit_slabs.map((profit_slab: any, index: any) => (
        <ProfitSlabsCard key={index} profit_slab={profit_slab} />
      ))}

      {/* No Record Found */}
      {data.profit_slabs.length === 0 && <h2>No record found</h2>}
    </>

  );
}
