import React, { useEffect, useState } from "react";
import { GET_BRANDS, GET_BRANDS_PAYMENTS_METHODS } from "./graphql/Query";
import { useMutation, useQuery } from "@apollo/client";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import SingleTrasaction from "./components/SingleTrasaction";
import { useFieldArray, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import moment from "moment";
import { generateRandom10DigitCode } from "~/utils/generateRandomId";
import { CalendarBox } from "~/components/FormInputs/CalendarBox";
import useRefetch from "~/hooks/useRealtime";
import { ADD_TRANSACTION } from "./graphql/Mutation";

export default function AddManual({ setSidePopup }: any) {
  const { travelHouse }: any = travelHouseStore();
  const [isLoading, setIsLoading] = useState(false);
  const [addTransaction] = useMutation(ADD_TRANSACTION);
  const refetch = useRefetch();
  const {
    loading: brandLoading,
    data: brandData,
    error: brandError,
  } = useQuery(GET_BRANDS, {
    variables: { thp_id: travelHouse.id },
  });

  const [brdId, setBrdId] = useState(null);
  const [debit, setDebit] = useState<any>(0);
  const [credit, setCredit] = useState<any>(0);

  const {
    loading: payMethodsLoading,
    data: payMethodsData,
    error: payMethodsErrors,
  } = useQuery(GET_BRANDS_PAYMENTS_METHODS, {
    skip: !brdId, // Don't run the query unless brdId is set
    variables: { brd_id: brdId },
  });

  useEffect(() => {
    setBrdId(brandData?.brd_list[0]?.id);
  }, [brandData]);

  // Define local state for stops
  const [entries, setEntries] = useState<any>([1, 2]);

  const {
    formState: { errors },
    register,
    control,
    unregister,
    handleSubmit,
    reset,
    watch,
    setValue,
    getValues,
    resetField,
  } = useForm();

  //   const { update } = useFieldArray({ name: "tr" });

  function isUUID(input: any) {
    const uuidPattern =
      /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-4[0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;
    return uuidPattern.test(input);
  }

  const onSubmit = async (formData: any) => {
    if (debit !== credit) {
      toast.error("Debit and credit must be equal");
    } else {
      console.log(formData);
      const tenDigitCode: any = generateRandom10DigitCode();
      const transaction: any = [];

      formData.tr.forEach((tr: any) => {
        if (tr.debit || tr.credit) {
          const isValid = isUUID(tr.acc_id);
          let acc_id, def_acc_id;

          if (isValid) {
            acc_id = tr.acc_id;
            def_acc_id = null;
          } else {
            def_acc_id = tr.acc_id;
            acc_id = null;
          }

          const obj = {
            brd_id: brdId,
            acc_id: acc_id,
            def_acc_id: def_acc_id,
            payment_method_id: tr.payment_method_id || null,
            user_id: tr.user_id || null,
            transactions_no: tenDigitCode,
            date: moment(formData.date).format("YYYY-MM-DD"),
            type: tr.debit ? "credit" : "debit",
            amount: tr.debit ? -credit : debit,
          };
          transaction.push(obj);
        }
      });
      console.log("transaction", transaction);
      try {
        const res = await addTransaction({
          variables: {
            transactions: transaction,
          },
        });
        toast.success("Transaction added successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        reset();
        await refetch();
        setSidePopup(false);
      } catch (e) {
        console.error("error", e);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-full">
      <div className="w-full">
        <h2 className="font-extrabold w-full"> Journal Entry</h2>
        <hr />
        <div className="flex gap-4 w-[50%] py-4">
          <div className="w-full">
            <label htmlFor="">Select Brand</label>
            <select
              onChange={(e: any) => {
                setBrdId(e.target.value);
              }}
              className="text-sm w-full border-gray-200 py-[5px] px-4 rounded-[4px]"
            >
              {brandLoading && <option>Loading brands ...</option>}
              {!brandLoading &&
                brandData &&
                brandData.brd_list.map((brd: any) => (
                  <option key={brd.id} value={brd.id}>
                    {brd.name}
                  </option>
                ))}
            </select>
          </div>
          <CalendarBox
            control={control}
            setValue={setValue}
            errors={errors}
            fieldName="date"
            label="Date"
            defaultValue={moment(new Date()).format("YYYY-MM-DD")}
          />
        </div>
        <table className="min-w-full text-center divide-y divide-gray-200">
          <thead className="bg-gray-50 ">
            <tr>
              <th
                scope="col"
                className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 "
              >
                <div className="flex items-center gap-x-3">
                  <button className="flex items-center gap-x-2">
                    <span>#</span>
                  </button>
                </div>
              </th>
              <th
                scope="col"
                className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 "
              >
                Account
              </th>
              <th
                scope="col"
                className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 "
              >
                Debits (GBP)
              </th>

              <th
                scope="col"
                className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 "
              >
                Credits (GBP)
              </th>
              <th
                scope="col"
                className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 "
              >
                Description
              </th>

              <th
                scope="col"
                className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 "
              >
                User
              </th>
              <th
                scope="col"
                className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 "
              >
                Payment Method
              </th>
              <th
                scope="col"
                className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 "
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200 ">
            {entries.map((tr: any, index: any) => (
              <SingleTrasaction
                sr={index}
                setEntries={setEntries}
                entries={entries}
                register={register}
                errors={errors}
                watch={watch}
                debit={debit}
                credits={credit}
                setDebit={setDebit}
                setCredit={setCredit}
                getValues={getValues}
                unregister={unregister}
                setValue={setValue}
                reset={reset}
                resetField={resetField}
                paymentMethods={{
                  payMethodsLoading,
                  payMethodsData,
                  payMethodsErrors,
                }}
              />
            ))}
            <tr className="bg-gray-100">
              <td
                colSpan={2}
                className="px-4 py-4 text-sm font-medium text-gray-700 text-right"
              >
                Total (GBP)
              </td>
              <td className="px-4 text-right py-4 text-sm font-medium text-gray-700  whitespace-nowrap">
                {debit}
              </td>
              <td className="px-4 text-right py-4 text-sm font-medium text-gray-700  whitespace-nowrap">
                {credit}
              </td>
              <td colSpan={4}></td>
            </tr>
          </tbody>
        </table>
        <div className="p-4">
          <button
            onClick={() => {
              const ent = [...entries, 1];
              setEntries([...ent]);
            }}
          >
            Add Line
          </button>
        </div>
        <div className="flex gap-2 w-full pt-[50px] px-4">
          <button
            className="w-full btn bg-green-500 text-white rounded-md p-2"
            type="submit"
          >
            {isLoading ? "Submitting.." : "Submit"}
          </button>
        </div>
      </div>
    </form>
  );
}
