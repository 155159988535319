import React from "react";

export default function TransactionRow({ transaction, index }: any) {
  return (
    <tr className="">
      <td className="px-4 py-4 text-sm font-medium text-gray-700  ">
        <div className="inline-flex  gap-x-3">
          <span># {transaction?.transactions_no}</span>
        </div>
      </td>
      <td className="px-4 py-4 text-sm text-gray-500  ">
        {transaction?.brd_list?.name}
      </td>
      <td className="px-4 py-4 text-sm text-gray-500">{transaction?.date}</td>
      <td className="px-4 py-4 text-sm text-gray-500 capitalize ">
        {transaction?.acc_list?.type} {transaction?.def_acc_list?.type}
      </td>
      <td className="px-4 py-4 text-sm text-gray-500">
        {transaction?.acc_list?.name} {transaction?.def_acc_list?.name}
      </td>
      <td className="px-4 py-4 text-sm text-gray-500">
        {transaction?.inq_list?.inquiry_no || (
          <i className="text-[#ff9b00] text-[12px]">Not linked</i>
        )}
      </td>
      <td className="px-4 py-4 text-sm text-gray-500">
        {transaction?.acc_payment_method?.name || (
          <i className="text-[#ff9b00] text-[14px]">-</i>
        )}
      </td>
      <td className="px-4 py-4 text-sm text-gray-500">
        {transaction?.users?.displayName || (
          <i className="text-[#ff9b00] text-[14px]">-</i>
        )}
      </td>
      {/* <div className="inline-flex  px-3 py-1 rounded-full gap-x-2 text-emerald-500 bg-emerald-100/60 ">
          <h2 className="text-sm font-normal">{transaction?.type}</h2>
        </div> */}
      <td className="px-4 py-4 text-sm ">
        {transaction?.type === "debit" ? (
          transaction?.amount
        ) : (
          <i className="text-[#ff9b00] text-[14px]">-</i>
        )}
      </td>
      <td className="px-4 py-4 text-sm">
        {transaction?.type === "credit" ? (
          -transaction?.amount
        ) : (
          <i className="text-[#ff9b00] text-[14px]">-</i>
        )}
      </td>
    </tr>
  );
}
