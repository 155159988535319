import React from "react";
import InquiresHead from "./components/InquiresPagination/InquiresHead";
import InquiryRow from "./components/InquiryRow";
import InquiresPagination from "./components/InquiresPagination";
import NoDataFound from "~/components/common/NoDataFound";

export default function InquiryTable({ inquires, page, total, setPage }: any) {
  return (
    <>
      {inquires.length > 0 ? (
        <>
          <div className="flex flex-col">
            <div className="overflow-x-auto">
              <div className="inline-block min-w-full align-middle">
                <div className="overflow-hidden shadow">
                  <table className="min-w-full divide-y divide-gray-200 table-fixed dark:divide-gray-600">
                    <InquiresHead />
                    <tbody className="bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700">
                      {inquires.map((inquiry: any, index: any) => (
                        <InquiryRow key={index} inquiry={inquiry} />
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <InquiresPagination
            count={inquires.length}
            page={page}
            total={total}
            setPage={setPage}
          />
        </>
      ) : (
        <NoDataFound />
      )}
    </>
  );
}
