import React, { useEffect, useState } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
// import gql queries
import {
  GET_ACTIVE_MEMBERS_WITH_BRANDS,
  GET_ACTIVE_MEMBERS_WITH_THP,
} from "./graphql/Query";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import UserAdd from "components/UserCard/components/UserAdd";
import SidePopup from "~/components/common/SidePopup";
import UsersGroup from "./components/UsersGroup";
import { useUserId } from "@nhost/react";
import useAssignedBrands from "~/hooks/useAssignedBrands";
import NoDataFound from "~/components/common/NoDataFound";

export default function ActiveMembers() {
  const { travelHouse }: any = travelHouseStore();
  const [getBrandsMembers] = useLazyQuery(GET_ACTIVE_MEMBERS_WITH_BRANDS);
  const [getAllMembers] = useLazyQuery(GET_ACTIVE_MEMBERS_WITH_THP);
  let dataRes: any = null;

  const userId = useUserId();

  const [data, setData] = useState<any>([]);
  const assignedBrands = useAssignedBrands();
  // get data based on query

  const fetchMembers = async () => {
    console.log("xwt user id ===>", userId);
    console.log("xwt ===> brands ===>", assignedBrands);
    if (assignedBrands) {
      dataRes = await getBrandsMembers({
        variables: { brands: assignedBrands },
      });
      console.log("xwt data check ===>", dataRes);
      setData(dataRes);
    } else {
      dataRes = await getAllMembers({
        variables: { thp_id: travelHouse?.id },
      });

      console.log("xwt data check 2 ===>", dataRes);
      setData(dataRes);
    }

    console.log("xwt data ===>", dataRes);
  };

  useEffect(() => {
    fetchMembers();
  }, []);

  // const { loading, data, error } = dataRes;
  // return loading while loading data
  if (data?.loading) return <div>Loading...</div>;
  // Return error if get gql error
  if (data?.error) return <div>Error in the query {data?.error.message}</div>;
  if (data?.data?.users?.length > 0) {
    return (
      <>
        <UsersGroup users={data?.data?.users} role="admin" />
        <UsersGroup users={data?.data?.users} role="consultant" />
        <UsersGroup users={data?.data?.users} role="accountant" />
        <SidePopup
          text="Add Team Member"
          component={<UserAdd defaultRole={"team"} />}
        />
      </>
    );
  } else {
    return <p>Loading...</p>;
  }
}
