import { useApolloClient, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { EDIT_INQUIRY } from "../../../../graphql/Mutation";
import { toast } from "react-toastify";
import { useUserDefaultRole, useUserDisplayName } from "@nhost/react";
import useNotifications from "hooks/useNotifications";
import useEmail from "~/hooks/emails/useEmail";

export default function SuggestionUnSelect({
  inquiry_id,
  suggestion_id,
  picked_by,
  user_id,
  inquiry,
}: any) {
  const [modalOpen, setModalOpen] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const client = useApolloClient();
  const alert = useNotifications();
  const userRole = useUserDefaultRole();
  const emailSend = useEmail();
  const userName = useUserDisplayName();
  const [editInquiry] = useMutation(EDIT_INQUIRY);
  const handleConfirm = async (event: any) => {
    event.stopPropagation();
    setIsloading(true);

    try {
      // Create passenger function call
      const res = await editInquiry({
        variables: { id: inquiry_id, selected_suggestion_id: null },
      });
      if (res.data?.update_inq_list?.returning?.length > 0) {
        toast.success("Suggestion Unselected successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });

        if (userRole === "user") {
          await alert.newNotification(
            picked_by,
            `Your customer has unselected fare options for you.`,
            "",
            ``
          );
        } else {
          const variables: any = {
            inquiryNo: inquiry?.inquiry_no,
            userFirstName: inquiry?.users?.displayName,
            userLastName: "",
            consultantName: userName,
            brandName: inquiry?.brd_list?.name,
          };

          // console.log("inquiryPicked", inquiry);
          await emailSend(6, inquiry.brd_id, inquiry.users?.email, variables);

          await alert.newNotification(
            user_id,
            `Your consultant has unselected fare options for you.`,
            "",
            ``
          );
        }
        await client.resetStore();
      }
      //   setIsloading(false); // change button loading state
      setModalOpen(false);
      setIsloading(false);
    } catch (e) {
      setModalOpen(false);
      setIsloading(false);
      console.error("error", e);
    }
  };
  return (
    <>
      <span
        onClick={() => {
          setModalOpen(true);
        }}
        className=" bg-primary cursor-pointer rounded-[5px] mt-2 py-2 px-5 text-white absolute top-[-1px] right-[130px]"
      >
        {isloading ? "Unselecting.." : "Unselect"}
      </span>

      {modalOpen && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
          style={{ zIndex: 50 }}
        >
          <div className="relative bg-white rounded-xl shadow-2xl p-8 w-11/12 sm:w-3/4 md:w-1/2 lg:w-1/3">
            <h2 className="mb-6 text-2xl font-bold text-gray-700">
              Are you sure?
            </h2>
            <div className="flex justify-end space-x-4">
              <button
                type="button"
                onClick={(event: any) => setModalOpen(false)}
                className="px-5 py-2 font-semibold text-gray-700 bg-red-500 bg-opacity-10 rounded-full hover:bg-opacity-20"
              >
                No
              </button>
              <button
                onClick={(event: any) => handleConfirm(event)}
                className="px-5 py-2 font-semibold text-white bg-green-500 rounded-full hover:bg-green-600"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
