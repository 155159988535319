import { gql } from "@apollo/client";

export const GET_VENDORS = gql`
  query GetVendors($thp_id: uuid!) {
    users(
      where: {
        brd_users: {
          thp_id: { _eq: $thp_id }
          users: { defaultRole: { _eq: "vendor" } }
        }
      }
    ) {
      email
      id
      displayName
      phoneNumber
      metadata
      defaultRole
      disabled
      brd_users {
        user_id
        brd_id
        thp_id
      }
    }
  }
`;
