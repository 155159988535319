import React, { useState } from "react";
import EditFAQs from "./components/EditFAQs";
import { GET_BRNAD_FAQs } from "./graphql/Query";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { AiFillSetting } from "react-icons/ai";
import FAQSettings from "./components/FAQSettings";
import AddFAQs from "./components/AddFAQs";
import FAQRow from "./components/FAQRow";
import NoDataFound from "~/components/common/NoDataFound";
import { FaExclamationCircle, FaNotEqual } from "react-icons/fa";

const ManageFAQs = () => {
  const { brd_id } = useParams();
  const [settings, setSettings] = useState(false);
  const [addFAQ, setAddFAQ] = useState(false);
  const { loading, data } = useQuery(GET_BRNAD_FAQs, {
    variables: { id: brd_id },
  });
  if (loading) return <p>Loading...</p>;
  const faqs = data?.brd_faq;
  console.log("xwt faqs ===>", faqs);
  return (
    <>
      <div className="p-4 block sm:flex items-center justify-between border-b border-gray-200 lg:mt-1.5 dark:bg-gray-800 dark:border-gray-700">
        <div className="w-full mb-1">
          <div className="mb-4">
            <h1 className="text-xl font-semibold text-gray-900 sm:text-2xl dark:text-white">
              All Faqs
            </h1>
          </div>
          <div className="items-center justify-between block sm:flex md:divide-x md:divide-gray-100 dark:divide-gray-700">
            <div className="flex items-center mb-4 sm:mb-0">
              <form className="sm:pr-3" action="#" method="GET">
                <label htmlFor="faqs-search" className="sr-only">
                  Search
                </label>
                <div className="relative w-48 mt-1 sm:w-64 xl:w-96">
                  <input
                    type="text"
                    name="email"
                    id="faqs-search"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Search for faqs"
                  />
                </div>
              </form>
              <div className="flex items-center w-full sm:justify-end">
                <div className="flex pl-2 space-x-1">
                  <button
                    onClick={() => setSettings(true)}
                    id="faqSettingButton"
                    className="inline-flex justify-center p-1 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                    type="button"
                    data-drawer-target="drawer-faq-setting-default"
                    data-drawer-show="drawer-faq-setting-default"
                    aria-controls="drawer-faq-setting-default"
                    data-drawer-placement="right"
                  >
                    <AiFillSetting fontSize={25} />
                  </button>
                </div>
              </div>
            </div>
            <button
              onClick={() => setAddFAQ(true)}
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
              type="button"
            >
              Add New FAQ
            </button>
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full align-middle">
            <div className="overflow-hidden shadow">
              {faqs?.length > 0 ? (
                <table className="min-w-full divide-y divide-gray-200 table-fixed dark:divide-gray-600">
                  <thead className="bg-gray-100 dark:bg-gray-700">
                    <tr>
                      <th scope="col" className="p-4">
                        <div className="flex items-center">
                          <input
                            id="checkbox-all"
                            aria-describedby="checkbox-1"
                            type="checkbox"
                            className="w-4 h-4 border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
                          />
                          <label htmlFor="checkbox-all" className="sr-only">
                            checkbox
                          </label>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="p-4 text-xs font-medium text-left text-gray-500 uppercase dark:text-gray-400"
                      >
                        Destination Name
                      </th>
                      <th
                        scope="col"
                        className="p-4 text-xs font-medium text-left text-gray-500 uppercase dark:text-gray-400"
                      >
                        Category
                      </th>
                      <th
                        scope="col"
                        className="p-4 text-xs font-medium text-left text-gray-500 uppercase dark:text-gray-400"
                      >
                        Question
                      </th>
                      <th
                        scope="col"
                        className="p-4 text-xs font-medium text-left text-gray-500 uppercase dark:text-gray-400"
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700">
                    {faqs?.length > 0 &&
                      faqs?.map((faq: any, i: number) => (
                        <FAQRow key={i} faq={faq} />
                      ))}
                  </tbody>
                </table>
              ) : (
                <NoDataFound
                  icon={
                    <FaExclamationCircle className="text-[30px] text-red-500" />
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {addFAQ && <AddFAQs setAddFAQ={setAddFAQ} addFAQ={addFAQ} />}
      {settings && (
        <FAQSettings settings={settings} setSettings={setSettings} />
      )}
    </>
  );
};

export default ManageFAQs;
