import { useQuery } from "@apollo/client";
import { useState } from "react";
import travelHouseStore from "store/travelHouse/travelHouseStore";
import { GET_PAYMENT_METHODS } from "./graphql/Query";
import SelectPayType from "./components/SelectPayType";
import BankPaymentForm from "./components/BankPaymentForm";
import StripePaymentForm from "./components/StripePaymentForm";
import { FaTimes } from "react-icons/fa";
import { useUserDefaultRole } from "@nhost/react";

const PaymentAdd = ({
  setHideForm,
  user,
  inq,
  picked_by,
  inquiry_id,
  brd_id,
  payment_method_id,
  brd_domain,
}: any) => {
  const [paytype, setPayType] = useState(payment_method_id ? "bank" : "");
  const [stripe, setStripe] = useState<any>({});

  const { travelHouse }: any = travelHouseStore();
  const userRole = useUserDefaultRole();
  // get data based on query
  const { loading, data, error } = useQuery(GET_PAYMENT_METHODS, {
    variables: { th_id: travelHouse.id, brd_id: brd_id },
  });
  // return loading while loading data
  if (loading) return <div>Loading...</div>;
  // Return error if get gql error
  if (error) return <div>Error in the query {error.message}</div>;

  return (
    <div className="self-stretch relative flex-1 bg-white-100 flex flex-col p-[25px] items-start justify-between text-left text-mini text-darkslategray  border-[1px] border-solid border-lightsteelblue">
      <div className="self-stretch flex flex-col items-center justify-between">
        <button
          onClick={() => {
            setHideForm(false);
          }}
          className="absolute top-0 right-[5px] m-2"
        >
          <FaTimes />
        </button>

        {/* PayTypeSelect Payment Form */}
        {userRole !== "user" && (
          <SelectPayType
            paymentMethods={data.acc_payment_methods}
            setPayType={setPayType}
            setStripe={setStripe}
          />
        )}

        {/* Add Bank Payment Form */}
        {paytype === "bank" && (
          <BankPaymentForm
            inquiry={inq}
            inquiry_id={inquiry_id}
            setHideForm={setHideForm}
            user_email={inq?.users?.email}
            brand_url={inq.brd_list.subdomain}
            brand_name={inq.brd_list.name}
            picked_by={picked_by}
            user_id={user?.id}
            payment_method_id={payment_method_id}
            paymentMethods={data.acc_payment_methods.filter(
              (pay: any) => pay.type === "bank"
            )}
          />
        )}

        {/* Add Stripe Payment Form */}
        {paytype === "stripe" && (
          <StripePaymentForm
            stripe={stripe}
            user={user}
            brd_domain={brd_domain}
            brd_id={brd_id}
            user_email={inq?.users?.email}
            brand_url={inq.brd_list.subdomain}
            brand_name={inq.brd_list.name}
            inquiry_id={inquiry_id}
            setHideForm={setHideForm}
            picked_by={picked_by}
          />
        )}

        {/* If paytype not selected*/}
        {paytype === "" && <h3>Payment method not selected</h3>}
      </div>
    </div>
  );
};

export default PaymentAdd;
