import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
// import { EDIT_USER } from "../../graphql/Mutation";
import { useMutation, useApolloClient, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { useUserId } from "@nhost/react";
import InputField from "components/FormInputs/InputField";
import PhoneBox from "components/FormInputs/PhoneBox";
import AddressBox from "components/FormInputs/AddressBox";
import CountryBox from "components/FormInputs/CountryBox";
import { GET_BRANDS } from "./graphql/Query";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import { EDIT_USER } from "./graphql/Mutation";
import BrandsAssign from "./components/BrandsAssign";
import { useUserDefaultRole } from "@nhost/react";
import { GET_ALIASES } from "../UserAdd/graphql/Query";
import { GET_TRAVEL_HOUSE_BY_ID } from "~/modules/InboxModule/segments/Inbox/graphql/Query";

export default function UserEdit({ user, setEdit, brands }: any) {
  const userId = useUserId();
  const { travelHouse }: any = travelHouseStore();
  const userRole = useUserDefaultRole();
  const {
    loading: loadingBrands,
    error: brandsError,
    data: brd_list,
  } = useQuery(GET_BRANDS, {
    variables: { thp_id: travelHouse.id },
  });

  const [editUser] = useMutation(EDIT_USER);
  const client = useApolloClient();
  const [countryCode, setCountryCode] = useState(
    user?.metadata?.countryCode || ""
  );
  const {
    formState: { errors },
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
  } = useForm();

  const country = watch("country");
  useEffect(() => {
    if (country?.code) {
      setCountryCode(country.code.toLowerCase());
    } else {
      setCountryCode("");
    }
  }, [country]);

  const { loading: travelHouseLoading, data: travelHouseData } = useQuery(
    GET_TRAVEL_HOUSE_BY_ID,
    {
      variables: { id: travelHouse?.id },
      fetchPolicy: "network-only",
    }
  );

  const { data: getAliasData, loading: getAliasLoading } = useQuery(
    GET_ALIASES,
    {
      variables: {
        refresh_token: travelHouseData?.thp_list[0]?.email_refresh_token,
        th_id: travelHouse.id,
        page: "",
        nextPageToken: "",
        pageSize: "1",
        body: "",
        sendEmail: false,
        subject: "",
        to: "",
        getNewEmailsFlag: false,
        historyId: "",
        aliasDisplayName: "",
        aliasEmail: "",
        createAlias: false,
        getAlias: true,
        from: "",
        alias: false,
        replyFlag: false,
        messageId: "",
        query: false,
        queryData: "",
      },
    }
  );

  const onSubmit = async (data: any) => {
    if (brands === true && data?.brands?.length <= 0) {
      toast.error("At least one brand is required for update!");
      return 0;
    }
    console.log("data", data.disabled);
    let brd_users: any = [];

    if (brands) {
      if (typeof data.brands === "string") {
        brd_users.push({
          thp_id: travelHouse?.id,
          brd_id: data.brands,
          user_id: user.id,
        });
      } else {
        data.brands.forEach((brd_id: any) => {
          brd_users.push({
            thp_id: travelHouse?.id,
            brd_id: brd_id,
            user_id: user.id,
          });
        });
      }
    } else {
      console.log("user", user);
      user.brd_users.forEach((brd: any) => {
        brd_users.push({
          thp_id: brd?.thp_id,
          brd_id: brd?.brd_id,
          user_id: brd?.user_id,
        });
      });
    }
    const userMetadata: any = user.metaData;
    const metaData = {
      ...userMetadata,
      addressLine1: data.location.addressLine1,
      addressLine2: data.location.addressLine2,
      countryCode: data.country.code,
      countryName: data.country.label,
      city: data.location.city,
      postalCode: data.location.postalCode,
      alias: data?.alias || "",
    };
    const payload = {
      id: user.id,
      newUser: {
        displayName: data.displayName,
        email: data.email,
        phoneNumber: data.phoneNumber,
        defaultRole:
          user.defaultRole === "user" ? user.defaultRole : data.defaultRole,
        metadata: metaData,
        disabled: data.disabled === "1" ? false : true,
      },
      newUserBrands: brd_users,
    };
    console.log("payload", payload);
    try {
      const res = await editUser({ variables: payload });
      if (res.data?.updateUsers?.affected_rows > 0) {
        setEdit(false);
        await client.resetStore();
        toast.success("User Updated successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    } catch (e) {
      console.log(e);
      toast.error(`${e}`);
    }
  };

  // return loading while loading data
  if (loadingBrands) return <div>Loading...</div>;
  // Return error if get gql error
  if (brandsError) return <div>Error in the query {brandsError.message}</div>;

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-full">
      <h2 className="flex justify-center font-extrabold	">Edit User</h2>
      <div>
        <div className="mt-2 mb-2">
          {user.defaultRole !== "user" && user.defaultRole !== "vendor" && (
            <select
              {...register("defaultRole", { required: true })}
              className="bg-gray-50 mb-4 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option
                value="consultant"
                selected={user.defaultRole === "consultant" ? true : false}
              >
                Consultant
              </option>
              <option
                value="accountant"
                selected={user.defaultRole === "accountant" ? true : false}
              >
                Accountant
              </option>
              <option
                value="admin"
                selected={user.defaultRole === "admin" ? true : false}
              >
                Admin
              </option>
            </select>
          )}
          <InputField
            register={register}
            errors={errors}
            fieldName="displayName"
            required={true}
            placeHolder="User Name"
            defaultValue={user.displayName}
          />
        </div>
        <div className="mt-2 mb-2">
          <InputField
            type="email"
            register={register}
            errors={errors}
            fieldName="email"
            required={true}
            placeHolder="User Email"
            defaultValue={user.email}
          />
        </div>
        {userRole === "admin" && user.defaultRole !== "user" && (
          <>
            {getAliasLoading ? (
              "Loading..."
            ) : (
              <select
                {...register("alias")}
                defaultValue={user?.metadata?.alias}
                className="bg-gray-50 mb-4 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              >
                <option value="">No Alias</option>
                {getAliasData?.getGmailMessages?.aliases?.map((email: any) => (
                  <option key={email.sendAsEmail} value={email.sendAsEmail}>
                    {email.sendAsEmail}
                  </option>
                ))}
              </select>
            )}
          </>
        )}
        <div className="mt-2 mb-2">
          <PhoneBox
            control={control}
            errors={errors}
            fieldName="phoneNumber"
            defaultValue={user.phoneNumber}
            setValue={setValue}
          />
        </div>
        <div className="mt-2 mb-2">
          <CountryBox
            control={control}
            errors={errors}
            fieldName="country"
            setValue={setValue}
            placeholder="Country"
            defaultValue={user?.metadata?.countryCode}
          />
        </div>
        <div className="mt-2 mb-2">
          <AddressBox
            register={register}
            errors={errors}
            fieldName="location"
            setValue={setValue}
            defaultValue={{
              postalCode: user?.metadata?.postalCode,
              region: user?.metadata?.region,
              city: user?.metadata?.city,
              addressLine1: user?.metadata?.addressLine1,
              addressLine2: user?.metadata?.addressLine2,
            }}
            countryCode={countryCode}
          />
        </div>

        {userId !== user.id && (
          <div>
            <select
              {...register("disabled")}
              className="bg-gray-50 mb-4 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option value="1">Active</option>
              <option value="0" selected={user?.disabled}>
                Suspend
              </option>
            </select>
          </div>
        )}
      </div>

      {brands && (
        <BrandsAssign
          brd_list={brd_list.brd_list}
          user={user}
          register={register}
        />
      )}

      <div className="flex gap-2 w-full">
        <button
          className="w-full button btn bg-red-500 text-white  rounded-md p-2"
          onClick={() => {
            setEdit(false);
          }}
        >
          Close
        </button>
        <button
          className="w-full btn bg-green-500 text-white rounded-md p-2"
          type="submit"
        >
          Save
        </button>
      </div>
    </form>
  );
}
