import { gql } from "@apollo/client";

export const GET_ALL_INQUIRIES = gql`
  query GetInquiries($thp_id: uuid!, $limit: Int!, $offset: Int!) {
    inq_list(
      where: { thp_id: { _eq: $thp_id }, status: { _eq: "completed" } }
      order_by: { created_at: desc }
      limit: $limit
      offset: $offset
    ) {
      direct_flight
      flexible_arrival
      flexible_departure
      approx_budget
      from
      inquiry_no
      preferred_airline
      source
      status
      to
      trip_type
      arrival_datetime
      created_at
      departure_datetime
      updated_at
      brd_id
      id
      payment_method_id
      picked_by
      suggestion_id
      thp_id
      user_id
      brd_list {
        name
        domain
        subdomain
      }
      users {
        id
        email
        displayName
        metadata
        phoneNumber
        avatarUrl
        brd_users {
          user_id
          brd_id
          thp_id
        }
      }
      picked_user {
        id
        avatarUrl
        metadata
        email
        displayName
        phoneNumber
      }
      inq_passengers {
        type
      }
    }
  }
`;

export const GET_ALL_INQUIRIES_COUNT = gql`
  query GetInquiriesCount($thp_id: uuid!) {
    inq_list_aggregate(
      where: { thp_id: { _eq: $thp_id }, status: { _eq: "completed" } }
    ) {
      aggregate {
        count
      }
    }
  }
`;
