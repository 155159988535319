import { gql } from "@apollo/client";

export const GET_ACTIVE_MEMBERS_WITH_BRANDS = gql`
  query CustomerQuery($brands: [uuid!]!) {
    users(
      where: {
        brd_users: {
          brd_id: { _in: $brands }
          users: { defaultRole: { _neq: "user" } }
        }
      }
    ) {
      id
      email
      displayName
      phoneNumber
      metadata
      defaultRole
      disabled
      brd_users {
        user_id
        brd_id
        thp_id
      }
    }
  }
`;

export const GET_ACTIVE_MEMBERS_WITH_THP = gql`
  query CustomerQuery($thp_id: uuid!) {
    users(
      where: {
        brd_users: {
          thp_id: { _eq: $thp_id }
          users: { defaultRole: { _neq: "user" } }
        }
      }
    ) {
      id
      email
      displayName
      phoneNumber
      metadata
      defaultRole
      disabled
      brd_users {
        user_id
        brd_id
        thp_id
      }
    }
  }
`;
