import { gql } from "@apollo/client";

export const GET_ROLE_USERS = gql`
  query GetUserTravelHouse($th_id: uuid, $role: String) {
    brd_users(
      where: { thp_id: { _eq: $th_id }, users: { defaultRole: { _eq: $role } } }
    ) {
      user_id
    }
  }
`;

export const GET_ALL_USERS = gql`
  query GetUserTravelHouse($th_id: uuid) {
    brd_users(
      where: { thp_id: { _eq: $th_id } }
      order_by: { created_at: desc }
    ) {
      user_id
    }
  }
`;

export const GET_USER_BY_PHONE = gql`
  query GetUser($phone: String) {
    users(where: { phoneNumber: { _eq: $phone } }) {
      id
    }
  }
`;
export const GET_USER_BY_EMAIL = gql`
  query GetUser($email: citext) {
    users(where: { email: { _eq: $email } }) {
      id
    }
  }
`;

export const GET_ASSIGNED_BRANDS = gql`
  query GetAssignedBrands($id: uuid!) {
    brd_users(where: { user_id: { _eq: $id } }, distinct_on: brd_id) {
      brd_id
    }
  }
`;
