import { gql } from "@apollo/client";

export const INSERT_DESTINATION = gql`
  mutation InsertFlightPages($data: [brd_flights_pages_insert_input!]!) {
    insert_brd_flights_pages(objects: $data) {
      affected_rows
      returning {
        id
        brd_id
      }
    }
  }
`;
