import React from 'react'

const PipeLine = () => {
  return (
    <svg width="2" height="34" viewBox="0 0 2 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L0.999999 33" stroke="#393939" stroke-opacity="0.5" stroke-linecap="round"/>
    </svg>

  )
}

export default PipeLine