import moment from "moment";
import React, { useState } from "react";
import ViewIcon from "modules/InquiriesModule/segments/InquiryDetail/assets/svg/ViewIcon";
import { useUserDefaultRole } from "@nhost/react";
import { URLs } from "~/config/enums";
import PaymentEdit from "./components/PaymentEdit";
import { FaTimes } from "react-icons/fa";

const PaymentCard = ({ transaction, inquiry }: any) => {
  const [showReceipt, setShowReceipt] = useState(false);
  const role = useUserDefaultRole();

  const [editForm, setEditForm] = useState(false);
  return (
    <>
      {!editForm ? (
        <>
          <div className="self-stretch min-h-[250px] flex-1 bg-white-100 flex flex-col p-[25px] items-start justify-between text-left text-mini text-darkslategray border-[1px] border-solid border-lightsteelblue">
            <div className="self-stretch flex flex-row items-center justify-between">
              <div className="relative">
                <span>Reference #</span>
                <b>{transaction?.ref_no}</b>
              </div>
              <div className="w-[41px] flex flex-row items-center justify-end gap-[8px]">
                {transaction?.acc_payment_method?.type === "bank" && (
                  <button onClick={() => setShowReceipt(true)}>
                    <ViewIcon />
                  </button>
                )}
              </div>
            </div>
            <div className="self-stretch flex flex-row items-end justify-start gap-[10px] text-right text-29xl">
              <div className="relative w-32 h-12">
                <b className="absolute top-[0px] left-[2px] leading-[130%]">
                  £{transaction?.amount}
                </b>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-center justify-start gap-[10px] text-base">
              <div className="relative">
                <p className="m-0">Payment Date</p>
                <p className="m-0">
                  <b>{moment(transaction?.updated_at).format("DD-MM-YYYY")}</b>
                </p>
              </div>
              <div></div>
              <div className="flex-1 relative text-right">
                {transaction.status === "pending" ? (
                  <>
                    {transaction?.acc_payment_method?.type === "bank" ? (
                      <p className="m-0 text-tomato">
                        <b>Awaiting Confirmation</b>
                      </p>
                    ) : (
                      <>
                        {role === "consultant" ? (
                          <p className="m-0 text-tomato">
                            <b>Awaiting Payment</b>
                          </p>
                        ) : (
                          <a
                            className="bg-[#2373c5] p-4 rounded-md text-white"
                            href={transaction.receipt}
                            target="_blank"
                          >
                            Pay
                          </a>
                        )}
                      </>
                    )}
                  </>
                ) : transaction.status === "paid" ? (
                  <>
                    <p className="m-0 text-green-500">
                      <b>Paid</b>
                    </p>
                  </>
                ) : (
                  <>
                    <button
                      onClick={() => {
                        setEditForm(true);
                      }}
                      className="bg-[#2373c5] p-4 rounded-md text-white"
                    >
                      Pay
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>

          {showReceipt && (
            <div className="fixed z-[999] top-0 left-0 w-full min-h-screen bg-[#00000070] flex justify-center items-center py-[100px]">
              <div className="container bg-white rounded-[10px] p-[30px] max-w-[900px] m-[20px] w-full relative">
                <button
                  onClick={() => setShowReceipt(false)}
                  className="text-[16px] text-white flex justify-center items-center h-[25px] w-[25px] bg-red-500 rounded-full absolute right-[-10px] top-[-10px]"
                >
                  x
                </button>
                <div className="">
                  {transaction?.receipt ? (
                    <img src={`${URLs.FILE_URL}${transaction?.receipt}`} />
                  ) : (
                    <p className="text-center">No file available</p>
                  )}
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="self-stretch relative flex-1 bg-white-100 flex flex-col p-[25px] items-start justify-between text-left text-mini text-darkslategray  border-[1px] border-solid border-lightsteelblue">
          <div className="self-stretch flex flex-col items-center justify-between">
            <button
              onClick={() => {
                setEditForm(false);
              }}
              className="absolute top-0 right-[5px] m-2"
            >
              <FaTimes />
            </button>
            <PaymentEdit
              inquiry={inquiry}
              setEditForm={setEditForm}
              transaction={transaction}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default PaymentCard;
