import { gql } from "@apollo/client";

export const GET_PAYMENT_METHODS = gql`
  query GetPaymentMethods($th_id: uuid!, $brd_id: uuid!) {
    acc_payment_methods(
      where: { thp_id: { _eq: $th_id }, brd_id: { _eq: $brd_id } }
      order_by: { created_at: desc }
    ) {
      account_no
      name
      account_title
      stripe_pub
      stripe_secret
      default
      swift_code
      type
      created_at
      updated_at
      id
      thp_id
    }
  }
`;

export const GET_BRANDS = gql`
  query GetUserBrand($brands: [uuid!]!) {
    brd_list(where: { id: { _in: $brands } }) {
      id
      name
    }
  }
`;
export const GET_BRANDS_THP = gql`
  query GetUserBrand($thp_id: uuid!) {
    brd_list(where: { thp_id: { _eq: $thp_id } }) {
      id
      name
    }
  }
`;

export const GET_USER = gql`
  query GetUsers($role: String, $brd_id: uuid!) {
    users(
      where: {
        brd_users: {
          brd_id: { _eq: $brd_id }
          users: { defaultRole: { _eq: $role } }
        }
      }
    ) {
      email
      id
      displayName
      phoneNumber
      metadata
      defaultRole
      disabled
      brd_users {
        user_id
        brd_id
        thp_id
      }
    }
  }
`;
