import { gql } from "@apollo/client";

export const INSERT_POST = gql`
  mutation InsertPost($data: [brd_posts_insert_input!]!) {
    insert_brd_posts(objects: $data) {
      affected_rows
      returning {
        id
        brd_id
      }
    }
  }
`;
