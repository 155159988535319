import React from "react";
import { SideBarPopupButtonProps } from "../../types";
import { FaPlus } from "react-icons/fa";

const SidePopupButton = ({
  icon,
  className,
  setShow,
  show,
  text,
}: SideBarPopupButtonProps) => {
  return (
    <button
      type="button"
      onClick={() => setShow(true)}
      className={`p-4 rounded-full bg-primary cursor-pointer shadow-lg fixed right-[50px] bottom-[50px] z-[2] flex items-center justify-center ${className}`}
    >
      {icon ? icon : <FaPlus className="text-white text-[20px]" />}
      <span className="text-white pl-2">{text}</span>
    </button>
  );
};

export default SidePopupButton;
