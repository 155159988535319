import { gql } from "@apollo/client";

export const EDIT_EMAIL_TEMPLATE = gql`
  mutation MyMutation($id: uuid, $data: brd_emails_templates_set_input!) {
    update_brd_emails_templates(where: { id: { _eq: $id } }, _set: $data) {
      affected_rows
      returning {
        id
      }
    }
  }
`;
