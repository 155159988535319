import React from "react";
import ActiveMembers from "./components/ActiveMembers";
import InvitedMembers from "./components/InvitedMembers";

export default function TeamList() {
  return (
    <>
      <ActiveMembers />
      {/* <InvitedMembers /> */}
    </>
  );
}
