import { Navigate } from "react-router-dom";
import { useAuthenticationStatus, useUserDefaultRole } from "@nhost/react";
import LoginPage from "../modules/AuthModule/segments/LoginPage";

function Login() {
  const { isAuthenticated, isLoading } = useAuthenticationStatus(); //get nhost authentication status
  const userDefaultRole = useUserDefaultRole();

  if (isLoading) return <div>Loading...</div>;

  if (isAuthenticated) return <Navigate to={`../${userDefaultRole}`} />;

  return <LoginPage />;
}

export default Login;
