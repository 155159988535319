import { gql } from "@apollo/client";

export const EDIT_INQUIRY = gql`
  mutation EditInquiry($id: uuid, $selected_suggestion_id: uuid) {
    update_inq_list(
      where: { id: { _eq: $id } }
      _set: { suggestion_id: $selected_suggestion_id }
    ) {
      returning {
        id
      }
    }
  }
`;
