import {
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import AddressBox from "components/FormInputs/AddressBox";
import InputField from "components/FormInputs/InputField";
import PhoneBox from "components/FormInputs/PhoneBox";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import travelHouseStore from "store/travelHouse/travelHouseStore";
import {
  GET_ALIASES,
  GET_BRANDS_WITH_BRD,
  GET_BRANDS_WITH_THP,
} from "./graphql/Query";
import { ADD_USER } from "./graphql/Mutation";
import BrandsAssign from "./components/BrandsAssign";
import commonStore from "~/store/commonStore/commonStore";
import { GET_TRAVEL_HOUSE_BY_ID } from "~/modules/InboxModule/segments/Inbox/graphql/Query";
import useRefetch from "~/hooks/useRealtime";
import useValidation from "~/hooks/useValidation";
import useAssignedBrands from "~/hooks/useAssignedBrands";
import { useEffect, useState } from "react";

export default function UserAdd({ defaultRole, setSidePopup }: any) {
  const [addUsers] = useMutation(ADD_USER);
  let dataRes: any = null;
  const assignedBrands = useAssignedBrands();
  const [data, setData] = useState<any>([]);
  // const { setSidePopup } = commonStore();
  const { travelHouse }: any = travelHouseStore();
  const validation = useValidation();
  // Get Brands
  // const { loading, error, data } = useQuery(GET_BRANDS_WITH_BRD, {
  //   variables: { brands: brands },
  // });
  const [getBrandsMembers] = useLazyQuery(GET_BRANDS_WITH_BRD);
  const [getAllMembers] = useLazyQuery(GET_BRANDS_WITH_THP);

  const fetchMembers = async () => {
    console.log("xwt ===> brands ===>", assignedBrands);
    if (assignedBrands) {
      dataRes = await getBrandsMembers({
        variables: { brands: assignedBrands },
      });
      console.log("xwt data check ===>", dataRes);
      setData(dataRes);
    } else {
      dataRes = await getAllMembers({
        variables: { thp_id: travelHouse?.id },
      });

      console.log("xwt data check 2 ===>", dataRes);
      setData(dataRes);
    }

    console.log("xwt data ===>", dataRes);
  };

  useEffect(() => {
    fetchMembers();
  }, []);

  // Appolo Client
  const client = useApolloClient();

  const refetch = useRefetch();
  const { loading: travelHouseLoading, data: travelHouseData } = useQuery(
    GET_TRAVEL_HOUSE_BY_ID,
    {
      variables: { id: travelHouse?.id },
      fetchPolicy: "network-only",
    }
  );

  const { data: getAliasData, loading: getAliasLoading } = useQuery(
    GET_ALIASES,
    {
      variables: {
        refresh_token: travelHouseData?.thp_list[0]?.email_refresh_token,
        th_id: travelHouse.id,
        page: "",
        nextPageToken: "",
        pageSize: "1",
        body: "",
        sendEmail: false,
        subject: "",
        to: "",
        getNewEmailsFlag: false,
        historyId: "",
        aliasDisplayName: "",
        aliasEmail: "",
        createAlias: false,
        getAlias: true,
        from: "",
        alias: false,
        replyFlag: false,
        messageId: "",
        query: false,
        queryData: "",
      },
    }
  );
  console.log("getAliasData", getAliasData);
  const {
    formState: { errors },
    register,
    handleSubmit,
    reset,
    control,
    setValue,
  } = useForm();

  const onSubmit = async (formData: any) => {
    // check if user have no brands
    console.log("formData", formData);
    if (formData.brands.length <= 0) {
      toast.error("At least one brand is required for update!");
      return 0;
    }
    if (!formData.brands) {
      toast.error("At least one brand is required for update!");
      return 0;
    }
    let brd_users: any = [];
    if (typeof formData.brands === "string") {
      brd_users.push({
        thp_id: travelHouse?.id,
        brd_id: formData.brands,
      });
    } else {
      formData.brands.forEach((brd_id: any) => {
        brd_users.push({
          thp_id: travelHouse?.id,
          brd_id: brd_id,
        });
      });
    }

    const resPhone = await validation.checkPhone(formData.phoneNumber);

    if (resPhone.length > 0) {
      toast.error("Phone number already exist");
      return 0;
    }

    const resEmail = await validation.checkEmail(formData.email);
    if (resEmail.length > 0) {
      toast.error("Email already exist");
      return 0;
    }

    try {
      const res = await addUsers({
        variables: {
          user: {
            displayName: formData.displayName,
            email: formData.email,
            defaultRole: defaultRole === "team" ? formData.role : defaultRole,
            phoneNumber: formData.phoneNumber,
            metadata: {
              country: formData.location.country,
              addressLine1: formData.location.addressLine1,
              addressLine2: formData.location.addressLine2,
              city: formData.location.city,
              postalCode: formData.location.postalCode,
              region: formData.location.region,
              alias: formData.alias,
            },
            brd_users: {
              data: brd_users,
            },
            locale: "en",
          },
        },
      });
      if (res.data?.insertUsers?.returning?.length > 0) {
        toast.success("User added successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        reset();
        await refetch();
        setSidePopup(false);
      }
    } catch (e: any) {
      console.log("error", e);
      toast.error("e", e);
    }
  };

  // return loading while loading data
  // if (loading) return <div>Loading...</div>;
  // Return error if get gql error
  // if (error) return <div>Error in the query {error.message}</div>;
  console.log("xwt asny data ====>", data);
  // return <p>tset</p>;
  if (Object.keys(data)?.length > 0) {
    return (
      <form onSubmit={handleSubmit(onSubmit)} className="w-full">
        <h2 className="flex justify-center font-extrabold	">Add User</h2>
        <div>
          {defaultRole === "team" && (
            <div>
              <select
                {...register("role", { required: true })}
                className="bg-gray-50 mb-4 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              >
                <option value="consultant">Consultant</option>
                <option value="accountant">Accountant</option>
                <option value="admin">Admin</option>
              </select>
              {/* <InputField
                register={register}
                errors={errors}
                fieldName="alias"
                required={true}
                placeHolder="Email Alias"
              /> */}
              {getAliasLoading ? (
                "Loading..."
              ) : (
                <select
                  {...register("alias")}
                  className="bg-gray-50 mb-4 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <option value="">Select Alias</option>
                  {getAliasData?.getGmailMessages?.aliases?.map(
                    (email: any) => (
                      <option key={email.sendAsEmail} value={email.sendAsEmail}>
                        {email.sendAsEmail}
                      </option>
                    )
                  )}
                </select>
              )}
            </div>
          )}
          <div className="mt-2 mb-2">
            <InputField
              register={register}
              errors={errors}
              fieldName="displayName"
              required={true}
              placeHolder="User Name"
            />
          </div>
          <div className="mt-2 mb-2">
            <InputField
              type="email"
              register={register}
              errors={errors}
              fieldName="email"
              required={true}
              placeHolder="User Email"
            />
          </div>
          <div className="mt-2 mb-2">
            <PhoneBox
              control={control}
              errors={errors}
              fieldName="phoneNumber"
              setValue={setValue}
            />
          </div>
          <div className="mt-2 mb-2">
            <AddressBox
              register={register}
              errors={errors}
              fieldName="location"
              setValue={setValue}
            />
          </div>
        </div>
        {/* Assign Brands */}
        <BrandsAssign brd_list={data?.data?.brd_list} register={register} />

        <div className="flex gap-2 w-full">
          <button
            className="w-full btn bg-green-500 text-white rounded-md p-2"
            type="submit"
          >
            Save
          </button>
        </div>
      </form>
    );
  } else {
    return <p>Loading...</p>;
  }
}
