import { gql } from "@apollo/client";

export const GET_BRAND_DETAILS = gql`
  query GetBrandsQuery($brd_id: uuid) {
    brd_list(where: { id: { _eq: $brd_id } }) {
      created_at
      domain
      id
      name
      subdomain
      thp_id
      updated_at
      brd_default_settings {
        id
        gtag_id
      }
      brd_details {
        logo
        primary_color
        secondary_color
        theme_id
        updated_at
        id
        created_at
        fav_icon
        footer_logo
        font_id
        hero_image
        iata
        authority_link
        authority_number
      }
      brd_contact_details {
        id
        country
        email
        phone
        phone_without_country_code
        address2
        address1
        city
        whatsapp
        created_at
      }
      brd_social_media {
        facebook
        discord
        instagram
        id
        linkedin
        pinterest
        tiktok
        updated_at
        x
        youtube
      }
      brd_seo_setting {
        author_company
        canonical_url
        created_at
        default_description
        default_keywords
        default_title
        flight_page_id
        id
        locale
        og_image_url
        page_id
        site_name
        twitter_card_type
        twitter_creator_handle
        twitter_site_handle
        updated_at
      }
    }
  }
`;
