import { gql } from "@apollo/client";

export const GET_PAGE_DETAILS = gql`
  query GetFlightPageQuery($page_id: uuid) {
    brd_flights_pages(where: { id: { _eq: $page_id } }) {
      id
      brd_id
      origin_iata
      destination_iata
      origin_name
      destination_name
      featured_image
      show_in_footer
      show_featured_destination
      featured_destination_image
      airline_iata
      base_price
      created_at
      updated_at
      slug
      show_in_home
      brd_flights_pages_tips {
        description
        flight_page_id
        heading
        id
      }
      brd_seo_settings {
        canonical_url
        created_at
        default_description
        default_keywords
        default_title
        flight_page_id
        locale
        og_image_url
        page_id
        site_name
        twitter_card_type
        twitter_creator_handle
        twitter_site_handle
        id
        author_company
        brd_id
      }
    }
  }
`;
