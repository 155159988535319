import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import InputField from "components/FormInputs/InputField";

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import travelHouseStore from "store/travelHouse/travelHouseStore";
import { ADD_PAYMENT_METHOD, EDIT_PAYMENT_METHOD } from "./graphql/Mutation";
import { GET_BRANDS } from "./graphql/Query";
import commonStore from "~/store/commonStore/commonStore";

export default function PaymentMethodAdd({ setSidePopup }: any) {
  const [addPaymentMethod] = useMutation(ADD_PAYMENT_METHOD);
  // const { setSidePopup } = commonStore();
  const { travelHouse }: any = travelHouseStore();
  const client = useApolloClient();
  const [isLoading, setIsLoading] = useState(false);
  // get data based on query
  const { loading, data, error } = useQuery(GET_BRANDS, {
    variables: { thp_id: travelHouse.id },
  });

  const {
    formState: { errors },
    register,
    handleSubmit,
    reset,
    control,
    watch,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      brd_id: "",
      type: "bank",
      defaultPayment: true,
    },
  });
  const type = watch("type");

  const onSubmit = async (formData: any) => {
    console.log("formData", formData);
    setIsLoading(true);
    try {
      // Create Suggestion function call
      const res = await addPaymentMethod({
        variables: {
          paymentMethod: {
            thp_id: travelHouse?.id,
            brd_id: formData.brd_id,
            type: formData.type,
            name: formData.name,
            default: false,
            account_no: formData.account_no,
            account_title: formData.account_title,
            swift_code: formData.swift_code,
            stripe_pub: formData.stripe_pub,
            stripe_secret: formData.stripe_secret,
          },
        },
      });
      if (res.data?.insert_acc_payment_methods?.returning?.length > 0) {
        toast.success("Payment method added successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        reset();
        await client.resetStore();

        setIsLoading(false);
        setSidePopup(false);
      }
    } catch (e) {
      reset();
      console.error("error", e);

      setIsLoading(false);
    }
  };

  // return loading while loading data
  if (loading) return <div>Loading...</div>;
  // Return error if get gql error
  if (error) return <div>Error in the query {error.message}</div>;

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-full">
      <h2 className="flex justify-center font-extrabold">Add Payment Method</h2>

      <div>
        <div>
          <select
            {...register("brd_id", { required: true })}
            className=" mb-4 border  text-sm rounded-lg block w-full p-2.5 "
          >
            {data.brd_list.map((brand: any, index: any) => (
              <option value={brand.id} selected={true}>
                {brand.name}
              </option>
            ))}
          </select>
        </div>

        <div>
          <select
            {...register("type", { required: true })}
            defaultValue="bank" // Set the default value to "bank"
            className="bg-gray-50 mb-4 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
          >
            <option value="bank">Bank</option>
            <option value="stripe">Stripe</option>
            <option value="stripe-thp">Stripe (THP)</option>
          </select>
        </div>

        <div className="mt-2 mb-2">
          <InputField
            register={register}
            errors={errors}
            fieldName="name"
            required={true}
            placeHolder="Payment Method Name"
          />
        </div>

        {type === "bank" && (
          <>
            <div className="mt-2 mb-2">
              <InputField
                register={register}
                errors={errors}
                fieldName="account_title"
                required={true}
                placeHolder="Account Title"
              />
            </div>
            <div className="mt-2 mb-2">
              <InputField
                register={register}
                errors={errors}
                fieldName="account_no"
                required={true}
                placeHolder="Account Number"
              />
            </div>
            <div className="mt-2 mb-2">
              <InputField
                register={register}
                errors={errors}
                fieldName="swift_code"
                required={true}
                placeHolder="Sort Code"
              />
            </div>
          </>
        )}

        {type === "stripe" && (
          <>
            <div className="mt-2 mb-2">
              <InputField
                register={register}
                errors={errors}
                fieldName="stripe_pub"
                required={true}
                placeHolder="Stripe Public Key"
              />
            </div>
            <div className="mt-2 mb-2">
              <InputField
                register={register}
                errors={errors}
                fieldName="stripe_secret"
                required={true}
                placeHolder="Stripe Secret Key"
              />
            </div>
          </>
        )}
      </div>

      <div className="flex gap-2 w-full">
        <button
          className="w-full btn bg-green-500 text-white rounded-md p-2"
          type="submit"
        >
          {isLoading ? "Saving.." : "Save"}
        </button>
      </div>
    </form>
  );
}
