import { useApolloClient, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import InputField from "~/components/FormInputs/InputField";
import { ADD_ACCOUNT } from "./graphql/Mutation";
import { toast } from "react-toastify";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import useRefetch from "~/hooks/useRealtime";

export default function AddAccount({ setSidePopup }: any) {
  const [isLoading, setIsLoading] = useState(false);
  const [addAccount] = useMutation(ADD_ACCOUNT);
  const { travelHouse }: any = travelHouseStore();
  const refetch = useRefetch();

  const {
    formState: { errors },
    register,
    handleSubmit,
    reset,
    control,
    watch,
    setValue,
    getValues,
  } = useForm();

  //   const onSubmit = async (formData: any) => {};

  const onSubmit = async (formData: any) => {
    try {
      const res = await addAccount({
        variables: {
          acc_list: [
            {
              thp_id: travelHouse.id,
              name: formData.name,
              type: formData.type,
            },
          ],
        },
      });
      await refetch();
      toast.success("Account added successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setSidePopup(false);
    } catch (e) {
      reset();
      console.error("error", e);
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-full">
      <h2 className="flex justify-center font-extrabold">Add Account</h2>
      <div>
        <label htmlFor="">Type</label>
        <select
          {...register("type", { required: true })}
          className=" mb-4 border text-sm rounded-lg block w-full p-2.5 "
        >
          <option value="assets">Assets</option>
          <option value="liability">Liability</option>
          <option value="income">Income</option>
          <option value="equity">Equity</option>
          <option value="expense">Expense</option>
        </select>
      </div>
      <div className="mt-2 mb-2">
        <InputField
          register={register}
          errors={errors}
          type="text"
          fieldName="name"
          required={true}
          label="Name"
          placeHolder="Enter Name"
        />
      </div>
      <div className="flex gap-2 w-full">
        <button
          className="w-full btn bg-green-500 text-white rounded-md p-2"
          type="submit"
        >
          {isLoading ? "Saving.." : "Save"}
        </button>
      </div>
    </form>
  );
}
