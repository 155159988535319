import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
// Import Multi User Routes
import AdminRoutes from "./routes/AdminRoutes";
import AccountantRoutes from "./routes/AccountantRoutes";
import ConsultantRoutes from "./routes/ConsultantRoutes";
import UserRoutes from "./routes/UserRoutes";

// Import Pages
import Login from "./pages/Login";
import Verify from "./pages/Verify";
import SuperAdminRoutes from "./routes/SuperAdminRoutes";

// Import Ably
import { ABLY } from "~/config/enums";
import { configureAbly, useChannel } from "@ably-labs/react-hooks";
import { generateRandomId } from "~/utils/generateRandomId";
import travelHouseStore from "./store/travelHouse/travelHouseStore";
import { useApolloClient } from "@apollo/client";
// import useReactRouterBreadcrumbs from "use-react-router-breadcrumbs";

// Config Ably
configureAbly({ key: ABLY.ABLY_API_KEY, clientId: generateRandomId() });

// You could use context to set app Routes and add the breadcrumbs somewhere deeper in the application layout.
// const AppRoutes = GenerateAppRoute();
// const appRouteObjects = createRoutesFromChildren(AppRoutes);
// const breadCrumbs = useBreadcrumbs(appRouteObjects);
// const GeneratedRoutes = useRoutes(appRouteObjects);

function App() {
  // const breadcrumbs = useReactRouterBreadcrumbs(breadcrumbRoutes);

  const { travelHouse }: any = travelHouseStore();
  const client = useApolloClient();
  const [channel] = useChannel(
    "getUpdate",
    travelHouse.id,
    async (message: any) => {
      console.log("getUpdate");
      await client.resetStore();
    }
  );
  return (
    <BrowserRouter>
      <SuperAdminRoutes />
      <AdminRoutes />
      <ConsultantRoutes />
      <AccountantRoutes />
      <UserRoutes />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/verify" element={<Verify />} />
        <Route path="/" element={<Navigate to={"/login"} />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
