import { useState } from "react";
import SelectButton from "./components/SelectButton";
import FlightDetail from "./components/FlightDetail";
import EditSuggestion from "./components/EditSuggestion";
import { FaEdit } from "react-icons/fa";
import { useUserDefaultRole } from "@nhost/react";
import CostBreakDown from "./components/CostBreakDown";

const SuggestionBox = ({
  suggestion,
  selected_suggestion_id,
  selected,
  active,
  picked_by,
  user_email,
  brand_domain,
  brand_url,
  brand_name,
  user_id,
  inquiry,
}: any) => {
  const [showAddModal, setShowAddModal] = useState<any>(false);
  const [showCostBreakdownModal, setShowCostBreakdownModal] = useState(false); // New state for cost breakdown modal

  const userRole = useUserDefaultRole();

  console.log("inquiry", inquiry);
  return (
    <>
      {active && (
        <div
          className={` ${
            active ? `show` : `hide`
          } p-[55px] self-stretch relative bg-white-100 overflow-hidden flex flex-row mb-6 items-center justify-center gap-[60px] text-left text-xs text-darkslategray  border-[1px] border-solid border-silver`}
        >
          {inquiry.status !== "completed" && (
            <>
              {!inquiry.invoice_id && (
                <>
                  {userRole !== "user" && (
                    <button
                      onClick={() => {
                        setShowAddModal(!showAddModal);
                      }}
                      className="absolute top-[0px] right-[-75px] z-[2]"
                    >
                      <span className=" bg-primary cursor-pointer rounded-[5px] mt-2 py-2 px-5 text-white absolute top-[-1px] right-[130px]">
                        Edit
                      </span>
                    </button>
                  )}
                  <button
                    className="absolute top-[0px] right-[90px] z-[2] w-[150px]"
                    onClick={() => setShowCostBreakdownModal(true)}
                  >
                    <span className=" bg-primary cursor-pointer rounded-[5px] mt-2 py-2 px-5 text-white absolute top-[-1px] right-[130px] w-full">
                      Price Breakdown
                    </span>
                  </button>

                  <SelectButton
                    selected={selected}
                    brand_domain={brand_domain}
                    suggestion={suggestion}
                    inquiry={inquiry}
                    inquiry_id={inquiry.id}
                    user_email={user_email}
                    brand_url={brand_url}
                    brand_name={brand_name}
                    selected_suggestion_id={selected_suggestion_id}
                    picked_by={picked_by}
                    user_id={user_id}
                  />
                </>
              )}
            </>
          )}
          {suggestion?.inq_suggestion_legs.map(
            (suggestion_leg: any, index: any) => {
              return (
                <FlightDetail
                  key={index}
                  suggestion_leg={suggestion_leg}
                  index={index}
                />
              );
            }
          )}

          <div className="absolute right-5 bottom-2 mb-[10px] mr-[15px]">
            <div className="self-stretch flex flex-col items-start justify-start mt-2">
              <div className="flex flex-row items-center justify-start gap-[41px]">
                {userRole !== "user" && (
                  <div className="flex flex-row items-center justify-start gap-[10px] ">
                    <strong className="">Cost:</strong>
                    <div className="relative inline-block shrink-0 text-[20px] font-bold">
                      £
                      {suggestion.inq_suggestion_costs.reduce(
                        (sum: any, obj: any) =>
                          sum +
                          parseFloat(obj.cost_price) +
                          parseFloat(obj.tax_price),
                        0
                      )}
                    </div>
                  </div>
                )}

                <div className="flex flex-row items-center justify-start gap-[6px]">
                  <strong className="">Sale:</strong>
                  <div className="relative inline-block shrink-0 text-[20px] font-bold">
                    £
                    {suggestion.inq_suggestion_costs.reduce(
                      (sum: any, obj: any) => sum + parseFloat(obj.sale_price),
                      0
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {showCostBreakdownModal && (
        <CostBreakDown
          inq_suggestion_cost={suggestion.inq_suggestion_costs}
          setShowCostBreakdownModal={setShowCostBreakdownModal}
        />
      )}

      {showAddModal && (
        <EditSuggestion
          suggestion={suggestion}
          setShowAddModal={setShowAddModal}
          inquiry={inquiry}
          inquiry_id={inquiry.id}
          passengers={inquiry.inq_passengers}
          arrival={inquiry?.to}
          user_id={inquiry.users.id}
        />
      )}
    </>
  );
};

export default SuggestionBox;
