import { useApolloClient, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  getPaidAmount,
  getSalePrice,
} from "~/modules/InquiriesModule/utils/getInquiryStats";
import { ADD_TRANSACTION, EDIT_INQUIRY } from "./graphql/Mutation";
import useNotifications from "~/hooks/useNotifications";
import { generateRandom10DigitCode } from "~/utils/generateRandomId";
import moment from "moment";
import useEmail from "~/hooks/emails/useEmail";

export default function CompleteInquiry({ inquiry }: any) {
  const [modalOpen, setModelOpen] = useState(false);
  const [editInquiry] = useMutation(EDIT_INQUIRY);
  const [addTransaction] = useMutation(ADD_TRANSACTION);
  const client = useApolloClient();
  const alert = useNotifications();
  const emailSend = useEmail();

  const closeInquiry = async () => {
    if (getPaidAmount(inquiry) < getSalePrice(inquiry)) {
      toast.error("Paid amount is less then inquiry sale price!");
      return 0;
    }

    if (
      inquiry?.inq_tickets[0]?.status !== "issued" ||
      inquiry?.inq_tickets[0]?.url === null
    ) {
      toast.error("Ticket not issued!");
      return 0;
    }

    const tenDigitCode: any = generateRandom10DigitCode();

    const transactions: any = [
      {
        brd_id: inquiry?.brd_id,
        def_acc_id: 4,
        inq_id: inquiry?.id,
        user_id: inquiry?.users.id,
        transactions_no: tenDigitCode,
        date: moment(new Date()).format("YYYY-MM-DD"),
        type: "debit",
        amount: getPaidAmount(inquiry),
      },
      {
        brd_id: inquiry?.brd_id,
        def_acc_id: 5,
        inq_id: inquiry?.id,
        transactions_no: tenDigitCode,
        date: moment(new Date()).format("YYYY-MM-DD"),
        type: "credit",
        amount: -getPaidAmount(inquiry),
      },
    ];
    console.log("formData", transactions);
    if (transactions[0].amount + transactions[1].amount === 0) {
      try {
        const res = await addTransaction({
          variables: {
            transactions: transactions,
          },
        });
        toast.success("Transaction added successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        await client.resetStore();

        const response = await editInquiry({
          variables: {
            id: inquiry.id,
            status: "completed",
          },
        });

        if (response?.data?.update_inq_list?.returning?.length > 0) {
          const emailVariables: any = {
            inquiryNo: inquiry?.inquiry_no,
            userFirstName: inquiry?.users?.displayName,
            userLastName: "",
            brandName: inquiry.brd_list?.name,
            linkToPortal: `${inquiry.brd_list?.domain}`,
          };

          await emailSend(
            10,
            inquiry.brd_id,
            inquiry.users?.email,
            emailVariables
          );

          toast.success("Inqiury Status Update Success", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          await client.resetStore();

          await alert.newNotification(
            inquiry.users?.id,
            `Congratulations! Your ticket is uploaded by your consultant, please check your portal`,
            "",
            ``
          );
        } else {
          toast.error("Something went wrong");
        }
      } catch (e: any) {
        toast.error("Transaction Error", e?.message);
      }
    } else {
      toast.error("Transaction Error");
    }
  };

  return (
    <div className="flex justify-end gap-3 w-full">
      <button
        onClick={() => setModelOpen(!modalOpen)}
        className="block border border-primary rounded-full px-3 py-1 text-primary cursor-pointer hover:bg-primary hover:text-white"
      >
        Complete
      </button>

      {modalOpen && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
          style={{ zIndex: 50 }}
        >
          <div className="relative bg-white rounded-xl shadow-2xl p-8 w-11/12 sm:w-3/4 md:w-1/2 lg:w-1/3">
            <h2 className="mb-6 text-2xl font-bold text-gray-700">
              Are you sure?
            </h2>
            <div className="flex justify-end space-x-4">
              <button
                onClick={(event: any) => setModelOpen(false)}
                className="px-5 py-2 font-semibold text-gray-700 bg-red-500 bg-opacity-10 rounded-full hover:bg-opacity-20"
              >
                No
              </button>
              <button
                onClick={(event: any) => closeInquiry()}
                className="px-5 py-2 font-semibold text-white bg-green-500 rounded-full hover:bg-green-600"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
