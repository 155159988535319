// import InputField from "components/FormInputs/InputField";
import React from "react";
import InputField from "components/FormInputs/InputField";
import { CalendarBox } from "components/FormInputs/CalendarBox";

export default function FlightDates({ errors, setValue, control }: any) {
  return (
    <div className="flex flex-row gap-4">
      {/* Flight Fare Expiry */}
      <CalendarBox
        control={control}
        setValue={setValue}
        errors={errors}
        fieldName="fareExpiry"
        label="Fare Expiry"
      />
      {/* Flight Invoice Due Date */}
      <CalendarBox
        control={control}
        setValue={setValue}
        errors={errors}
        fieldName="invoiceDueDate"
        label="Invoice Due Date"
      />
    </div>
  );
}
