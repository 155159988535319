import React from "react";
import Layout from "~/components/common/Layout";
import BrandModule from "~/modules/BrandModule";
import Posts from "~/modules/BrandModule/segments/Posts";
export default function BrandPostsPage() {
  return (
    <div>
      <Layout>
        <BrandModule>
          <Posts />
        </BrandModule>
      </Layout>
    </div>
  );
}
