import React, { useState } from "react";
import InvoiceCard from "./components/InvoiceCard";
import { toast } from "react-toastify";
import { ADD_INVOICE, EDIT_INQUIRY } from "./graphql/Mutation";
import { useApolloClient, useMutation } from "@apollo/client";

export default function Invoices({ inquiry }: any) {
  const [hideForm, setHideForm] = useState(false);
  const [addInvoice] = useMutation(ADD_INVOICE);
  const [editInquiry] = useMutation(EDIT_INQUIRY);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingVoid, setIsLoadingVoid] = useState(false);
  const client = useApolloClient();

  function generateRandomNumber() {
    return Math.floor(Math.random() * 1000000); // Adjust the range as needed
  }

  const generateInvoice = async () => {
    if (inquiry.suggestion_id) {
      setIsLoading(true);
      const invoiceItems: any = [];
      inquiry.selected_suggestion.inq_suggestion_costs.map((cost: any) => {
        invoiceItems.push({
          amount: cost.sale_price,
          description: `${cost?.inq_passenger?.first_name} ${cost?.inq_passenger?.last_name} (${cost?.inq_passenger?.type})`,
        });
      });

      const invoice = {
        inquiry_id: inquiry.id,
        invoice_no: generateRandomNumber(),
        due_date: inquiry.selected_suggestion?.due_date || null,
        inq_invoice_items: {
          data: invoiceItems,
        },
      };

      const invoiceRes = await addInvoice({
        variables: { invoice },
      });

      if (invoiceRes.data.insert_inq_invoices.affected_rows > 0) {
        const editInquiryRes = await editInquiry({
          variables: {
            id: inquiry.id,
            invoice_id: invoiceRes.data.insert_inq_invoices.returning[0].id,
          },
        });

        await client.resetStore();
        console.log("editInquiryRes", editInquiryRes);

        setIsLoading(false);
        toast.success("Invoice generated");
      }
      console.log("invoiceRes", invoiceRes);
    } else {
      toast.error("Suggestion not selected!");
    }
  };

  const voidInvoice = async () => {
    setIsLoadingVoid(true);
    const editInquiryRes = await editInquiry({
      variables: {
        id: inquiry.id,
        invoice_id: null,
      },
    });

    console.log("editInquiryRes", editInquiryRes);

    await client.resetStore();
    setIsLoadingVoid(false);
    toast.success("Invoice void");
  };
  return (
    <div className="self-stretch flex flex-col items-start justify-start gap-[30px] mb-5">
      <div className="self-stretch flex  pt-0 px-0 pb-[5px] items-start justify-between border-b-[1px] border-solid border-lightsteelblue">
        <b className="self-stretch  relative leading-[20px]">{`Invoices`}</b>
        <div className="">
          {!inquiry.invoice_id ? (
            <div className="flex justify-end gap-3 w-full">
              <button
                onClick={() => {
                  generateInvoice();
                }}
                className="block border border-primary rounded-full px-3 py-1 text-primary cursor-pointer hover:bg-primary hover:text-white"
              >
                {isLoading ? "Generating.." : "Generate Invoice"}
              </button>
            </div>
          ) : (
            <div className="flex justify-end gap-3 w-full">
              <button
                onClick={() => {
                  voidInvoice();
                }}
                className="block border border-primary rounded-full px-3 py-1 text-primary cursor-pointer hover:bg-primary hover:text-white"
              >
                {isLoadingVoid ? "Voiding.." : "Void Invoice"}
              </button>
            </div>
          )}
        </div>
      </div>

      <div className="self-stretch grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-4  items-start justify-start gap-[30px] text-mini">
        {inquiry.inq_invoices.map((invoice: any) => (
          <InvoiceCard
            isActive={inquiry.invoice_id === invoice.id ? true : false}
            invoice={invoice}
            inquiry={inquiry}
          />
        ))}
      </div>
    </div>
  );
}
