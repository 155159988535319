import { gql } from "@apollo/client";

export const GET_INQUIRY = gql`
  query GetInquiry($inquiry_no: String!) {
    inq_list(where: { inquiry_no: { _eq: $inquiry_no } }) {
      direct_flight
      flexible_arrival
      flexible_departure
      approx_budget
      from
      inquiry_no
      preferred_airline
      source
      status
      to
      trip_type
      arrival_datetime
      created_at
      departure_datetime
      updated_at
      brd_id
      id
      payment_method_id
      picked_by
      suggestion_id
      invoice_id
      thp_id
      user_id
      inq_invoices {
        id
        invoice_no
        due_date
        inq_invoice_items {
          id
          description
          amount
        }
      }
      brd_list {
        name
        domain
        subdomain
        brd_contact_details {
          address1
          country
          city
        }
        brd_details {
          logo
        }
      }
      users {
        id
        email
        displayName
        metadata
        phoneNumber
        avatarUrl
        defaultRole
        brd_users {
          user_id
          brd_id
          thp_id
        }
      }
      picked_user {
        id
        avatarUrl
        metadata
        email
        displayName
        phoneNumber
      }
      inq_passengers {
        type
        first_name
        dob
        created_at
        gender
        id
        last_name
        nationality
        passport_expiry
        passport_no
        updated_at
        inq_id
        inq_suggestion_costs {
          cost_price
          created_at
          id
          passenger_id
          sale_price
          suggestion_id
          tax_price
          updated_at
        }
      }
      inq_suggestions {
        due_date
        fare_expiry
        id
        inq_suggestion_costs {
          cost_price
          id
          created_at
          passenger_id
          sale_price
          tax_price
          updated_at
          inq_passenger {
            first_name
            last_name
            id
            gender
            type
            passport_no
            passport_expiry
            nationality
            inq_id
            dob
          }
        }
        inq_suggestion_legs {
          cabin
          hand_carry
          id
          created_at
          luggage
          type
          updated_at
          inq_suggestion_leg_stops {
            airline_iata
            arrival
            arrival_datetime
            created_at
            depart
            depart_datetime
            flight_no
            id
            ordering
          }
        }
      }
      inq_tickets {
        cancelation_charges
        ibe
        id
        inq_id
        pnr
        refundable
        status
        updated_at
        url
        vendor_id
        void_time
        created_at
        ticket_cost_price
        ticket_attol_price
        ticket_mis_cost
      }
      inq_transections {
        amount
        created_at
        id
        payment_method_id
        receipt
        ref_no
        status
        acc_payment_method {
          type
          name
          account_title
          account_no
          swift_code
        }
      }
      selected_suggestion {
        inq_suggestion_costs {
          cost_price
          sale_price
          tax_price
          id
          passenger_id
          inq_passenger {
            first_name
            last_name
            id
            gender
            type
            passport_no
            passport_expiry
            nationality
            inq_id
            dob
          }
        }
        due_date
        fare_expiry
      }
    }
  }
`;
