import React from "react";
import RestoreTemplates from "./components/RestoreTemplates";
import { useQuery } from "@apollo/client";
import { GET_BRAND_EMAIL_TEMPLATES } from "./graphql/Query";
import SingleTemplate from "./components/SingleTemplate";

export default function BrandEmailTemplates({ brd_id }: any) {
  const { loading, error, data } = useQuery(GET_BRAND_EMAIL_TEMPLATES, {
    variables: { brd_id },
  });

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error in the query {error.message}</div>;

  return (
    <div>
      <RestoreTemplates brd_id={brd_id} />
      {data.brd_emails_templates.map((template: any) => (
        <SingleTemplate template={template} />
      ))}
    </div>
  );
}
