import { Controller } from "react-hook-form";
import { Fragment, useEffect, useState } from "react";
import { Combobox, Transition } from "@headlessui/react";
import * as JsSearch from "js-search";
import _ from "lodash";
import { CountryBoxProps } from "./types";
import countries from "./data/countries.json";
const search = new JsSearch.Search("label");
search.addIndex("label");
search.addIndex("code");

search.addDocuments(countries);

const CountryBox = ({
  control,
  errors,
  setValue,
  fieldName,
  defaultValue,
  label,
  labelClasses,
  optionBoxClasses,
  optionClasses,
  inputClasses,
  containerClasses,
  placeholder,
  required,
}: CountryBoxProps) => {
  const [selected, setSelected] = useState({});
  const [query, setQuery] = useState("");
  const [filteredCountries, setFilteredCountries] = useState<any>([]);

  useEffect(() => {
    if (defaultValue) {
      const country = search.search(defaultValue.toLowerCase());
      setTimeout(() => {
        setSelected(country[0]);
        setValue(fieldName, country[0]);
      }, 200);
    }
  }, []);

  function searchCountry(input: string) {
    const matchedCountries = search.search(input);

    if (matchedCountries.length > 0) {
      setFilteredCountries(matchedCountries);
    }

    return matchedCountries;
  }

  useEffect(() => {
    if (query !== "") {
      searchCountry(query);
    } else {
      setSelected("");
      setValue(fieldName, "");
    }
  }, [query]);

  const resetSelection = () => {
    setQuery("");
    setSelected("");
    setValue(fieldName, "");
  };

  return (
    <div className={`w-full ${containerClasses}`}>
      {label && (
        <label
          htmlFor="fruit"
          className={`text-[16px] block mb-2 ${labelClasses}`}
        >
          {label}
        </label>
      )}
      <Controller
        name={fieldName}
        control={control}
        rules={{
          required: required || false,
        }}
        defaultValue={defaultValue || ""}
        render={({ field: { onChange } }) => (
          <Combobox value={selected} onChange={onChange}>
            <div className="relative">
              <div
                className={`relative w-full cursor-default overflow-hidden rounded bg-white text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm`}
              >
                <Combobox.Input
                  className={`w-full border py-2 pl-3 pr-10 text-sm leading-5 rounded text-gray-900 focus:ring-0 ${
                    errors[fieldName] ? "border-red-500" : "border-gray-200"
                  } ${inputClasses}`}
                  displayValue={(country: any) => country.label}
                  placeholder={placeholder}
                  onChange={(event: any) => setQuery(event.target.value)}
                />
                {selected !== "" && (
                  <button
                    onClick={() => resetSelection()}
                    className="absolute h-[20px] w-[20px] bg-gray-200 text-[12px] flex justify-center items-center rounded-full right-2 cursor-pointer  top-[50%] translate-y-[-50%]"
                  >
                    x
                  </button>
                )}
              </div>
              {query !== "" && (
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  // afterLeave={() => setQuery('')}
                >
                  <Combobox.Options
                    className={`absolute z-[100] mt-1 max-h-60 w-full overflow-auto min-w-[300px] rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm ${optionBoxClasses}`}
                  >
                    {filteredCountries.length === 0 && query !== "" ? (
                      <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                        Nothing found.
                      </div>
                    ) : (
                      filteredCountries.map((country: any, i: number) => (
                        <Combobox.Option
                          key={i}
                          className={({ active }: any) =>
                            `relative select-none py-2 cursor-pointer px-4 ${
                              active
                                ? "bg-blue-600 text-white"
                                : "text-gray-900"
                            } ${optionClasses}`
                          }
                          value={country}
                          onClick={() => setSelected(country)}
                        >
                          {({ selected, active }: any) => (
                            <>
                              <span
                                className={`block truncate ${
                                  selected ? "font-medium" : "font-normal"
                                }`}
                              >
                                {country.label}
                              </span>
                              {selected ? (
                                <span
                                  className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                    active ? "text-white" : "text-teal-600"
                                  }`}
                                ></span>
                              ) : null}
                            </>
                          )}
                        </Combobox.Option>
                      ))
                    )}
                  </Combobox.Options>
                </Transition>
              )}
            </div>
          </Combobox>
        )}
      />
      {errors[fieldName] && (
        <p className={`text-red-500 text-[12px] mt-1 ml-1`}>
          {" "}
          <sup>*</sup> required.
        </p>
      )}
    </div>
  );
};

export default CountryBox;
