import React from "react";
import { InputFieldProps } from "./types";

const InputField = ({
  fieldName,
  required,
  onChange,
  defaultValue,
  type,
  placeHolder,
  label,
  register,
  errors,
  checked,
  className,
  containerClasses,
  inputClasses,
  labelClasses,
  errorClasses,
}: InputFieldProps) => {
  return (
    <div className={`w-full ${containerClasses}`}>
      <div
        className={`${
          type === "radio" || type === "checkbox"
            ? "flex flex-row-reverse justify-end items-center gap-2"
            : ""
        }`}
      >
        {label && (
          <label
            className={`block text-black ${
              type === "radio" || type === "checkbox" ? "mb-0" : "mb-2"
            } ${labelClasses}`}
            htmlFor={
              type === "radio" || type === "checkbox"
                ? `${fieldName}_${defaultValue}`
                : fieldName
            }
          >
            {label}
          </label>
        )}
        <input
          onChange={onChange}
          step="0.01"
          id={
            type === "radio" || type === "checkbox"
              ? `${fieldName}_${defaultValue}`
              : fieldName
          }
          type={type || "text"}
          placeholder={placeHolder || ""}
          {...register(fieldName, {
            required: required ? `* This is required` : false,
          })}
          defaultValue={defaultValue || ""}
          defaultChecked={checked || false}
          min={0}
          aria-invalid={errors[fieldName] ? "true" : "false"}
          className={` ${
            type === "radio" || type === "checkbox"
              ? ""
              : "self-stretch w-full px-[10px]"
          } border py-2 pl-3 pr-10 text-sm leading-5 rounded text-gray-900 focus:ring-0 ${
            errors[fieldName] ? "border-red-500" : "border-gray-200"
          } ${inputClasses}`}
        />
      </div>
      {errors[fieldName] && (
        <span
          role="alert"
          className={`text-red-500 m-1 text-[12px] normal-case ${errorClasses}`}
        >
          {errors[fieldName]?.message}
        </span>
      )}
    </div>
  );
};

export default InputField;
