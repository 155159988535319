import { useAuthenticationStatus, useUserDefaultRole } from "@nhost/react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";

export function ProtectedRoute({ role }: any) {
  const { isAuthenticated, isLoading } = useAuthenticationStatus(); // get nhost authentication status
  const userDefaultRole = useUserDefaultRole();
  const { setTravelHouse } = travelHouseStore();
  const { travelHouse }: any = travelHouseStore();

  const location = useLocation(); // get location from react router dom

  // display loading while authentication is on loading
  if (isLoading) {
    return <div>Loading...</div>;
  }

  // If authentication true redirect
  if (isAuthenticated) {
    if (!travelHouse?.id && userDefaultRole !== "super-admin")
      return <Navigate to="/verify" state={{ from: location }} replace />;

    if (userDefaultRole === role) return <Outlet />;
  }
  // redirect to login when authentication is false
  return <Navigate to="/login" state={{ from: location }} replace />;
}
