import React from "react";
import { useQuery } from "@apollo/client";
// import gql queries
import { GET_VENDORS } from "./graphql/Query";
import travelHouseStore from "../../../../store/travelHouse/travelHouseStore";
import UserCard from "../../../../components/UserCard";
import UserAdd from "components/UserCard/components/UserAdd";
import SidePopup from "~/components/common/SidePopup";

export default function VendorsList() {
  const { travelHouse }: any = travelHouseStore();
  // get data based on query
  const { loading, data, error } = useQuery(GET_VENDORS, {
    variables: { thp_id: travelHouse.id },
  });

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error in the query {error.message}</div>;

  console.log("dataCUSTOMERS", data);
  return (
    <>
      <h1 className="text-gray-700 uppercase text-[18px] font-bold border-b-2 border-gray-100 mb-2">
        Vendors ({data.users.length})
      </h1>
      <div className="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-4">
        {data.users.map((user: any, index: any) => (
          <UserCard key={index} user={user} />
        ))}
        <SidePopup
          text="Add Vendor"
          component={<UserAdd defaultRole={"vendor"} />}
        />
      </div>

      {data.users.length === 0 && <h2>No record found</h2>}
    </>
  );
}
