// Import react router v6
import { Routes, Route } from "react-router-dom";
// Import protected route
import { ProtectedRoute } from "./ProtectedRoute";
// Import pages
import ProfilePage from "../pages/accountant/ProfilePage";
import Dashboard from "../pages/accountant/Dashboard";
import CustomerPayments from "pages/accountant/CustomerPayments";
import InboxPage from "~/pages/admin/Inbox";
import VendorsPage from "~/pages/accountant/VendorsPage";

export default function AccountantRoutes() {
  return (
    <Routes>
      <Route path="/accountant" element={<ProtectedRoute role="accountant" />}>
        <Route index element={<Dashboard />} />
        <Route path="profile" element={<ProfilePage />} />
        <Route path="inbox" element={<InboxPage />} />
        <Route path="customer-payments" element={<CustomerPayments />} />
        <Route path="vendors" element={<VendorsPage />} />
      </Route>
    </Routes>
  );
}
