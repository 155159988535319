import React from "react";
import WhatsAppChat from "~/components/common/Whatsapp/WhatsAppChat";
import WhatsappNew from "~/components/common/Whatsapp/WhatsappNew";

export default function DashboardStats() {
  return (
    <>
      <h1 className="text-gray-700 uppercase text-[18px] font-bold border-b-2 border-gray-100 mb-2">
        Dashbaord
      </h1>
      {/* <WhatsAppChat /> */}
      {/* <WhatsappNew /> */}
    </>
  );
}
