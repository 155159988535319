import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import InputField from "components/FormInputs/InputField";

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import travelHouseStore from "store/travelHouse/travelHouseStore";
import { ADD_PAYMENT_METHOD, ADD_TRANSACTION } from "./graphql/Mutation";
import {
  ACC_LIST,
  GET_BRANDS,
  GET_BRANDS_PAYMENTS_METHODS,
  GET_USER,
} from "./graphql/Query";
import commonStore from "~/store/commonStore/commonStore";
import { random } from "lodash";
import moment from "moment";
import { generateRandom10DigitCode } from "~/utils/generateRandomId";

export default function AddEquity({ setSidePopup }: any) {
  const [addTransaction] = useMutation(ADD_TRANSACTION);
  // const { setSidePopup } = commonStore();
  const { travelHouse }: any = travelHouseStore();
  const client = useApolloClient();
  const [isLoading, setIsLoading] = useState(false);
  // get data based on query
  const { loading, data, error } = useQuery(ACC_LIST, {
    variables: { thp_id: travelHouse.id },
  });

  const {
    loading: brandLoading,
    data: brandData,
    error: brandError,
  } = useQuery(GET_BRANDS, {
    variables: { thp_id: travelHouse.id },
  });

  const [brdId, setBrdId] = useState(null);

  const {
    loading: payMethodsLoading,
    data: payMethodsData,
    error: payMethodsErrors,
  } = useQuery(GET_BRANDS_PAYMENTS_METHODS, {
    skip: !brdId, // Don't run the query unless brdId is set
    variables: { brd_id: brdId },
  });

  const {
    loading: usersLoading,
    data: usersData,
    error: usersErrors,
  } = useQuery(GET_USER, {
    variables: { role: "admin", thp_id: travelHouse.id },
  });

  const {
    formState: { errors },
    register,
    handleSubmit,
    reset,
    control,
    watch,
    setValue,
    getValues,
  } = useForm();

  const selectedBrdId = watch("brd_id");
  useEffect(() => {
    setBrdId(selectedBrdId);
  }, [selectedBrdId]);

  const onSubmit = async (formData: any) => {
    const tenDigitCode: any = generateRandom10DigitCode();

    const transaction: any = [
      {
        brd_id: formData.brd_id,
        def_acc_id: 3,
        payment_method_id: formData.payment_method_id,
        transactions_no: tenDigitCode,
        date: moment(new Date()).format("YYYY-MM-DD"),
        type: "debit",
        amount: parseFloat(formData.amount),
      },
      {
        brd_id: formData.brd_id,
        def_acc_id: 1,
        user_id: formData.user_id,
        transactions_no: tenDigitCode,
        date: moment(new Date()).format("YYYY-MM-DD"),
        type: "credit",
        amount: -formData.amount,
      },
    ];
    console.log("formData", transaction);
    if (transaction[0].amount + transaction[1].amount === 0) {
      try {
        const res = await addTransaction({
          variables: {
            transactions: transaction,
          },
        });
        toast.success("Transaction added successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        reset();
        await client.resetStore();
        setSidePopup(false);
      } catch (e) {
        reset();
        console.error("error", e);
      }
    } else {
      toast.error("Transaction Error");
    }
  };

  // return loading while loading data
  if (loading) return <div>Loading...</div>;
  // Return error if get gql error
  if (error) return <div>Error in the query {error.message}</div>;

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-full">
      <h2 className="flex justify-center font-extrabold">Add Equity</h2>

      <div>
        <div>
          <label htmlFor="">Brands</label>
          <select
            {...register("brd_id", { required: true })}
            className=" mb-4 border text-sm rounded-lg block w-full p-2.5 "
          >
            {brandLoading && <option>Loading brands ...</option>}
            {!brandLoading &&
              brandData &&
              brandData.brd_list.map((brd: any) => (
                <option key={brd.id} value={brd.id}>
                  {brd.name}
                </option>
              ))}
          </select>
        </div>

        <div>
          <label htmlFor="">Payment Methods</label>
          <select
            {...register("payment_method_id", { required: true })}
            className=" mb-4 border text-sm rounded-lg block w-full p-2.5 "
          >
            {payMethodsLoading && <option>Loading payment methods ...</option>}
            {!payMethodsLoading &&
              payMethodsData &&
              payMethodsData.acc_payment_methods.map((pay: any) => (
                <option key={pay.id} value={pay.id}>
                  {pay.name}
                </option>
              ))}
          </select>
        </div>

        <div>
          <label htmlFor="">Owner</label>
          <select
            {...register("user_id", { required: true })}
            className=" mb-4 border text-sm rounded-lg block w-full p-2.5 "
          >
            {usersLoading && <option>Loading Owners ...</option>}
            {!usersLoading &&
              usersData &&
              usersData.users.map((user: any) => (
                <option key={user.id} value={user.id}>
                  {user.displayName}
                </option>
              ))}
          </select>
        </div>

        <div className="mt-2 mb-2">
          <InputField
            register={register}
            errors={errors}
            type="number"
            fieldName="amount"
            required={true}
            label="Amount"
            placeHolder="Enter Amount"
          />
        </div>
      </div>

      <div className="flex gap-2 w-full">
        <button
          className="w-full btn bg-green-500 text-white rounded-md p-2"
          type="submit"
        >
          {isLoading ? "Saving.." : "Save"}
        </button>
      </div>
    </form>
  );
}
