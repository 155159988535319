import moment from "moment";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import ViewIcon from "../../../../assets/svg/ViewIcon";
import DownloadIcon from "../../../../assets/svg/DownloadIcon";
import EditPencil from "../../../../assets/svg/EditPencil";
import InvoiceDetail from "./components/InvoiceDetail";

export default function InvoiceCard({ invoice, isActive, inquiry }: any) {
  const [showDetail, setShowDetail] = useState(false);

  return (
    <div className="bg-[url(/public/invoice-card@3x.png)] self-stretch flex-1 flex flex-col p-[25px] items-start justify-start gap-[43px] border-[1px] border-solid border-lightsteelblue bg-cover bg-no-repeat bg-[top] text-left text-mini text-darkslategray">
      <div className="self-stretch flex flex-row items-center justify-between ">
        <div className="relative">
          <span>{`Invoice # `}</span>
          <b>{invoice.invoice_no}</b>
        </div>
        {/* {/* {offer_price && ( */}
        <div className="w-[76px] flex flex-row items-center justify-between">
          <button onClick={() => setShowDetail(true)}>
            <ViewIcon />
          </button>
          {/* <Link to={`/invoice/pdf/download/${inquiry_no}`}>
              <DownloadIcon />
            </Link> */}
          {isActive ? "Active" : "Void"}
        </div>
        {/* )}  */}
      </div>

      <div className="self-stretch flex flex-col items-start justify-center text-right ">
        <b className="relative leading-[130%] text-29xl">
          {/* {invoice.inq_invoice_items[0].amount} */}£
          {invoice?.inq_invoice_items?.reduce(
            (sum: any, obj: any) => sum + parseFloat(obj.amount),
            0
          )}
        </b>
      </div>

      <div className="self-stretch flex flex-row items-center justify-between text-base">
        <div className="relative">
          <p className="m-0">Due Date</p>
          <p className="m-0">
            <b>{moment(invoice?.due_date).format("DD-MM-YYYY")}</b>
          </p>
        </div>
        {showDetail && (
          <div className="fixed z-[999] top-0 left-0 w-full min-h-screen bg-[#00000070] flex justify-center items-center py-[100px]">
            <div className="container max-w-[900px] m-[20px] w-full relative">
              <button
                onClick={() => setShowDetail(false)}
                className="text-[16px] text-white flex m-4 justify-center items-center h-[25px] w-[25px] bg-red-500 rounded-full absolute right-0 top-0"
              >
                x
              </button>
              <div className="bg-white h-[600px] overflow-y-scroll scroll-y rounded-lg p-4">
                <InvoiceDetail inquiry={inquiry} invoice={invoice} />
              </div>
            </div>
          </div>
        )}

        {/* {editInv && (
          <UpdateInvoiceModal
            hideForm={setEditInv}
            user={user}
            inquiryDetail={inq}
          />
        )} */}

        {/* {paidAmount === offer_price ? (
          <p className="text-green-500 font-bold">Paid</p>
        ) : paidAmount > offer_price ? (
          <p className="text-green-500  font-bold">Extra Paid</p>
        ) : paidAmount < offer_price ? (
          <p className="text-[#d68e4a] font-bold">Partialy Paid</p>
        ) : (
          <p className="text-tomato font-bold">UnPaid</p>
        )} */}
      </div>
    </div>
  );
}
