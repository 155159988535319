import { useApolloClient, useLazyQuery, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import InputField from "~/components/FormInputs/InputField";
import PhoneBox from "~/components/FormInputs/PhoneBox";
// import { INSERT_POST } from "./graphql/Mutation";
import { toast } from "react-toastify";
import LocationBox from "~/components/FormInputs/LocationBox";
import AirlineBox from "~/components/FormInputs/AirlineBox";
import { ALLOWED_IMAGE_FORMATS } from "~/config/constants";
import { useFileUpload } from "@nhost/react";
import { useNavigate } from "react-router-dom";
// import { GET_FLIGHT_PAGES } from "../../graphql/Query";
import CountryBox from "~/components/FormInputs/CountryBox";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { EDIT_EMAIL_TEMPLATE } from "./graphql/Mutation";

const SingleTemplate = ({ template }: any) => {
  const [content, setContent] = useState(template.content);
  const [editEmailTemplate] = useMutation(EDIT_EMAIL_TEMPLATE);
  const [isLoading, setIsLoading] = useState(false);
  const client = useApolloClient();

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    setValue,
    reset,
  } = useForm();

  const onSubmit = async (inpData: any) => {
    setIsLoading(true);
    console.log("xwt inpdata ===>", inpData);

    const res = await editEmailTemplate({
      variables: {
        id: template.id,
        data: {
          subject: inpData?.subject,
          content: content,
        },
      },
    });
    if (res?.data?.update_brd_emails_templates?.affected_rows > 0) {
      toast.success("Template update successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
    await client.refetchQueries({
      include: "all",
    });
    setIsLoading(false);

    // console.log("res", res);
    // if (res?.data?.insert_brd_posts?.affected_rows > 0) {
    //   reset();
    //   toast.success("Post added successfully", {
    //     position: toast.POSITION.BOTTOM_RIGHT,
    //   });
    //   await client.refetchQueries({
    //     include: "all",
    //   });
    //   navigate(
    //     `/admin/brands/${res?.data?.insert_brd_posts.returning?.[0].brd_id}/posts`
    //   );
    // }
  };

  //   let variables = [];

  //   template.def_emails_template.variables_allowed
  //     .split(",")
  //     .map((variable: any) => variables.push(`{{${variable}}}`));

  return (
    <div className="p-4 mt-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800">
      <div className="flex w-full justify-between">
        <h3 className="mb-4 text-xl font-semibold dark:text-white">
          {template.def_emails_template.name}
        </h3>
        <div className="">
          <p>
            <b>Variables: </b>
            {template.def_emails_template.variables_allowed
              .split(",")
              .map((variable: any) => {
                return (
                  <span className="text-[#156711] p-2">{`{{${variable}}}`}</span>
                );
              })}
          </p>
        </div>
      </div>
      <hr />

      <form onSubmit={handleSubmit(onSubmit)} className="pt-4">
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6 sm:col-full">
            <InputField
              register={register}
              errors={errors}
              fieldName="subject"
              type="text"
              inputClasses="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 py-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              label="Subject"
              placeHolder="Enter Post Title"
              defaultValue={template.subject}
            />
          </div>

          <div className="col-span-6 sm:col-full">
            <label className="mb-2 block">Content</label>
            <CKEditor
              editor={ClassicEditor}
              data={content}
              onReady={(editor) => {
                // You can store the "editor" and use when it is needed.
                console.log("Editor is ready to use!", editor);
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                setContent(data);
              }}
            />
          </div>
          <div className="col-span-6 sm:col-full">
            <button
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
              type="submit"
            >
              {isLoading ? "Updating.." : "Update Template"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SingleTemplate;
