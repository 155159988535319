import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { GET_PAGE_DATA } from "./graphql/Query";
import InputField from "~/components/FormInputs/InputField";
import { useForm } from "react-hook-form";
import EditHeroSection from "./components/EditHeroSection";
import EditFeatureSection from "./components/EditFeatureSection";
import HomePageSeo from "./components/HomepageSeo";
import RichContentEditor from "./components/RichContentEditor";

const MangePages = () => {
  const { slug, brd_id } = useParams();
  const { loading, data } = useQuery(GET_PAGE_DATA, {
    variables: {
      id: brd_id,
      slug: slug === "home" ? `/` : slug,
    },
  });
  if (loading) return <p>Loading...</p>;
  const page = data?.brd_pages?.at(0);
  console.log("xwt page data ====>", page);
  return (
    <div className="mb-4 col-span-full xl:mb-2">
      <EditHeroSection page={page} />
      {slug === "home" ? (
        <EditFeatureSection page={page} />
      ) : (
        <RichContentEditor page={page} />
      )}
      <HomePageSeo page={page} />
    </div>
  );
};

export default MangePages;
