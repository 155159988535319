import moment from "moment";
import React, { useState } from "react";
import SaveFloppy from "./assets/SaveFloppy";
import { EDIT_TICKET_REQUEST } from "./graphql/Mutation";
import { useApolloClient, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import EditTicket from "./components/EditTicket";
import { useUserDefaultRole } from "@nhost/react";
import { URLs } from "~/config/enums";

export default function TicketRequestCard({ inquiry }: any) {
  const [status, setStatus] = useState(inquiry?.status);
  const [isLoading, setIsLoading] = useState(false);
  const [hideForm, setHideForm] = useState(false);
  const [editRequest] = useMutation(EDIT_TICKET_REQUEST);
  const client = useApolloClient();

  const updateIssuanceStatus = async () => {
    setIsLoading(true);
    const payload = {
      id: inquiry.id,
      status: status,
    };
    try {
      const res = await editRequest({ variables: payload });
      if (res.data?.update_ticket_issuance_request?.returning?.length > 0) {
        toast.success("Request Status Updated Successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
        await client.resetStore();
      }
    } catch (e) {
      setIsLoading(false);
      console.log(e);
      toast.error(`${e}`);
    }
  };

  const userRole = useUserDefaultRole();

  return (
    <div className="self-stretch bg-white-100 w-full flex flex-col p-[25px] items-start justify-center text-left text-mini text-darkslategray border-[1px] border-solid border-lightsteelblue">
      <div className="@container w-full">
        <form className="self-stretch flex gap-3 text-center items-start justify-between">
          {userRole !== "user" && (
            <>
              <div className="relative">
                <span>{`Fair Expiry`}</span>
                <br />
                <b>
                  {moment(inquiry?.selected_suggestion?.fare_expiry).format(
                    "DD MM, YYYY"
                  )}
                </b>
              </div>
              <div className="relative">
                <span>{`Cancelation Charges`}</span>
                <br />
                <b>{inquiry?.inq_tickets[0]?.cancelation_charges}</b>
              </div>
              <div className="relative">
                <span>{`PNR`}</span>
                <br />
                <b>{inquiry?.inq_tickets[0]?.pnr}</b>
              </div>
              <div className="relative">
                <span>{`IBE`}</span>
                <br />
                <b>{inquiry?.inq_tickets[0]?.ibe}</b>
              </div>
            </>
          )}
          <div className="relative">
            <span>{`Status`}</span>
            <br />
            <b className="capitalize">{inquiry?.inq_tickets[0]?.status}</b>
          </div>

          {inquiry?.inq_tickets[0]?.url && (
            <>
              <div className="relative">
                <span>Ticket File</span>
                <br />
                <a
                  target="_blank"
                  href={`${URLs.FILE_URL}${inquiry?.inq_tickets[0]?.url}`}
                  rel="noreferrer"
                >
                  Download File
                </a>
              </div>
            </>
          )}
          {userRole !== "user" && inquiry.status !== "completed" && (
            <div className="relative">
              <span>Action</span>
              <>
                <div className="flex items-center gap-2">
                  <button
                    type="button"
                    className="block border mx-auto border-primary rounded-full px-3 py-1 text-primary cursor-pointer hover:bg-primary hover:text-white"
                    onClick={() => {
                      setHideForm(!hideForm);
                    }}
                  >
                    {!hideForm ? "Edit" : "Close"}
                  </button>
                </div>
                <br />
              </>
            </div>
          )}
        </form>
      </div>
      {hideForm && (
        <div className="flex items-center gap-2 mt-[20px] border-t-2 border-gray w-full">
          <EditTicket inquiry={inquiry} setHideForm={setHideForm} />
        </div>
      )}
    </div>
  );
}
