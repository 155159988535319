import { gql } from "@apollo/client";

export const GET_TRANSACTIONS = gql`
  query GetTransaction($thp_id: uuid!) {
    acc_transactions(
      where: { brd_list: { thp_id: { _eq: $thp_id } } }
      order_by: { created_at: desc }
    ) {
      brd_id
      acc_id
      inq_id
      payment_method_id
      user_id
      transactions_no
      date
      type
      amount
      brd_list {
        name
      }
      inq_list {
        inquiry_no
      }
      acc_list {
        type
        name
      }
      def_acc_list {
        type
        name
      }
      acc_list {
        name
      }
      users {
        displayName
      }
      users {
        displayName
      }
      acc_payment_method {
        name
      }
    }
  }
`;
