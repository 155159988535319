import { gql } from "@apollo/client";

export const GET_BRAND_EMAIL_TEMPLATES = gql`
  query GetBrandEamilTemplates($brd_id: uuid!) {
    brd_emails_templates(where: { brd_id: { _eq: $brd_id } }) {
      id
      content
      subject
      template_id
      def_emails_template {
        name
        variables_allowed
      }
      created_at
      updated_at
    }
  }
`;
