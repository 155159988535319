import { useApolloClient, useMutation } from "@apollo/client";
import React from "react";
import { useForm } from "react-hook-form";
import InputField from "~/components/FormInputs/InputField";
import PhoneBox from "~/components/FormInputs/PhoneBox";
import {
  INSERT_CONTACT_DETAILS,
  UPDDATE_CONTACT_DETAILS,
} from "./graphql/Mutation";
import { toast } from "react-toastify";
import CountryBox from "~/components/FormInputs/CountryBox";
import InputDate from "~/components/FormInputs/InputDate";

const BrandGeneralInfo = ({ brand }: any) => {
  const contact = brand?.brd_contact_details?.at(0);
  const [updateContact] = useMutation(UPDDATE_CONTACT_DETAILS);
  const [insertContact] = useMutation(INSERT_CONTACT_DETAILS);
  const client = useApolloClient();
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    setValue,
  } = useForm();
  const onSubmit = async (inpData: any) => {
    if (contact?.id) {
      const res = await updateContact({
        variables: {
          id: { id: contact.id },
          data: {
            country: inpData?.country?.label,
            city: inpData?.city,
            address1: inpData?.address1,
            phone: inpData?.phone,
            whatsapp: inpData?.whatsapp,
            email: inpData?.email,
            phone_without_country_code: inpData?.phone_without_country_code,
          },
        },
      });

      if (res?.data?.update_brd_contact_detail_by_pk?.id) {
        toast.success("Branch updated successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        await client.refetchQueries({
          include: "all",
        });
      }
    } else {
      const res = await insertContact({
        variables: {
          data: {
            country: inpData?.country?.label,
            city: inpData?.city,
            address1: inpData?.address1,
            phone: inpData?.phone,
            whatsapp: inpData?.whatsapp,
            email: inpData?.email,
            phone_without_country_code: inpData?.phone_without_country_code,
            brd_id: brand.id,
          },
        },
      });

      if (res?.data?.insert_brd_contact_detail_one?.id) {
        toast.success("Branch updated successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        await client.refetchQueries({
          include: "all",
        });
      }
    }
  };
  return (
    <div className="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800">
      <h3 className="mb-4 text-xl font-semibold dark:text-white">
        General information
      </h3>
      <form onSubmit={handleSubmit(onSubmit)} action="#">
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-full">
            <InputField
              register={register}
              errors={errors}
              fieldName="brand_name"
              inputClasses="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 py-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              label="Branch Name"
              placeHolder="Branch Name"
              defaultValue={brand?.name}
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <CountryBox
              control={control}
              setValue={setValue}
              defaultValue={contact?.country}
              errors={errors}
              fieldName="country"
              inputClasses="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 py-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              label="Country"
              placeholder="Country"
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <InputField
              register={register}
              errors={errors}
              fieldName="city"
              inputClasses="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 py-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              label="City"
              placeHolder="City"
              defaultValue={contact?.city}
            />
          </div>
          {/* <div className="col-span-6 sm:col-span-3">
            <InputDate
              register={register}
              errors={errors}
              control={control}
              fieldName="dob"
              inputClasses="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 py-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              label="Date of Birth"
              placeHolder="Dob"
            />
          </div> */}

          <div className="col-span-6 sm:col-span-3">
            <InputField
              register={register}
              errors={errors}
              fieldName="address1"
              inputClasses="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 py-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              label="Address"
              placeHolder="Address"
              defaultValue={contact?.address1}
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <InputField
              register={register}
              errors={errors}
              fieldName="email"
              type="email"
              inputClasses="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 py-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              label="Email"
              placeHolder="Email"
              defaultValue={contact?.email}
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <PhoneBox
              errors={errors}
              setValue={setValue}
              control={control}
              label="Phone"
              fieldName="phone"
              inputClasses="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 py-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              defaultValue={contact?.phone}
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <PhoneBox
              errors={errors}
              setValue={setValue}
              control={control}
              label="Whatsapp"
              fieldName="whatsapp"
              inputClasses="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 py-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              defaultValue={contact?.whatsapp}
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <InputField
              errors={errors}
              register={register}
              label="Phone Without Country Code"
              placeHolder="e.g 0331 2323 2323"
              fieldName="phone_without_country_code"
              inputClasses="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 py-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              defaultValue={contact?.phone_without_country_code}
            />
          </div>

          <div className="col-span-6 sm:col-full">
            <button
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
              type="submit"
            >
              Save Branch Settings
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default BrandGeneralInfo;
