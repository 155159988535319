export const InquiresTabs = [
  {
    name: "Unassign",
    className: "bg-orange-800",
  },
  {
    name: "Assigned",
    className: "bg-orange-500",
  },
  {
    name: "Inprocess",
    className: "bg-orange-800",
  },
  {
    name: "Completed",
    className: "bg-green-700",
  },
  {
    name: "All",
    className: "bg-green-700",
  },
  //   {
  //     name: "Canceled",
  //     className: "bg-red-700",
  //   },
];
