const PaymentTypes = {
  BANK: "bank",
  STRIPE_THP: "stripe-thp",
  STRIPE: "stripe",
};

const BankDetails = ({ account_title, account_no, swift_code }: any) => (
  <>
    <ListItem title="Bank Name" value={account_title} />
    <ListItem title="Account No" value={account_no} />
    <ListItem title="Sort Code" value={swift_code} />
  </>
);

const ListItem = ({ title, value }: any) => (
  <li className="flex items-center">
    <span>{title}:</span>
    <span className="text-gray-600 pl-[10px]">
      {value || <i className="text-[#ff9114]">Not Set</i>}
    </span>
  </li>
);

const PaymentMethodBox = ({ payment_method, index, setEdit }: any) => {
  const { type, name, account_title, account_no, swift_code, brd_list } =
    payment_method;

  const renderDetails = () => {
    switch (type) {
      case PaymentTypes.BANK:
        return (
          <BankDetails
            account_title={account_title}
            account_no={account_no}
            swift_code={swift_code}
          />
        );
      case PaymentTypes.STRIPE:
      default:
        return null;
    }
  };

  return (
    <div className="w-full px-4 mb-4 cursor-pointer">
      <div className="border rounded bg-white p-4">
        <div className="flex justify-between items-center mb-4">
          <div>{payment_method.default ? "Default" : ""}</div>{" "}
          <button
            onClick={() => setEdit(true)}
            className="ml-2 text-gray-600 hover:text-blue-500"
          >
            Edit
          </button>
        </div>
        <ul>
          <ListItem title="Brand" value={brd_list?.name} />
          <ListItem
            title="Type"
            value={
              type === PaymentTypes.BANK
                ? "Bank Payment"
                : type === PaymentTypes.STRIPE_THP
                ? "Stripe (THP)"
                : "Stripe"
            }
          />
          <ListItem title="Payment Method Name" value={name} />
          {renderDetails()}
        </ul>
      </div>
    </div>
  );
};

export default PaymentMethodBox;
