import React from "react";
import CostRow from "./components/CostRow";
import CostRowAdd from "./components/CostRowAdd";

export default function PassengersCost({
  passengers,
  errors,
  register,
  control,
  setValue,
  suggestion_cost,
}: any) {
  const passengerIdsArr1 = suggestion_cost.map((obj: any) => obj.passenger_id);
  const filteredPassengers = passengers.filter(
    (obj: any) => !passengerIdsArr1.includes(obj.id)
  );

  return (
    <div>
      <div className="bg-gray-100 rounded-lg p-2 mt-4">
        <div className="flex flex-col border-b-2 border-gray-300 w-full pb-2">
          {/* Section Heading */}
          <h1 className="flex-1 w-full font-bold text-left">Passenger Cost</h1>
        </div>
        <div className="mt-2">
          {suggestion_cost &&
            suggestion_cost?.length > 0 &&
            suggestion_cost.map((suggestion_cost_detail: any, index: any) => {
              return (
                <>
                  <CostRow
                    suggestion_cost_detail={suggestion_cost_detail}
                    register={register}
                    errors={errors}
                    index={index}
                    control={control}
                    setValue={setValue}
                  />
                  <hr className="border-gray-300  border-dashed my-2" />
                </>
              );
            })}
          {filteredPassengers &&
            filteredPassengers?.length > 0 &&
            filteredPassengers.map((passenger: any, index: any) => {
              console.log("passenger", passenger);
              return (
                <>
                  <CostRowAdd
                    passenger={passenger}
                    register={register}
                    errors={errors}
                    index={index}
                    control={control}
                    setValue={setValue}
                  />
                  <hr className="border-gray-300  border-dashed my-2" />
                </>
              );
            })}
        </div>
      </div>
    </div>
  );
}
