import React, { useState } from "react";
import { useQuery } from "@apollo/client";
// import gql queries
import { GET_ALL_INQUIRIES, GET_ALL_INQUIRIES_COUNT } from "./graphql/Query";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import { useUserId } from "@nhost/react";
import InquiryTable from "~/modules/InquiriesModule/components/InquiryTable";
export default function Allinquires({ brd_ids, setAll, activeTab }: any) {
  const limit = 20;
  const { travelHouse }: any = travelHouseStore();
  const [page, setPage] = useState(0);
  const picked_by = useUserId();
  // get data based on query
  const { loading, data, error } = useQuery(GET_ALL_INQUIRIES, {
    variables: {
      thp_id: travelHouse.id,
      limit: limit,
      offset: page * limit,
      brd_ids,
      picked_by,
    },
  });

  const {
    loading: countLoading,
    error: countError,
    data: countData,
  } = useQuery(GET_ALL_INQUIRIES_COUNT, {
    variables: {
      thp_id: travelHouse.id,
      brd_ids,
      picked_by,
    },
  });
  // return loading while loading data
  if (loading) return <div></div>;
  if (countLoading) return <div></div>;
  // Return error if get gql error
  if (error) return <div>Error in the query {error.message}</div>;

  setAll(countData?.inq_list_aggregate?.aggregate?.count);
  if (activeTab !== "All") return <></>;
  return (
    <InquiryTable
      inquires={data.inq_list}
      page={page}
      total={countData?.inq_list_aggregate?.aggregate?.count}
      setPage={setPage}
    />
  );
}
