import { gql } from "@apollo/client";

export const GET_DEF_EMAIL_TEMPLATES = gql`
  query GetDefEamilTemplates {
    def_emails_templates {
      id
      content
      for_role
      name
      subject
      variables_allowed
      created_at
      updated_at
    }
  }
`;
