/* eslint-disable jsx-a11y/img-redundant-alt */

import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import Sun from "../../../assets/svg/Sun";
import PipeLine from "../../../assets/svg/PipeLine";
import { useSignOut, useUserDefaultRole } from "@nhost/react";
import { getCurrentUrl } from "../../../utils/getCurrentUrl";
import { useUserData } from "@nhost/react";
import Notifications from "./Notifications";
import Avatar from "../../../assets/img/blank.jpeg";
import { FaEnvelope, FaEnvelopeOpenText } from "react-icons/fa";

const TopMenuNew = () => {
  const userDefaultRole = useUserDefaultRole();
  const [dropdown, setDropdown] = useState(false);
  const [activeMenu, setActiveMenu] = useState("");
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { signOut } = useSignOut();
  const userData = useUserData();

  console.log("dropdown", dropdown);
  // const breadCrump = ["dashboard", "customer"];

  return (
    <header className="sticky z-[4] top-0 flex items-center justify-between pr-[20px] md:px-[20px] lg:px-[38px] leading-[72px] bg-white border-b-2 border-[rgba(0, 0, 0, 0.1)] ">
      <button
        id="hamburgerBtn"
        // onClick={() => setSidebar(!sidebar)}
        className="p-6 md:hidden focus:outline-none"
      >
        <div className="h-1 w-5 bg-green-500 mb-1"></div>
        <div className="h-1 w-5 bg-green-500 mb-1"></div>
        <div className="h-1 w-5 bg-green-500"></div>
      </button>
      <div className="hidden md:block">
        <h1
          className="text-[24px] font-[400]"
          style={{ textTransform: "capitalize" }}
        >
          Alsiraj Group
        </h1>
      </div>

      <div className="flex items-center gap-[10px] sm:gap-[20px]">
        {/* <button>
          <Link
            title="Inbox"
            to={`${getCurrentUrl()}/${userDefaultRole}/inbox`}
          >
            <FaEnvelopeOpenText color="#3a3939" size="18px" />
          </Link>
        </button> */}
        {/* Render Notifications */}
        <Notifications />
        <button>
          <PipeLine />
        </button>
        <div ref={dropdownRef} className="relative flex items-center h-[72px]">
          <button
            onClick={() => {
              setDropdown(!dropdown);
              setActiveMenu(activeMenu === "main" ? "" : "main");
            }}
            className="focus:outline-none flex items-center gap-2"
          >
            {/* <img  className="h-8 w-8 rounded-full" src={user?.avatarUrl?.includes('blank') ? '/assets/img/avatar.jpeg'  : user?.avatarUrl} alt="user image" /> */}
            <img
              className="h-8 w-8 rounded-full"
              src={Avatar}
              alt="user image"
              title=""
            />

            <span className="hidden sm:block light text-[14px] capitalize leading-[20px] text-left">
              {userData?.displayName}
              <br />
              <span className="text-gray-700 text-[12px]">
                {userData?.defaultRole}
              </span>
            </span>
          </button>

          {activeMenu === "main" && (
            <div
              // ref={dropdownRef}
              id="userMenu"
              className="absolute top-[65px] right-[0px] mt-2 w-48 bg-white rounded-[0_0_10px_10px] overflow-hidden shadow-lg border-[1px] border-t-0 border-slate-100 z-30"
            >
              <div onClick={() => setActiveMenu("")}>
                <Link
                  to={`${getCurrentUrl()}/${userDefaultRole}/profile`}
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-green-500 hover:text-white leading-normal	"
                >
                  Profile
                </Link>
              </div>
              {/* <div onClick={() => setActiveMenu("")}>
                <Link
                  to={`${getCurrentUrl()}/${userDefaultRole}/customers`}
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-green-500 hover:text-white leading-normal	"
                >
                  Customers
                </Link>
              </div>
              <div onClick={() => setActiveMenu("")}>
                <Link
                  to={`${getCurrentUrl()}/${userDefaultRole}/vendors`}
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-green-500 hover:text-white leading-normal	"
                >
                  Vendors
                </Link>
              </div> */}
              {/* <div onClick={() => setActiveMenu("")}>
                <Link
                  to={`${getCurrentUrl()}/${userDefaultRole}/payment-methods`}
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-green-500 hover:text-white leading-normal	"
                >
                  Payments Methods
                </Link>
              </div> */}
              <div onClick={() => setActiveMenu("")}>
                <Link
                  to={`${getCurrentUrl()}/${userDefaultRole}/accounts`}
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-green-500 hover:text-white leading-normal	"
                >
                  Account List
                </Link>
              </div>
              {/* <div onClick={() => setActiveMenu("")}>
                <Link
                  to={`${getCurrentUrl()}/${userDefaultRole}/transactions`}
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-green-500 hover:text-white leading-normal	"
                >
                  Journal Entry
                </Link>
              </div> */}

              {userDefaultRole === "admin" && (
                <>
                  {/* <div onClick={() => setActiveMenu("")}>
                    <Link
                      to={`${getCurrentUrl()}/${userDefaultRole}/subscriptions`}
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-green-500 hover:text-white leading-normal	"
                    >
                      Subscription
                    </Link>
                  </div> */}
                  {/* <hr /> */}
                  <div onClick={() => setActiveMenu("")}>
                    <Link
                      to={`${getCurrentUrl()}/${userDefaultRole}/teams`}
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-green-500 hover:text-white leading-normal	"
                    >
                      Team Members
                    </Link>
                  </div>
                  <div onClick={() => setActiveMenu("")}>
                    <Link
                      to={`${getCurrentUrl()}/${userDefaultRole}/brands`}
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-green-500 hover:text-white leading-normal	"
                    >
                      Branches
                    </Link>
                  </div>{" "}
                  {/* <div onClick={() => setActiveMenu("")}>
                    <Link
                      to={`${getCurrentUrl()}/${userDefaultRole}/domains`}
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-green-500 hover:text-white leading-normal	"
                    >
                      Portal Domain
                    </Link>
                  </div>
                  <div onClick={() => setActiveMenu("")}>
                    <Link
                      to={`${getCurrentUrl()}/${userDefaultRole}/profit-slabs`}
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-green-500 hover:text-white leading-normal	"
                    >
                      Profit Slabs
                    </Link>
                  </div> */}
                </>
              )}
              <hr />
              <button
                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-green-500 hover:text-white leading-normal	"
                onClick={() => {
                  sessionStorage.clear();
                  signOut();
                }}
              >
                Logout
              </button>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default TopMenuNew;
