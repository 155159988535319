import {
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import travelHouseStore from "store/travelHouse/travelHouseStore";
import { ADD_TRANSACTIONS } from "../../graphql/Mutations";
import { toast } from "react-toastify";
import {
  createCustomer,
  createInvoiceItems,
  createInvoice,
  sendInvoice,
} from "~/utils/Stripe/stripeFunctions";
import useNotifications from "hooks/useNotifications";
import { useUserDefaultRole } from "@nhost/react";
import Stripe from "stripe";
import { STRIPE_SK } from "~/config/enums";
import { SEND_EMAIL } from "~/config/Mutation";
import { CustomerTemplates } from "~/utils/TemplatesAll";
import { GET_PAYMENT_METHODS } from "../../graphql/Query";
import { sendGenericEmail } from "~/utils/sendEmail";
import {
  GET_EMAILS,
  GET_TRAVEL_HOUSE_BY_ID,
} from "~/modules/InboxModule/segments/Inbox/graphql/Query";
import { useUserData } from "@nhost/react";

export default function StripePaymentForm({
  stripe,
  inquiry_id,
  setHideForm,
  user,
  brd_id,
  user_email,
  brand_url,
  brand_name,
  brd_domain,
  picked_by,
}: any) {
  const { travelHouse }: any = travelHouseStore();
  const [addTransactions] = useMutation(ADD_TRANSACTIONS);
  const [sendEmail] = useMutation(SEND_EMAIL);
  const client = useApolloClient();
  const [buttonText, setButtonText] = useState("Add Payment");
  const todayUTC = new Date().toISOString().split("T")[0];
  const { data } = useQuery(GET_PAYMENT_METHODS, {
    variables: { th_id: travelHouse.id, brd_id: brd_id },
  });
  const userData = useUserData();

  const { loading: travelHouseLoading, data: travelHouseData } = useQuery(
    GET_TRAVEL_HOUSE_BY_ID,
    {
      variables: { id: travelHouse?.id },
      fetchPolicy: "network-only",
    }
  );
  let StripeKey: any;

  const alert = useNotifications();
  const userRole = useUserDefaultRole();

  console.log("stripe_data", data);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const onSubmit = async (formData: any) => {
    console.log("StripeKey", stripe);

    formData["inq_id"] = inquiry_id; // add inquiry id to formdata
    formData["payment_method_id"] = stripe.id || stripe.id; // add travelhouse id to formdata
    formData["status"] = "pending"; // add status  to formdata

    // const customer = await createCustomer();
    setButtonText("Creating Stripe Customer..");
    const stripeHelper = new Stripe(stripe.stripe_secret, {
      apiVersion: "2022-11-15",
    });
    createCustomer(stripeHelper, user.email, user.displayName).then(
      (customer: any) => {
        setButtonText("Creating Stripe Invoice..");
        createInvoice(
          stripeHelper,
          customer.id,
          new Date(formData["due_date"])
        ).then((invoice: any) => {
          setButtonText("Creating Stripe Invoice Item..");
          createInvoiceItems(
            stripeHelper,
            customer.id,
            formData["amount"] * 100,
            "gbp",
            invoice.id
          ).then((invoiceItem: any) => {
            setButtonText("Sending Stripe Invoice..");
            sendInvoice(stripeHelper, invoice?.id).then(
              async (invoiceRes: any) => {
                // Invoice Crated
                formData["receipt"] = invoiceRes.hosted_invoice_url;
                formData["ref_no"] = invoice?.id; // add receipt to formdata
                delete formData["due_date"];

                setButtonText("Adding Payment..");
                try {
                  // Create Suggestion function call
                  const res = await addTransactions({
                    variables: { transactions: [formData] },
                  });
                  if (res.data?.insert_inq_transection?.returning?.length > 0) {
                    toast.success("Transaction added successfully", {
                      position: toast.POSITION.BOTTOM_RIGHT,
                    });

                    const variables = {
                      from: {
                        th_name: brand_name,
                      },
                      to: {
                        email: user_email,
                      },
                      variables: {
                        url: brand_url ?? "",
                      },
                    };
                    const { emails, body, subject } =
                      CustomerTemplates.sendBookingRequestNotification(
                        variables
                      );
                    // const emailResp = await sendEmail({
                    //   variables: {
                    //     emails: emails,
                    //     body: body,
                    //     subject,
                    //     from: "info@" + brd_domain,
                    //   },
                    // });
                    // console.log("emailResp", emailResp);
                    try {
                      const emailData = {
                        emails: emails,
                        body: body,
                        subject,
                        from: "info@" + brd_domain,
                      };
                      if (travelHouseData?.thp_list[0]?.email_refresh_token) {
                        // await sendEmailGmail({
                        //   variables: {
                        //     refresh_token:
                        //       travelHouseData?.thp_list[0]?.email_refresh_token,
                        //     sendEmail: true,
                        //     to: emails,
                        //     subject: subject,
                        //     body: body,
                        //     from: userData?.metadata?.alias,
                        //     th_id: travelHouse?.id,
                        //   },
                        // });
                        const response = await client.query({
                          query: GET_EMAILS,
                          variables: {
                            refresh_token:
                              travelHouseData?.thp_list[0]?.email_refresh_token,
                            sendEmail: true,
                            to: emails,
                            subject: subject,
                            body: body,
                            from: userData?.metadata?.alias,
                            th_id: travelHouse?.id,
                            alias: false,
                          },
                        });
                        console.log("response", response);
                      } else {
                        const emailResp = await sendGenericEmail(
                          emailData,
                          sendEmail
                        );
                        console.log("emailResp", emailResp);
                      }
                      // Handle success or do any additional logic here
                    } catch (error) {
                      // Handle error
                      console.error("Error sending email:", error);
                    }
                    const {
                      emails: newEmails,
                      body: newBody,
                      subject: newSubject,
                    } = CustomerTemplates.sendInvoiceReceivedNotification(
                      variables
                    );
                    const emailRespNew = await sendEmail({
                      variables: {
                        emails: newEmails,
                        body: newBody,
                        subject: newSubject,
                        from: "info@" + brd_domain,
                      },
                    });
                    console.log("emailResp", emailRespNew);

                    if (userRole === "user") {
                      await alert.newNotification(
                        picked_by,
                        `Customer created the stripe payment, please check your portal`,
                        "",
                        ``
                      );
                    } else {
                      await alert.newNotification(
                        user?.id,
                        `Consultant created the stripe payment,, please check your portal`,
                        "",
                        ``
                      );
                    }

                    await client.refetchQueries({
                      include: "all", // Consider using "active" instead!
                    });
                  }
                  setButtonText("Add Payment");
                  setHideForm(false); // hide form modal
                } catch (e) {
                  setButtonText("Add Payment");
                  setHideForm(false);
                  console.error("error", e);
                }
              }
            );
          });
        });
      }
    );
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="w-full">
        <div className="mb-2">
          <input
            className="border border-slate-300 py-[5px] h-[44px] px-[15px] text-[14px] rounded-[5px] w-full"
            type="date"
            min={todayUTC}
            placeholder="Due Date"
            // defaultValue={res?.data?.travel_house?.brand}
            {...register("due_date", {
              required: "Due Date is required",
            })}
            aria-invalid={errors.due_date ? "true" : "false"}
          />
        </div>
        <div className="grid grid-cols-1 gap-4">
          <div className="">
            <input
              className="border border-slate-300 py-[5px] h-[44px] px-[15px] text-[14px] rounded-[5px] w-full"
              type="number"
              placeholder="Amount"
              // defaultValue={res?.data?.travel_house?.brand}
              {...register("amount", {
                required: "Amount is required",
              })}
              aria-invalid={errors.amount ? "true" : "false"}
            />
          </div>
        </div>
        <button
          type="submit"
          className="bg-secondary block w-full text-white px-5 py-2 rounded hover:cursor-pointer hover:bg-green-700 transition-all mt-[20px]"
        >
          {buttonText}
        </button>
      </form>
    </>
  );
}
