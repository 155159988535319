import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import React from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import InputField from "~/components/FormInputs/InputField";
import {
  INSERT_DEFAULT_SETTING,
  UPDATE_DEFAULT_SETTING,
} from "./graphql/Mutation";
import { GET_DEFAULT_SETTINGS } from "./graphql/Query";

const TestimonialSettings = ({ setSettings, settings }: any) => {
  const { brd_id } = useParams();
  const { loading, data } = useQuery(GET_DEFAULT_SETTINGS, {
    variables: { id: brd_id },
  });
  const [insertDefaultSettings] = useMutation(INSERT_DEFAULT_SETTING);
  const [updateDefaultSettings] = useMutation(UPDATE_DEFAULT_SETTING);
  const client = useApolloClient();
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    setValue,
  } = useForm();
  if (loading) return <p>Loading...</p>;
  const defSettings = data?.brd_default_setting?.at(0);
  const onSubmit = async (inpData: any) => {
    if (defSettings?.id) {
      const res = await updateDefaultSettings({
        variables: {
          id: { id: defSettings?.id },
          data: {
            hot_destinations_heading: inpData?.hot_destinations_heading,
            hot_destinations_sub_heading: inpData?.hot_destinations_sub_heading,
            destinations_main_heading: inpData?.destinations_main_heading,
            destinations_button_text: inpData?.destinations_button_text,
            destinations_footer_text: inpData?.destinations_footer_text,
            destination_blogs_heading: inpData?.destination_blogs_heading,
            destination_blogs_sub_heading:
              inpData?.destination_blogs_sub_heading,
            destination_tips_heading: inpData?.destination_tips_heading,
            destination_tips_sub_heading: inpData?.destination_tips_sub_heading,
            destination_contact_heading: inpData?.destination_contact_heading,
            destination_contact_sub_heading:
              inpData?.destination_contact_sub_heading,
          },
        },
      });
      if (res?.data?.update_brd_default_setting_by_pk?.id) {
        toast.success("Social Media  updated successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        await client.refetchQueries({
          include: "all",
        });
      }
    } else {
      const res = await insertDefaultSettings({
        variables: {
          data: {
            brd_id: brd_id,
            hot_destinations_heading: inpData?.hot_destinations_heading,
            hot_destinations_sub_heading: inpData?.hot_destinations_sub_heading,
            destinations_main_heading: inpData?.destinations_main_heading,
            destinations_button_text: inpData?.destinations_button_text,
            destinations_footer_text: inpData?.destinations_footer_text,
            destination_blogs_heading: inpData?.destination_blogs_heading,
            destination_blogs_sub_heading:
              inpData?.destination_blogs_sub_heading,
            destination_tips_heading: inpData?.destination_tips_heading,
            destination_tips_sub_heading: inpData?.destination_tips_sub_heading,
            destination_contact_heading: inpData?.destination_contact_heading,
            destination_contact_sub_heading:
              inpData?.destination_contact_sub_heading,
          },
        },
      });

      if (res?.data?.insert_brd_default_setting_one?.id) {
        toast.success("Settings updated successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        await client.refetchQueries({
          include: "all",
        });
      }
    }
  };

  return (
    <>
      <div
        onClick={() => setSettings(false)}
        className="bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-30"
      ></div>
      <div
        id="drawer-faq-setting-default"
        className={`fixed top-0 right-0 z-40 w-full h-screen max-w-md p-4 overflow-y-auto transition-transform bg-white dark:bg-gray-800 ${
          settings ? "translate-x-0" : "translate-x-full"
        }`}
        aria-labelledby="drawer-label"
        aria-hidden="true"
      >
        <h5
          id="drawer-label"
          className="inline-flex items-center mb-6 text-sm font-semibold text-gray-500 uppercase dark:text-gray-400"
        >
          Destination Page Settings
        </h5>
        <button
          onClick={() => setSettings(false)}
          type="button"
          data-drawer-dismiss="drawer-faq-setting-default"
          aria-controls="drawer-faq-setting-default"
          className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-2.5 right-2.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
        >
          <svg
            aria-hidden="true"
            className="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
          <span className="sr-only">setting menu</span>
        </button>
        <form onSubmit={handleSubmit(onSubmit)} action="#">
          <div className="space-y-4">
            <div>
              <InputField
                register={register}
                errors={errors}
                fieldName="hot_destinations_heading"
                inputClasses="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 py-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                label="Hompage Destinations Heading"
                placeHolder="Hompage Destinations Heading"
                defaultValue={defSettings?.hot_destinations_heading}
              />
            </div>
            <div>
              <InputField
                register={register}
                errors={errors}
                fieldName="hot_destinations_sub_heading"
                inputClasses="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 py-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                label="Hompage Destinations Sub Heading"
                placeHolder="Hompage Destinations Sub Heading"
                defaultValue={defSettings?.hot_destinations_sub_heading}
              />
            </div>
            <div>
              <InputField
                register={register}
                errors={errors}
                fieldName="destinations_main_heading"
                inputClasses="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 py-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                label="Craft Your Destination Page Main Heading"
                placeHolder="Craft Your Destination Page Main Heading"
                defaultValue={defSettings?.destinations_main_heading}
              />
              <p className="mb-4 mt-0 text-[13px] text-gray-500 dark:text-gray-300">
                Available variables are {`{{depart_destination}}`},{" "}
                {`{{arrival_destination}}`}, {`{{cheap_price}}`}
              </p>
            </div>
            <div>
              <InputField
                register={register}
                errors={errors}
                fieldName="destinations_button_text"
                inputClasses="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 py-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                label="Homepage button title for hot destinations"
                placeHolder="Homepage button title for hot destinations"
                defaultValue={defSettings?.destinations_button_text}
              />
              <p className="mb-4 mt-0 text-[13px] text-gray-500 dark:text-gray-300">
                Available variables are {`{{depart_destination}}`},{" "}
                {`{{arrival_destination}}`}
              </p>
            </div>
            <div>
              <InputField
                register={register}
                errors={errors}
                fieldName="destinations_footer_text"
                inputClasses="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 py-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                label="Footer link title for hot destinations"
                placeHolder="Footer link title for hot destinations"
                defaultValue={defSettings?.destinations_footer_text}
              />
              <p className="mb-4 mt-0 text-[13px] text-gray-500 dark:text-gray-300">
                Available variables are {`{{depart_destination}}`},{" "}
                {`{{arrival_destination}}`}
              </p>
            </div>
            <div>
              <InputField
                register={register}
                errors={errors}
                fieldName="destination_blogs_heading"
                inputClasses="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 py-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                label="Destination Page Blogs Heading"
                placeHolder="Destination Page Blogs Heading"
                defaultValue={defSettings?.destination_blogs_heading}
              />
            </div>
            <div>
              <InputField
                register={register}
                errors={errors}
                fieldName="destination_blogs_sub_heading"
                inputClasses="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 py-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                label="Destination Page Blogs Sub Heading"
                placeHolder="Destination Page Blogs Sub Heading"
                defaultValue={defSettings?.destination_blogs_sub_heading}
              />
            </div>
            <div>
              <InputField
                register={register}
                errors={errors}
                fieldName="destination_tips_heading"
                inputClasses="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 py-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                label="Destination Page Tips Heading"
                placeHolder="Destination Page Tips Heading"
                defaultValue={defSettings?.destination_tips_heading}
              />
            </div>
            <div>
              <InputField
                register={register}
                errors={errors}
                fieldName="destination_tips_sub_heading"
                inputClasses="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 py-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                label="Destination Page Tips Sub Heading"
                placeHolder="Destination Page Tips Sub Heading"
                defaultValue={defSettings?.destination_tips_sub_heading}
              />
            </div>
            <div>
              <InputField
                register={register}
                errors={errors}
                fieldName="destination_contact_heading"
                inputClasses="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 py-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                label="Destination Page Contact Heading"
                placeHolder="Destination Page Contact Heading"
                defaultValue={defSettings?.destination_contact_heading}
              />
            </div>
            <div>
              <InputField
                register={register}
                errors={errors}
                fieldName="destination_contact_sub_heading"
                inputClasses="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 py-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                label="Destination Page Contact Sub Heading"
                placeHolder="Destination Page Contact Sub Heading"
                defaultValue={defSettings?.destination_contact_sub_heading}
              />
            </div>
          </div>
          <div className="bottom-0 left-0 flex justify-center w-full pb-4 mt-4 space-x-4 sm:px-4 sm:mt-0">
            <button
              type="submit"
              className="w-full justify-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Update
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default TestimonialSettings;
