import { gql } from "@apollo/client";

export const GET_DEFAULT_SETTINGS = gql`
  query GetDefaultSettings($id: uuid) {
    brd_default_setting(where: { brd_id: { _eq: $id } }) {
      brd_id
      id
      blogs_heading
      blogs_sub_heading
      created_at
      updated_at
    }
  }
`;
