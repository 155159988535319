import React, { useState } from "react";
import { useApolloClient, useMutation } from "@apollo/client";
import InputField from "components/FormInputs/InputField";
import { toast } from "react-toastify";
import travelHouseStore from "store/travelHouse/travelHouseStore";
import { useForm } from "react-hook-form";
import {
  UPDATE_BRAND,
  UPDATE_BRAND_DETAIL,
} from "~/modules/BrandModule/graphql/Mutation";
import { useFileUpload } from "@nhost/react";
import Card from "~/components/common/Card";
import { ALLOWED_IMAGE_FORMATS } from "~/config/constants";

export default function BrandEdit({ setEdit, data }: any) {
  const [updateBrand] = useMutation(UPDATE_BRAND);
  const [updateBrandDetail] = useMutation(UPDATE_BRAND_DETAIL);
  const { upload: uploadLogo, progress: logoUploadProgress } = useFileUpload();
  const [logoFile, setLogoFile] = useState<any | null>(null);
  const [footerLogo, setFooterLogo] = useState<any | null>(null);
  const [favIcon, setFavIcon] = useState<any | null>(null);
  const { travelHouse }: any = travelHouseStore();
  const client = useApolloClient();
  const [showDomainInput, setShowDomainInput] = useState(false);
  const {
    formState: { errors },
    register,
    handleSubmit,
    reset,
  } = useForm();

  const handleLogoUpload = async (event: any) => {
    const file = event.target.files[0];

    if (
      !ALLOWED_IMAGE_FORMATS.includes(file.type.split("/").at(1).toLowerCase())
    ) {
      toast.error("Invalid File format");
    } else {
      console.log("etc file selected");
      setLogoFile({ file });
    }
  };
  const handleFooterLogo = async (event: any) => {
    const file = event.target.files[0];
    if (
      !ALLOWED_IMAGE_FORMATS.includes(file.type.split("/").at(1).toLowerCase())
    ) {
      toast.error("Invalid File format");
    } else {
      console.log("etc file selected");
      setFooterLogo({ file });
    }
  };
  const handleFavIcon = async (event: any) => {
    const file = event.target.files[0];

    if (
      !ALLOWED_IMAGE_FORMATS.includes(file.type.split("/").at(1).toLowerCase())
    ) {
      toast.error("Invalid File format");
    } else {
      console.log("etc file selected");
      setFavIcon({ file });
    }
  };

  const onSubmit = async (formData: any) => {
    if (logoFile) {
      const logoUploadRes = await uploadLogo(logoFile);
      formData["logo"] = logoUploadRes?.id || null;
      console.log("logoUploadRes", logoUploadRes, formData);
    }
    if (footerLogo) {
      const logoUploadRes = await uploadLogo(footerLogo);
      formData["footer_logo"] = logoUploadRes?.id || null;
      console.log("logoUploadRes", logoUploadRes, formData);
    }
    if (favIcon) {
      const logoUploadRes = await uploadLogo(favIcon);
      formData["fav_icon"] = logoUploadRes?.id || null;
      console.log("logoUploadRes", logoUploadRes, formData);
    }

    try {
      const res = await updateBrand({
        variables: {
          id: {
            id: data?.id,
          },
          brdData: {
            name: formData?.name,
          },
        },
      });
      if (res.data) {
        const brandDetail: any = {
          primary_color: formData.primary_color,
          secondary_color: formData.secondary_color,
        };

        if (formData.logo) {
          brandDetail.logo = formData.logo;
        }
        if (formData.footer_logo) {
          brandDetail.footer_logo = formData.footer_logo;
        }
        if (formData.fav_icon) {
          brandDetail.fav_icon = formData.fav_icon;
        }
        const response = await updateBrandDetail({
          variables: {
            id: {
              id: data?.brd_details[0].id,
            },
            brdData: brandDetail,
          },
        });
        console.log("responseDetail", response);
        toast.success("Brand added successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        reset();
        await client.refetchQueries({
          include: "all",
        });
        setEdit(false);
      }
    } catch (e) {
      reset();
      console.error("error", e);
    }
  };

  return (
    <Card>
      <form onSubmit={handleSubmit(onSubmit)} className="w-full h-full">
        <h2 className="flex justify-center font-extrabold">Edit Brand</h2>

        <div className="flex flex-col justify-between h-[95%]">
          <div>
            <div className="mt-2 mb-2">
              <InputField
                register={register}
                errors={errors}
                fieldName="name"
                required={true}
                placeHolder="Branch Name"
                defaultValue={data?.name}
              />
            </div>

            {/* Logo Upload */}
            <div className="pt-4">
              <label>Brand Logo</label>
              <input
                type="file"
                onChange={handleLogoUpload}
                className="w-full px-2 py-2 border rounded focus:outline-none focus:border-green-500 leading-tight border-gray-300"
              />
              {logoUploadProgress && (
                <p className="font-thin">
                  Logo Upload Progress: {logoUploadProgress}%
                </p>
              )}
            </div>
            {/* Logo Upload */}
            <div className="pt-4">
              <label>Footer Logo</label>
              <input
                type="file"
                onChange={handleFooterLogo}
                className="w-full px-2 py-2 border rounded focus:outline-none focus:border-green-500 leading-tight border-gray-300"
              />
              {logoUploadProgress && (
                <p className="font-thin">
                  Footer Logo Upload Progress: {logoUploadProgress}%
                </p>
              )}
            </div>
            {/* Logo Upload */}
            <div className="pt-4">
              <label>Fav Icon</label>
              <input
                type="file"
                onChange={handleFavIcon}
                className="w-full px-2 py-2 border rounded focus:outline-none focus:border-green-500 leading-tight border-gray-300"
              />
              {logoUploadProgress && (
                <p className="font-thin">
                  Favicon Upload Progress: {logoUploadProgress}%
                </p>
              )}
            </div>

            {/* Primary Color */}
            <div className="pt-4">
              <label>Primary Color</label>
              <input
                type="color"
                {...register("primary_color")}
                className="w-full border focus:outline-none focus:border-green-500 leading-tight h-[30px]"
                defaultValue={data?.brd_details[0]?.primary_color}
              />
            </div>

            {/* Secondary Color */}
            <div className="pt-4">
              <label>Secondary Color</label>
              <input
                type="color"
                {...register("secondary_color")}
                className="w-full mb-[10px] border focus:outline-none focus:border-green-500 leading-tight h-[30px]"
                defaultValue={data?.brd_details[0]?.secondary_color}
              />
            </div>

            {showDomainInput && (
              <div className="mt-2 mb-2">
                <InputField
                  register={register}
                  errors={errors}
                  fieldName="domain"
                  placeHolder="Domain (Optional)"
                />
              </div>
            )}
            {/* <div className="mt-2 mb-2">
        <InputField
          register={register}
          errors={errors}
          fieldName="logo"
          placeHolder="Logo URL (Optional)"
        />
      </div> */}
          </div>

          <div className="grid grid-cols-2 gap-[10px]">
            <button className="bg-blue-500 text-white w-full rounded">
              Save
            </button>
            <button
              onClick={() => setEdit(false)}
              className="bg-red-500 text-white w-full rounded"
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    </Card>
  );
}
