import React from "react";

import { InquiresTabs } from "./data/InquiresTabs";
import SingleButton from "./components/SingleButton";
import { useUserDefaultRole } from "@nhost/react";

export default function InquiryFilterButtons({
  activeTab,
  setActiveTab,
  stats,
}: any) {
  const role = useUserDefaultRole();
  return (
    <div className="flex items-center w-full sm:justify-end">
      <div className="flex pl-2 space-x-1">
        {InquiresTabs.map((Tab: any) =>
          role === "consultant" && Tab.name === "All" ? (
            <></>
          ) : (
            <SingleButton
              name={Tab.name}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              no={
                Tab.name === "All"
                  ? stats.all
                  : Tab.name === "Inprocess"
                  ? stats.inprocess
                  : Tab.name === "Completed"
                  ? stats.completed
                  : Tab.name === "Assigned"
                  ? stats.assigned
                  : Tab.name === "Unassign"
                  ? stats.unAssign
                  : null
              }
              className={Tab.className}
            />
          )
        )}
      </div>
    </div>
  );
}
