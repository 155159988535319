import React from "react";
import { SimpleSelectProps } from "./types";

const SimpleSelect = ({
  register,
  errors,
  fieldName,
  defaultValue,
  label,
  classNames,
  options,
  required,
}: SimpleSelectProps) => {
  return (
    <div className={`w-full ${classNames?.container}`}>
      {label && <label className="mb-2">{label}</label>}
      <select
        {...register(fieldName, { required: required })}
        id=""
        className={`border p-[5px] w-full border-gray-200 rounded ${classNames?.input}`}
        defaultValue={defaultValue}
      >
        {options?.length > 0 &&
          options?.map((opt: any, i: number) => (
            <option key={i} value={opt.value}>
              {opt.title}
            </option>
          ))}
      </select>
      {errors[fieldName] && (
        <span
          role="alert"
          className={`text-red-500 m-1 text-[12px] normal-case`}
        >
          {errors[fieldName]?.message}
        </span>
      )}
    </div>
  );
};

export default SimpleSelect;
