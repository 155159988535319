export const getPaidAmount = (inquiry: any) => {
  let sum = 0;
  console.log("inquiry", inquiry);
  inquiry?.inq_transections.map((item: any) => {
    if (item?.status === "paid") {
      sum += item?.amount;
    }
  });
  return sum;
};

export const getSalePrice = (inquiry: any) => {
  return inquiry?.selected_suggestion?.inq_suggestion_costs.reduce(
    (sum: any, obj: any) => sum + parseFloat(obj.sale_price),
    0
  );
};
