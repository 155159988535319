import { useApolloClient, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useFileUpload, useUserDefaultRole } from "@nhost/react";
import { ADD_TRANSACTION, EDIT_INQUIRY } from "./graphql/Mutation";
import useNotifications from "hooks/useNotifications";
import { getPaidAmount } from "~/modules/InquiriesModule/utils/getInquiryStats";
import PurchaseDetail from "../../../TicketRequestForm/components/RequestForm/components/PurchaseDetail";
import moment from "moment";
import { generateRandom10DigitCode } from "~/utils/generateRandomId";

export default function EditTicket({ inquiry, setHideForm }: any) {
  const [isloading, setIsloading] = useState(false);
  const [status, setStatus] = useState(inquiry?.inq_tickets[0]?.status);
  const [editInquiry] = useMutation(EDIT_INQUIRY);

  const [addTransaction] = useMutation(ADD_TRANSACTION);
  // const [sendEmail] = useMutation(SEND_EMAIL);
  const [ticketPurchase, setTicketPurchase] = useState(true);
  const [selectedFile, setSelectedFile] = useState<any | null>(null);

  const client = useApolloClient();

  const { upload, progress } = useFileUpload();

  const {
    register,
    handleSubmit,
    formState: { errors },
  }: any = useForm();
  const role = useUserDefaultRole();

  const alert = useNotifications();

  const onSubmit = async (formData: any) => {
    setIsloading(true);
    let payload: any = {
      ibe: inquiry?.inq_tickets[0]?.ibe || null,
      ticket_cost_price: inquiry?.inq_tickets[0]?.ticket_cost_price || null,
      ticket_attol_price: inquiry?.inq_tickets[0]?.ticket_attol_price || null,
      ticket_mis_cost: inquiry?.inq_tickets[0]?.ticket_mis_cost || null,
      url: inquiry?.inq_tickets[0]?.url || null,
      status: inquiry?.inq_tickets[0]?.status,
    };

    if (status === "pending_approval") {
      if (formData["ibe_no"]) {
        payload["ibe"] = formData["ibe_no"];
        payload["ticket_cost_price"] = formData["ticket_cost_price"];
        payload["ticket_attol_price"] = formData["ticket_attol_price"];
        payload["ticket_mis_cost"] = formData["ticket_mis_cost"];
        payload["status"] = "awaiting_vendor";

        let ticket_attol_price = formData["ticket_attol_price"];
        let ticket_mis_cost = formData["ticket_mis_cost"];
        let ticket_cost_price = formData["ticket_cost_price"];
        const tenDigitCode: any = generateRandom10DigitCode();
        if (ticket_attol_price) {
          ticket_attol_price = parseFloat(ticket_attol_price);
        } else {
          ticket_attol_price = 0;
        }
        if (ticket_mis_cost) {
          ticket_mis_cost = parseFloat(ticket_mis_cost);
        } else {
          ticket_mis_cost = 0;
        }

        const rawTransactions: any = [
          {
            brd_id: inquiry.brd_id,
            def_acc_id: 6,
            inq_id: inquiry.id,
            transactions_no: tenDigitCode,
            date: moment(new Date()).format("YYYY-MM-DD"),
            type: "debit",
            amount: parseFloat(ticket_cost_price),
          },
          {
            brd_id: inquiry.brd_id,
            def_acc_id: 7,
            inq_id: inquiry.id,
            transactions_no: tenDigitCode,
            date: moment(new Date()).format("YYYY-MM-DD"),
            type: "debit",
            amount: parseFloat(ticket_attol_price),
          },
          {
            brd_id: inquiry.brd_id,
            def_acc_id: 8,
            inq_id: inquiry.id,
            transactions_no: tenDigitCode,
            date: moment(new Date()).format("YYYY-MM-DD"),
            type: "debit",
            amount: parseFloat(ticket_mis_cost),
          },
          {
            brd_id: inquiry.brd_id,
            def_acc_id: 2,
            user_id: formData["vendor_id"],
            inq_id: inquiry.id,
            transactions_no: tenDigitCode,
            date: moment(new Date()).format("YYYY-MM-DD"),
            type: "credit",
            amount: -(
              parseFloat(ticket_cost_price) +
              parseFloat(ticket_attol_price) +
              parseFloat(ticket_mis_cost)
            ),
          },
        ];
        const transactions = rawTransactions.filter(
          (obj: any) => obj.amount !== 0
        );

        console.log("formData", transactions);
        try {
          const res = await addTransaction({
            variables: {
              transactions: transactions,
            },
          });
          toast.success("Transaction added", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          await client.resetStore();
        } catch (e) {
          console.error("error", e);
        }
      } else {
        payload["status"] = "pending_approval";
      }
    } else if (status === "issued") {
      if (!selectedFile) {
        setIsloading(false);
        console.log("selectedFile", selectedFile);
        toast.error("File not selected");
      }
      const res = await upload(selectedFile);
      payload["status"] = "issued";
      payload["url"] = res.id;
    } else {
      payload["status"] = status;
    }

    try {
      //Edit Inquiry Function function call
      const res = await editInquiry({
        variables: { id: inquiry?.inq_tickets[0].id, inquiry: payload },
      });
      if (res.data?.update_inq_ticket?.returning?.length > 0) {
        toast.success("Ticket updated successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        console.log("status", payload["status"]);
        // if (payload["status"] === "issued") {
        //   const variables = {
        //     from: {
        //       th_name: inquiry?.brd_list?.name,
        //     },
        //     to: {
        //       email: inquiry?.users?.email,
        //     },
        //     variables: {},
        //   };
        //   const { emails, body, subject } =
        //     CustomerTemplates.sendTicketReadyNotification(variables);
        //   const emailResp = await sendEmail({
        //     variables: {
        //       emails: emails,
        //       body: body,
        //       subject,
        //     },
        //   });
        //   console.log("emailResp", emailResp);
        // }

        if (role === "consultant") {
          await alert.newNotification(
            "admin",
            `Early ticket issuance request is in the process by Admin`,
            "",
            ``
          );
        } else {
          await alert.newNotification(
            inquiry?.picked_by,
            `Early ticket issuance request is in the process by Admin`,
            "",
            ``
          );
        }

        await client.resetStore();
      }
      setIsloading(false);
      setHideForm(false); // hide form modal
    } catch (e) {
      setIsloading(false);
      setHideForm(false);
      toast.error("File not selected");
      console.error("error", e);
    }
  };

  const handleFileUpload = async (event: any) => {
    const file = event.target.files[0];
    if (!file.type.includes("pdf")) {
      toast.error("Invalid File format");
    } else {
      console.log("etc file selected", { file });
      setSelectedFile({ file });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="pt-4 w-[400px]">
      <>
        {inquiry?.inq_tickets[0]?.status === "pending_approval" ? (
          <PurchaseDetail
            ticketPurchase={ticketPurchase}
            setTicketPurchase={setTicketPurchase}
            register={register}
            errors={errors}
          />
        ) : (
          <>
            <label>Status {"*"}</label>
            <select
              onChange={(e: any) => setStatus(e.target.value)}
              className="border border-slate-300 py-[5px] h-[44px] px-[15px] text-[14px] rounded-[5px] w-full"
              id=""
              defaultValue={inquiry?.inq_tickets[0]?.status}
            >
              <option value="awaiting_vendor">Awaiting Vendor</option>
              <option value="queued_pending">Queued Pending</option>
              <option value="queued">Queued</option>
              <option value="issued">Issued</option>
            </select>
            {status === "issued" && (
              <>
                <div className="pt-4">
                  <label>Ticket File {"*"}</label>
                  <input
                    id="fileUpload"
                    type="file"
                    onChange={handleFileUpload}
                    className="w-full px-2 py-2 border rounded focus:outline-none focus:border-green-500 leading-tight border-gray-300"
                  />
                  {progress && <p>Upload Progress: {progress}%</p>}

                  <small className="text-[#b63434]">
                    Only PDF file is acceptable.
                  </small>
                </div>
              </>
            )}
          </>
        )}
        <button
          type="submit"
          className={`text-white px-5 py-2 rounded hover:cursor-pointer bg-teal-700 transition-all mt-[20px]`}
        >
          {isloading ? "Updating ..." : "Update"}
        </button>
      </>
    </form>
  );
}
