import React, { useState } from "react";
import { toast } from "react-toastify";
// Import Components
import RingSVG1 from "../../assets/svg/RingSVG1";
import { useSearchParams } from "react-router-dom";
import {
  useProviderLink,
  useSignInEmailPassword,
  useSignInEmailPasswordless,
} from "@nhost/react";
import { useApolloClient } from "@apollo/client";
import { GET_USER } from "./graphql/Query";
import { getCurrentUrl } from "~/utils/getCurrentUrl";

export default function LoginPage() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [searchParams] = useSearchParams();
  const { apple } = useProviderLink();

  const client = useApolloClient();
  const { signInEmailPasswordless } = useSignInEmailPasswordless();
  const { signInEmailPassword } = useSignInEmailPassword();
  // Display Error Toast
  if (searchParams.get("error")) {
    toast.error(
      `${searchParams.get("error")?.replace("-", " ")}: ${searchParams.get(
        "errorDescription"
      )}`
    );
    searchParams.delete("error");
    searchParams.delete("errorDescription");
  }

  let customUrl = "abc-portalabc.travelhouseportal.com";
  // Submit form function
  const onSubmit = async (e: any) => {
    e.preventDefault(); // prevent
    setLoading(true); // set loading state true
    const { data, error } = await client.query({
      query: GET_USER,
      variables: { email, custom_url: customUrl },
    });

    if (data?.thp_list?.length > 0) {
      const res: any = await signInEmailPassword(email, password);
      if (res.isSuccess) {
        toast.success("Successfully Logged In");
      } else if (res.isError) {
        toast.error(res?.error?.message);
      } else {
        toast.error("Something went wrong");
      }
    } else {
      toast.error("No user found");
    }
    if (error) {
      toast.error(`Error in the query ${error.message}`);
    }
    setLoading(false);
  };

  return (
    <div className="w-full relative flex justify-center items-center h-[100vh]">
      <div className="max-w-[400px] m-[25px] w-full  flex flex-col gap-[32px]">
        <h4 className="text-black text-center text-[28px] m-0 font-bold">
          Login
        </h4>
        <form className="flex flex-col gap-[24px]" onSubmit={onSubmit}>
          <div className="flex flex-col gap-[12px]">
            <label
              className="text-[16px] text-[#172B4D] m-0 leading-[normal]"
              htmlFor=""
            >
              Email
            </label>
            <input
              className="w-full rounded-[8px] border border-gray-300 px-[16px] text-black py-[12px] m-0"
              type="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email Address"
              autoComplete="true"
            />
          </div>
          <div className="flex flex-col gap-[12px]">
            <label
              className="text-[16px] text-[#172B4D] m-0 leading-[normal]"
              htmlFor=""
            >
              Password
            </label>
            <input
              className="w-full rounded-[8px] border border-gray-300 px-[16px] text-black py-[12px] m-0"
              type="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              autoComplete="true"
            />
          </div>

          <button
            className="mt-[10px] w-full bg-primary-gradient rounded-[8px] h-[52px] text-white text-[16px]"
            type="submit"
          >
            {loading ? "Loging..." : "Login"}
          </button>
        </form>
      </div>
    </div>
  );
}
