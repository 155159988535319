import { useState } from "react";
import PaymentMethodEdit from "./components/PaymentMethodEdit";
import PaymentMethodBox from "./components/PaymentMethodBox";

const PaymentMethodCard = ({
  payment_method,
  className,
  payment_methods,
}: any) => {
  const [edit, setEdit] = useState(false);

  return (
    <>
      <div
        className={`relative bg-white-100 rounded-8xs border-solid mt-[10px] flex flex-col items-start justify-start text-left text-xs text-darkslategray ${className}`}
      >
        {!edit && (
          <PaymentMethodBox payment_method={payment_method} setEdit={setEdit} />
        )}
        {edit && (
          <PaymentMethodEdit
            payment_methods={payment_methods}
            payment_method={payment_method}
            setEdit={setEdit}
          />
        )}
      </div>
    </>
  );
};

PaymentMethodCard.defaultProps = {
  className: "",
};
export default PaymentMethodCard;
