import React, { useEffect, useState } from "react";
import base64 from "base64-js";
import SendEmailForm from "./SendEmailForm";
import Modal from "./Modal";
import { getTimeReadableFormat } from "~/utils/GetTimeReadable";
import moment from "moment";
import { useUserData } from "@nhost/react";
import { useForm } from "react-hook-form";
import { AnyARecord } from "dns";
import { VscSearch } from "react-icons/vsc";

const ShowEmailsNew = ({
  aliases,
  emails,
  loadMoreEmails,
  loadPreviousEmails,
  isPreviousDisabled,
  resetSendLoading,
  handleSendEmail,
  nextPageToken,
  isLoading,
  handleSearchEmail,
}: any) => {
  console.log("ShowEmailsNew", emails);
  const user: any = useUserData();
  const { register, handleSubmit, reset } = useForm();
  const [selectedEmail, setSelectedEmail] = useState<any>(null);
  const [selectedAlias, setSelectedAlias] = useState<any>(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [compose, setCompose] = useState(false);
  const [reply, setReply] = useState(false);
  const handleEmailClick = (email: any) => {
    setSelectedAlias(
      extractEmailAddress(
        email?.payload?.headers.find((header: any) => header.name === "To")
          .value
      )
    );
    setSelectedEmail(email);
    setModalOpen(true);
  };

  const closeDrawer = () => {
    setModalOpen(false);
    setReply(false);
    setCompose(false);
  };

  useEffect(() => {
    if (user.defaultRole !== "admin" && user?.metadata?.alias) {
      console.log("i am working", user);
    }
  }, [user]);

  function extractEmailAddress(inputString: any) {
    const emailRegex = /[\w.-]+@[\w.-]+\.\w+/;
    const emailMatch = inputString.match(emailRegex);
    return emailMatch ? emailMatch[0] : "";
  }

  const allMessages: any = [];
  // emails?.getGmailMessages?.emails.forEach((email: any) => {
  const messagesWithAlias = emails?.getGmailMessages?.emails?.messages.map(
    (message: any) => ({
      ...message,
      alias: "",
    })
  );
  allMessages.push(...messagesWithAlias);
  // });
  // Sort the email messages based on the internalDate in descending order
  const sortedMessages = allMessages.sort(
    (a: any, b: any) => parseInt(b.internalDate) - parseInt(a.internalDate)
  );
  console.log("selectedEmail", selectedEmail);
  function truncateString(str: any, num: any) {
    if (str.length > num) {
      return str.slice(0, num) + "...";
    } else {
      return str;
    }
  }

  function extractEmailContent(payload: any, snippet: string): string {
    // If the payload itself has a body and data, return it based on its MIME type.
    if (payload.body && payload.body.data) {
      if (payload.mimeType === "text/html") {
        return decodeBase64(payload.body.data);
      }
      if (payload.mimeType === "text/plain") {
        return decodeBase64(payload.body.data);
      }
    }

    // If the payload has parts, recurse into each part.
    if (payload.parts) {
      let plainTextContent: string | null = null;

      for (const part of payload.parts) {
        const content = extractEmailContent(part, snippet);

        // If the content is HTML, return it immediately.
        if (part.mimeType === "text/html" && content) {
          return content;
        }

        // If the content is plain text, store it and continue searching for an HTML version.
        if (part.mimeType === "text/plain" && content) {
          plainTextContent = content;
        }
      }

      // If no HTML content was found but plain text was, return the plain text content.
      if (plainTextContent) {
        return plainTextContent;
      }
    }

    // If neither HTML nor plain text content was found, return the snippet.
    return snippet;
  }

  const decodeBase64 = (data: string) => {
    if (!data) {
      console.error("Data to decode is null or undefined");
      return "";
    }
    const bytes = base64.toByteArray(data);
    return new TextDecoder().decode(bytes);
  };

  const onSubmit = (data: any) => {
    // Handle form submission here
    console.log("searchFormData", data);
    handleSearchEmail(data);
    // reset();
  };

  return (
    <>
      <div className="flex mb-2">
        <form className="w-full mr-[10px]" onSubmit={handleSubmit(onSubmit)}>
          <div className="relative">
            <input
              type="text"
              {...register("search")}
              id="search"
              required
              className="bg-gray-50 mr-[10px] border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 pr-10" // Add right padding to accommodate the buttons and checkboxes
              placeholder="Search"
            />
            {/* <input
              type="checkbox"
              {...register("subject")}
              placeholder="Subject"
              className="absolute right-[6.25rem] top-[0.9rem]" // Adjust the position as needed
            />
            <input
              type="checkbox"
              placeholder="Email"
              {...register("emailCheckbox")}
              className="absolute right-[4.25rem] top-[0.9rem]" // Adjust the position as needed
            /> */}
            <button
              className="absolute right-[0.8rem] top-[0.9rem] cursor-pointer" // Adjust the position as needed
            >
              <VscSearch className="" size="18px" />
            </button>
          </div>
        </form>
        <button
          onClick={() => {
            setReply(true);
            setModalOpen(true);
            setCompose(true);
            setSelectedAlias(null);
            setSelectedEmail(null);
          }}
          className="ml-auto text-white bg-[#135737] hover:bg-[#256a4a] focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-[#256a4a] dark:hover:bg-[#135737] focus:outline-none dark:focus:ring-blue-800"
        >
          Compose
        </button>
      </div>
      <div className="bg-gray-50 dark:bg-gray-800">
        {isModalOpen && (
          <div
            onClick={closeDrawer}
            className="fixed inset-0 z-30 bg-black bg-opacity-50"
          ></div>
        )}
        <div
          id="main-content"
          className="flex flex-col justify-center items-center relative w-full h-full overflow-y-auto "
        >
          <div className="flex flex-col w-full">
            <div className="overflow-x-auto">
              <div className="inline-block min-w-full align-middle">
                <div className="overflow-hidden shadow">
                  <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                      <tbody>
                        {sortedMessages.length === 0 && (
                          <p className="text-center">Inbox is empty</p>
                        )}
                        {sortedMessages.map((email: any) => {
                          console.log("emailSingle", email);
                          return (
                            <tr
                              onClick={() => handleEmailClick(email)}
                              className="bg-white border-b cursor-pointer dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                            >
                              <th
                                scope="row"
                                className="max-w-[250px]  flex items-center px-6 py-4 text-gray-900 whitespace-nowrap dark:text-white"
                              >
                                {/* You can change this avatar to the actual sender's avatar once you have it */}
                                <img
                                  className="w-[20px] h-[20px] rounded-full"
                                  src="https://www.gravatar.com/avatar/3449c9e5e332f1dbb81505cd739fbf3f?d=identicon"
                                  alt="Sender"
                                />

                                <div className="pl-3">
                                  {(() => {
                                    const fromValue =
                                      email.payload.headers.find(
                                        (header: any) => header.name === "From"
                                      ).value;

                                    // Split the fromValue into name and email using regex
                                    const match =
                                      fromValue.match(/^(.*?)\s*<([^>]+)>$/);

                                    if (match && match.length === 3) {
                                      return (
                                        <>
                                          <div className="text-[14px] font-semibold">
                                            {truncateString(match[1], 30)}
                                          </div>
                                        </>
                                      );
                                    } else {
                                      // In case the regex doesn't match, fallback to the original value
                                      return (
                                        <>
                                          <div className="text-[14px] font-semibold">
                                            {truncateString(fromValue, 30)}
                                          </div>
                                        </>
                                      );
                                    }
                                  })()}
                                  {/* <div className="font-normal text-gray-500">
                                    {email.alias}
                                  </div> */}
                                </div>
                              </th>
                              <td className=" text-[14px] overflow-hidden  font-medium text-gray-900 whitespace-nowrap dark:text-white truncate xl:max-w-2xl dark:text-gray-400">
                                {truncateString(email.snippet, 70)}
                              </td>
                              <td className="max-w-[250px] p-4 text-right text-[14px] text-gray-500 whitespace-nowrap dark:text-white">
                                {getTimeReadableFormat(
                                  moment
                                    .unix(email.internalDate / 1000)
                                    .format("YYYY-MM-DDTHH:mm:ss.SSSSSSZZ")
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {nextPageToken && (
            <button
              className="w-[200px] m-4 items-center flex-1 px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-[#135737] hover:bg-[#256a4a] focus:ring-4 focus:ring-blue-300 dark:bg-[#256a4a] dark:hover:bg-[#135737] dark:focus:ring-blue-800"
              onClick={loadMoreEmails}
            >
              {isLoading ? "Loading..." : <> Load More</>}
            </button>
          )}

          <div
            id="drawer-email-setting-default"
            className={`fixed top-0 right-0 z-40 w-full h-screen max-w-md p-4 overflow-y-auto transition-transform bg-white dark:bg-gray-800 ${
              isModalOpen ? "translate-x-0" : "translate-x-full"
            }`}
            aria-labelledby="drawer-label"
            aria-hidden="true"
          >
            <h5
              id="drawer-label"
              className="inline-flex items-center mb-6 text-sm font-semibold text-gray-500 uppercase dark:text-gray-400"
            >
              Email Details
            </h5>

            <Modal
              isOpen={isModalOpen}
              onClose={() => {
                setModalOpen(false);
                setReply(false);
                setCompose(false);
              }}
            >
              {reply ? (
                <SendEmailForm
                  to={
                    selectedEmail != null
                      ? extractEmailAddress(
                          selectedEmail?.payload?.headers.find(
                            (header: any) => header.name === "From"
                          ).value
                        )
                      : ""
                  }
                  subject={
                    selectedEmail != null
                      ? selectedEmail?.payload?.headers?.find(
                          (header: any) => header.name === "Subject"
                        ).value
                      : ""
                  }
                  handleSendEmail={handleSendEmail}
                  resetSendLoading={resetSendLoading}
                  setModalOpen={setModalOpen}
                  from={selectedEmail != null ? selectedAlias : aliases}
                  setCompose={setCompose}
                  compose={compose}
                  messageId={selectedEmail != null ? selectedEmail?.id : null}
                  setReply={setReply}
                />
              ) : (
                <>
                  {selectedEmail && (
                    <div>
                      <div>
                        <label className="block mb-2 text-sm font-sm mt-2 text-gray-900 dark:text-white">
                          From
                        </label>
                        <p className="bg-gray-50 p-2.5 rounded-lg">
                          {
                            selectedEmail.payload.headers.find(
                              (header: any) => header.name === "From"
                            ).value
                          }
                        </p>
                      </div>
                      <div>
                        <label className="block mb-2 text-sm font-sm mt-2 text-gray-900 dark:text-white">
                          To
                        </label>
                        <p className="bg-gray-50 p-2.5 rounded-lg">
                          {
                            selectedEmail.payload.headers.find(
                              (header: any) => header.name === "To"
                            ).value
                          }
                        </p>
                      </div>
                      <div>
                        <label className="block mb-2 text-sm font-sm mt-2 text-gray-900 dark:text-white">
                          Subject
                        </label>
                        <p className="bg-gray-50 p-2.5 rounded-lg">
                          {
                            selectedEmail.payload.headers.find(
                              (header: any) => header.name === "Subject"
                            ).value
                          }
                        </p>
                      </div>

                      <div>
                        <label className="block mb-2 text-sm font-sm mt-2 text-gray-900 dark:text-white">
                          Content
                        </label>
                        <p className="bg-gray-50 p-2.5 rounded-lg">
                          <iframe
                            className="w-full rounded-lg h-72"
                            srcDoc={extractEmailContent(
                              selectedEmail.payload,
                              selectedEmail.snippet
                            )}
                            title="Email content"
                          />
                        </p>
                      </div>

                      <div className="flex mt-4">
                        <button
                          onClick={() => setReply(true)}
                          className="ml-auto text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                        >
                          Reply
                        </button>
                      </div>
                    </div>
                  )}
                </>
              )}
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShowEmailsNew;
