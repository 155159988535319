import React from "react";
import { useUserDefaultRole } from "@nhost/react";
import SideBar from "./sidebar/SideBar";
import sideMenuList from "../../data/side-menu";
import TopMenuNew from "./header/TopMenuNew";

interface LayoutProps {
  children: JSX.Element;
}
const Layout: React.FC<LayoutProps> = ({ children }) => {
  const userDefaultRole = useUserDefaultRole();
  // Get Menu based on user role
  const menu = sideMenuList.filter(
    (menu: any) => menu?.role === userDefaultRole
  );
  return (
    <div className="flex h-screen bg-gray-100 overflow-hidden">
      {/* <SideBar menu={menu[0].menuItems} /> */}
      <div className="flex-1 flex flex-col overflow-y-auto bg-gray-50 dark:bg-gray-900 relative">
        <TopMenuNew />
        <div className="relative p-[10px] lg:p-[20px]  w-full  max-w-[1600px] mx-auto">
          {children}
        </div>
        {/* <GradientBlob /> */}
      </div>
    </div>
  );
};

export default Layout;
