import React, { useEffect, useRef } from "react";
import { GOOGLE } from "../../../config/enums";
import { useParams } from "react-router-dom";

declare global {
  interface Window {
    google: any;
  }
}

interface AuthenticateProps {
  onCodeReceived: (code: string) => void;
}

const Authenticate: React.FC<AuthenticateProps> = ({ onCodeReceived }) => {
  const { code } = useParams();

  const getAuthCode = () => {
    const currentURL = window.location.href; // This will include the entire URL including path
    console.log("currentURL", encodeURIComponent(currentURL));
    const serverlessFunctionURL =
      "https://xtvnyxqrjgccgtjyvcsf.functions.eu-central-1.nhost.run/v1/gmail/authenticate";
    window.location.href = `${serverlessFunctionURL}?origin=${encodeURIComponent(
      currentURL
    )}`;
  };

  useEffect(() => {
    if (code) {
      const decodedCode = atob(code);
      console.log("decodedCode", decodedCode);
      onCodeReceived(decodedCode);
    } else {
      console.error("Code is undefined");
    }
  }, [code]);

  return (
    <button className="border border-black p-3" onClick={getAuthCode}>
      Authenticate with Google
    </button>
  );
};

export default Authenticate;
