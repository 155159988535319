import { gql } from "@apollo/client";

export const GET_BRANDS_WITH_BRD = gql`
  query GetUserBrand($brands: [uuid!]!) {
    brd_list(where: { id: { _in: $brands } }) {
      id
      name
    }
  }
`;
export const GET_BRANDS_WITH_THP = gql`
  query GetUserBrand($thp_id: uuid!) {
    brd_list(where: { thp_id: { _eq: $thp_id } }) {
      id
      name
    }
  }
`;

export const GET_ALIASES = gql`
  query GetEmails(
    $refresh_token: String
    $code: String
    $th_id: String
    $page: String
    $pageSize: String
    $nextPageToken: String
    $sendEmail: Boolean
    $to: String
    $body: String
    $subject: String
    $historyId: String
    $getNewEmailsFlag: Boolean
    $aliasDisplayName: String
    $aliasEmail: String
    $from: String
    $createAlias: Boolean
    $getAlias: Boolean
    $alias: Boolean
    $replyFlag: Boolean
    $messageId: String
    $query: Boolean
    $queryData: String
  ) {
    getGmailMessages(
      arg1: {
        refresh_token: $refresh_token
        code: $code
        th_id: $th_id
        page: $page
        pageSize: $pageSize
        nextPageToken: $nextPageToken
        sendEmail: $sendEmail
        to: $to
        body: $body
        subject: $subject
        historyId: $historyId
        getNewEmailsFlag: $getNewEmailsFlag
        aliasDisplayName: $aliasDisplayName
        aliasEmail: $aliasEmail
        createAlias: $createAlias
        getAlias: $getAlias
        from: $from
        alias: $alias
        replyFlag: $replyFlag
        messageId: $messageId
        query: $query
        queryData: $queryData
      }
    ) {
      status
      nextPageToken
      aliases {
        sendAsEmail
      }
    }
  }
`;
