import React, { useState } from "react";
import InvoiceCard from "./components/InvoiceCard";
import PaymentCard from "./components/PaymentCard";
import PaymentAdd from "./components/PaymentAdd";
import { toast } from "react-toastify";
import { ADD_INVOICE, EDIT_INQUIRY } from "./graphql/Mutation";
import { useMutation } from "@apollo/client";
import { useUserDefaultRole } from "@nhost/react";
import PaymentRequest from "./components/PaymentRequest";

export default function PaymentsCard({ inquiry }: any) {
  const role = useUserDefaultRole();
  const [hideForm, setHideForm] = useState(false);
  const [requestForm, setRequestForm] = useState(false);

  const [addInvoice] = useMutation(ADD_INVOICE);
  const [edit] = useMutation(EDIT_INQUIRY);

  function generateRandomNumber() {
    return Math.floor(Math.random() * 1000000); // Adjust the range as needed
  }

  return (
    <div className="self-stretch flex flex-col items-start justify-start gap-[30px] mb-5">
      <div className="self-stretch flex  pt-0 px-0 pb-[5px] items-start justify-between border-b-[1px] border-solid border-lightsteelblue">
        <b className="self-stretch  relative leading-[20px]">{`Payments`}</b>

        {inquiry.status !== "completed" && (
          <div className="flex gap-2">
            <div className="flex justify-end gap-3 w-full">
              <button
                onClick={() => setHideForm(true)}
                className="block border border-primary  w-[160px]  rounded-full px-3 py-1 text-primary cursor-pointer hover:bg-primary hover:text-white"
              >
                Add Payment
              </button>
            </div>
            {role !== "user" && (
              <div className="flex justify-end gap-3 w-full">
                <button
                  onClick={() => setRequestForm(true)}
                  className="block border border-primary w-[160px] rounded-full px-3 py-1 text-primary cursor-pointer hover:bg-primary hover:text-white"
                >
                  Pay Request
                </button>
              </div>
            )}
          </div>
        )}
      </div>

      <div className="self-stretch grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-4  items-start justify-start gap-[30px] text-mini">
        {inquiry?.inq_transections.map((transaction: any, i: any) => (
          <PaymentCard key={i} inquiry={inquiry} transaction={transaction} />
        ))}

        {hideForm && (
          <PaymentAdd
            setHideForm={setHideForm}
            inq={inquiry}
            payment_method_id={inquiry.payment_method_id}
            user={inquiry.users}
            inquiry_id={inquiry.id}
            brd_domain={inquiry.brd_list.domain}
            brd_id={inquiry.brd_id}
            picked_by={inquiry.picked_by}
          />
        )}

        {requestForm && (
          <PaymentRequest
            setHideForm={setRequestForm}
            inq={inquiry}
            payment_method_id={inquiry.payment_method_id}
            user={inquiry.users}
            inquiry_id={inquiry.id}
            brd_domain={inquiry.brd_list.domain}
            brd_id={inquiry.brd_id}
            picked_by={inquiry.picked_by}
          />
        )}
      </div>
    </div>
  );
}
