import React, { useEffect, useState } from "react";
import { AddressBoxProps } from "./types";
import { useClickOutside } from "@mantine/hooks";

const AddressBox = ({
  register,
  errors,
  setValue,
  fieldName,
  defaultValue,
  required,
  containerClasses,
  labelClasses,
  inputClasses,
  errorClasses,
  labels,
  countryCode,
}: AddressBoxProps) => {
  const [address, setAddress] = useState({});
  const [addressList, setAddressList] = useState([]);
  const [query, setQuery] = useState("");
  const ref = useClickOutside(() => setQuery(""));

  const searchPlace = async (place: string) => {
    const data = await fetch(
      `https://api.geoapify.com/v1/geocode/autocomplete?text=${place}&apiKey=e25595083ac7414da2f5612fbbddbef8${
        countryCode ? `&filter=countrycode:${countryCode.toLowerCase()}` : ""
      }`
    );
    const res = await data.json();
    if (!res.error) {
      setAddressList(res?.features);
    }
  };

  useEffect(() => {
    if (defaultValue) {
      setValue(`${fieldName}.addressLine1`, defaultValue?.addressLine1);
      setValue(`${fieldName}.addressLine2`, defaultValue?.addressLine2);
      setValue(`${fieldName}.city`, defaultValue?.city);
      setValue(
        `${fieldName}.region`,
        defaultValue?.state || defaultValue?.county || defaultValue?.region
      );
      setValue(`${fieldName}.postalCode`, defaultValue?.postalCode);
      // setValue(`${fieldName}.country`, defaultValue?.country)
    }
  }, []);

  useEffect(() => {
    if (query !== "") {
      searchPlace(query);
    }
  }, [query]);

  const handleAddress = (addr: any) => {
    setValue(`${fieldName}.addressLine1`, addr?.properties?.address_line1);
    setValue(`${fieldName}.addressLine2`, addr?.properties?.address_line2);
    setValue(`${fieldName}.city`, addr?.properties?.city);
    setValue(
      `${fieldName}.region`,
      addr?.properties?.state ||
        addr?.properties?.county ||
        addr?.properties?.region
    );
    setValue(`${fieldName}.postalCode`, addr?.properties?.postcode);
    // setValue(`${fieldName}.country`, addr?.properties?.country)
    setQuery("");
    setAddressList([]);
  };

  const renderSearchOptions = () => {
    return (
      <div
        ref={ref}
        className="w-full absolute bg-[#fff] rounded z-10 border border-gray-300 shadow-lg transition-all"
      >
        {addressList.length === 0 ? (
          <p className="text-center text-[14px] py-2">Address not found</p>
        ) : (
          <>
            {addressList.map((item: any, i: number) => (
              <div
                onClick={() => handleAddress(item)}
                key={i}
                className="w-full py-[15px] px-[15px] hover:bg-gray-200 cursor-pointer transition-all"
              >
                <p>{item?.properties?.formatted}</p>
              </div>
            ))}
          </>
        )}
      </div>
    );
  };

  return (
    <div className={`w-full ${containerClasses}`}>
      <div className="mb-2">
        {labels && (
          <label htmlFor="" className={`block mb-1 ${labelClasses}`}>
            Address 1:
          </label>
        )}
        <div className="relative">
          <input
            {...register(`${fieldName}.addressLine1`, {
              onChange: (e: any) => setQuery(e.target.value),
              required: required || false,
            })}
            className={`border rounded py-1 px-2  w-full ${
              errors[fieldName]?.addressLine1
                ? `border-red-500`
                : `border-gray-300`
            } ${inputClasses}`}
            placeholder="Address Line 1"
            type="text"
          />
          {query !== "" && renderSearchOptions()}
        </div>
        {errors[fieldName]?.addressLine1 && (
          <p className={`text-red-500 text-[12px] mt-1 ml-1`}>
            {" "}
            <sup>*</sup> required
          </p>
        )}
      </div>
      <div className="mb-2">
        {labels && (
          <label htmlFor="" className={`block mb-1 ${labelClasses}`}>
            Address 2:
          </label>
        )}
        <input
          {...register(`${fieldName}.addressLine2`)}
          className={`border border-gray-300 rounded py-1 px-2  w-full ${inputClasses}`}
          placeholder="Address Line 2"
          type="text"
        />
      </div>
      <div className="@container">
        <div className="grid grid-cols-1 @md:grid-cols-2 @lg:grid-cols-3   gap-2">
          <div className="">
            {labels && (
              <label htmlFor="" className={`block mb-1 w-full ${labelClasses}`}>
                City:
              </label>
            )}
            <input
              {...register(`${fieldName}.city`, {
                required: required || false,
              })}
              className={`border rounded py-1 px-2  w-full ${
                errors[fieldName]?.city ? `border-red-500` : `border-gray-300`
              } ${inputClasses}`}
              placeholder="City"
              type="text"
            />
            {errors[fieldName]?.city && (
              <p className={`text-red-500 text-[12px] mt-1 ml-1`}>
                {" "}
                <sup>*</sup> required
              </p>
            )}
          </div>
          <div className="">
            {labels && (
              <label htmlFor="" className={`block mb-1 w-full ${labelClasses}`}>
                County/Region:
              </label>
            )}
            <input
              {...register(`${fieldName}.region`, {
                required: required || false,
              })}
              className={`border rounded py-1 px-2  w-full ${
                errors[fieldName]?.region ? `border-red-500` : `border-gray-300`
              } ${inputClasses}`}
              placeholder="County/Region"
              type="text"
            />
            {errors[fieldName]?.region && (
              <p className={`text-red-500 text-[12px] mt-1 ml-1`}>
                {" "}
                <sup>*</sup> required
              </p>
            )}
          </div>
          <div className="">
            {labels && (
              <label htmlFor="" className={`block mb-1 w-full ${labelClasses}`}>
                Postal Code:
              </label>
            )}
            <input
              {...register(`${fieldName}.postalCode`, {
                required: required || false,
              })}
              className={`border rounded py-1 px-2  w-full ${
                errors[fieldName]?.postalCode
                  ? `border-red-500`
                  : `border-gray-300`
              } ${inputClasses}`}
              placeholder="Postal Code"
              type="text"
            />
            {errors[fieldName]?.postalCode && (
              <p className={`text-red-500 text-[12px] mt-1 ml-1`}>
                {" "}
                <sup>*</sup> required
              </p>
            )}
          </div>
          {/* <div className="">
                {labels && (<label htmlFor="" className={`block mb-1 ${labelClasses}`}>Country:</label>)}
                <input {...register(`${fieldName}.country`, {required: required || false})} className={`border rounded py-1 px-2  w-full ${errors[fieldName]?.country ? `border-red-500` : `border-gray-300`   } ${inputClasses}`} placeholder='Country' type="text" />
                {errors[fieldName]?.country && <p className={`text-red-500 text-[12px] mt-1 ml-1`}> <sup>*</sup> required</p>}
            </div> */}
        </div>
      </div>
    </div>
  );
};

export default AddressBox;
