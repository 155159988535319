import Layout from 'components/common/Layout'
import ReportModule from 'modules/ReportModule'
import ReportsSegment from 'modules/ReportModule/segments/ReportsSegment'
import React from 'react'

const Reports = () => {
  return (
    <div>
        <Layout>
            <ReportModule>
                <ReportsSegment />
            </ReportModule>
        </Layout>
    </div>
  )
}

export default Reports