import Adult from "modules/InquiriesModule/assets/svg/Adult";
import Child from "modules/InquiriesModule/assets/svg/Child";
import Infant from "modules/InquiriesModule/assets/svg/Infant";
import React from "react";

export default function PassengerRecord({ inq_passengers }: any) {
  return (
    <div className="w-[237.67px] h-[18px] flex flex-row items-center justify-between text-sm">
      <div className="flex flex-row items-center justify-center gap-[7px]">
        <Adult />
        <div className="relative inline-block w-[6.54px] h-[16.88px] shrink-0">
          {
            inq_passengers.filter(
              (passenger: any) => passenger.type === "adult"
            ).length
          }
        </div>
      </div>
      <div className="flex flex-row items-center justify-center gap-[7px]">
        <Child />
        <div className="relative inline-block w-[7.36px] h-[16.88px] shrink-0">
          {
            inq_passengers.filter(
              (passenger: any) => passenger.type === "child"
            ).length
          }
        </div>
      </div>
      <div className="flex flex-row items-center justify-center gap-[7px] text-lightsteelblue">
        <Infant />
        <div className="relative inline-block w-[8.17px] h-[18px] shrink-0">
          {
            inq_passengers.filter(
              (passenger: any) => passenger.type === "infant"
            ).length
          }
        </div>
      </div>
    </div>
  );
}
