import { Realtime } from "ably/promises";
import { useApolloClient, useMutation } from "@apollo/client";
import { GET_TEMPLATE, SEND_GMAIL_EMAIL } from "./graphql/Query";
import { useUserData } from "@nhost/react";
import { sendGenericEmail } from "~/utils/sendEmail";
import { SEND_EMAIL } from "./graphql/Mutation";

export default function useEmail() {
  const client = useApolloClient();
  const userData = useUserData();
  const [sendEmail] = useMutation(SEND_EMAIL);
  const send: any = async (
    templateId: any,
    brdId: any,
    to: any,
    variables: any
  ) => {
    const GetTemplateResponse = await client.query({
      query: GET_TEMPLATE,
      variables: {
        templateId,
        brdId,
      },
    });

    // const templateFrom = GetTemplateResponse.data.brd_emails_templates[0]?.from;
    const refresh_token =
      GetTemplateResponse.data.brd_emails_templates[0]?.brd_list?.thp_list
        ?.email_refresh_token;

    const thp_id =
      GetTemplateResponse.data.brd_emails_templates[0]?.brd_list?.thp_list?.id;

    const domain =
      GetTemplateResponse.data.brd_emails_templates[0]?.brd_list?.domain;

    let emailBody = GetTemplateResponse.data.brd_emails_templates[0].content;
    let emailSubject = GetTemplateResponse.data.brd_emails_templates[0].subject;

    const variablesAllowed: any =
      GetTemplateResponse.data.brd_emails_templates[0]?.def_emails_template?.variables_allowed.split(
        ","
      );

    variablesAllowed.map((variable: any) => {
      emailBody = emailBody.replace(
        new RegExp(`{{${variable}}}`, "g"),
        variables?.[variable]
      );
      emailSubject = emailSubject.replace(
        `{{${variable}}}`,
        variables?.[variable]
      );
    });

    try {
      if (refresh_token) {
        const response = await client.query({
          query: SEND_GMAIL_EMAIL,
          variables: {
            refresh_token: refresh_token,
            sendEmail: true,
            to,
            subject: emailSubject,
            body: emailBody,
            from: userData?.metadata?.alias,
            th_id: thp_id,
            alias: false,
          },
        });
        console.log("EmailResponse", response);
      } else {
        const emailData = {
          emails: to,
          body: emailBody,
          subject: emailSubject,
          from: "info@" + domain,
        };
        const emailResp = await sendGenericEmail(emailData, sendEmail);
        console.log("EmailResponse", emailResp);
      }

      // Handle success or do any additional logic here
    } catch (error) {
      // Handle error
      console.error("Error sending email:", error);
    }
  };
  return send;
}
