// Import react router v6
import { Routes, Route } from "react-router-dom";
// Import protected route
import { ProtectedRoute } from "./ProtectedRoute";
// Import Pages
import ProfilePage from "../pages/admin/ProfilePage";
import Dashboard from "../pages/admin/Dashboard";
import Customers from "../pages/admin/Customers";
import Subscriptions from "../pages/admin/Subscriptions";
import Teams from "../pages/admin/Teams";
import Vendors from "../pages/admin/Vendors";
import PaymentsMethods from "../pages/admin/PaymentsMethods";
import API from "../pages/admin/API";
import ProfitSlabs from "../pages/admin/ProfitSlabs";
import TicketRequests from "../pages/admin/TicketRequests";
import Destinations from "../pages/admin/Destinations";
import Inquiry from "../pages/admin/Inquiry";
import Inbox from "../pages/admin/Inbox";
import Reports from "pages/admin/Reports";
import VendorDeposits from "~/pages/admin/VendorDeposits";
import Brands from "~/pages/admin/Brands";
import FlightsPages from "~/pages/admin/FlightsPages";
import BrandDetailsPage from "~/pages/admin/BrandDetailsPage";
import CustomerPayments from "~/pages/admin/CustomerPayments";
import Domains from "~/pages/admin/Domains";
import InboxPage from "../pages/admin/Inbox";
import TransactionsPage from "~/pages/admin/TransactionsPage";
import AccountsPage from "~/pages/admin/AccountsPage";
import EditBrands from "~/pages/admin/EditBrand";
import EditFAQs from "~/pages/admin/EditFAQs";
import EditTestimonials from "~/pages/admin/EditTestimonials";
import PageSettings from "~/pages/admin/PageSettings";
import BrandDestinationPage from "~/pages/admin/BrandDestinationPage";
import BrandDestinationAddPage from "~/pages/admin/BrandDestinationAddPage";
import BrandDestinationUpdatePage from "~/pages/admin/BrandDestinationUpdatePage";
import AddBrandPage from "~/pages/admin/AddBrandPage";
import InquiriesPage from "../pages/admin/InquiriesPage";
import BrandPostsPage from "~/pages/admin/BrandPostsPage";
import BrandPostsUpdatePage from "~/pages/admin/BrandPostsUpdatePage";
import BrandPostsAddPage from "~/pages/admin/BrandPostsAddPage";
import BrandEmailTemplatePage from "~/pages/admin/BrandEmailTemplatePage";

export default function AdminRoutes() {
  return (
    <Routes>
      <Route path="/admin" element={<ProtectedRoute role="admin" />}>
        <Route index element={<Dashboard />} />
        <Route
          path="profile"
          element={<ProfilePage />}
          // breadcrumb="Custom Example"
        />
        <Route path="inquiries" element={<InquiriesPage />} />
        <Route path="customers" element={<Customers />} />
        <Route path="inbox" element={<Inbox />} />
        <Route path="inbox/:code" element={<InboxPage />} />
        <Route path="subscriptions" element={<Subscriptions />} />
        <Route path="inquiry/:inquiry_id" element={<Inquiry />} />
        <Route path="brands" element={<Brands />} />
        <Route path="brands/add" element={<AddBrandPage />} />
        <Route path="brands/:brd_id/edit" element={<EditBrands />} />
        <Route path="brands/:brd_id/faqs" element={<EditFAQs />} />
        <Route
          path="brands/:brd_id/email-templates"
          element={<BrandEmailTemplatePage />}
        />
        <Route
          path="brands/:brd_id/testimonials"
          element={<EditTestimonials />}
        />
        <Route path="brands/:brd_id/page/:slug?" element={<PageSettings />} />
        <Route
          path="brands/:brd_id/testimonials"
          element={<EditTestimonials />}
        />
        <Route
          path="brands/:brd_id/destinations"
          element={<BrandDestinationPage />}
        />{" "}
        <Route
          path="brands/:brd_id/destinations/add"
          element={<BrandDestinationAddPage />}
        />
        <Route
          path="brands/:brd_id/destinations/update/:page_id"
          element={<BrandDestinationUpdatePage />}
        />
        <Route path="brands/:brd_id/posts" element={<BrandPostsPage />} />{" "}
        <Route
          path="brands/:brd_id/posts/add"
          element={<BrandPostsAddPage />}
        />
        <Route
          path="brands/:brd_id/posts/update/:post_id"
          element={<BrandPostsUpdatePage />}
        />
        {/* <Route path="brands/:brd_id" element={<BrandDetailsPage />} /> */}
        <Route path="domains" element={<Domains />} />
        <Route path="teams" element={<Teams />} />
        <Route path="vendors" element={<Vendors />} />
        <Route path="vendor-deposits" element={<VendorDeposits />} />
        <Route path="payment-methods" element={<PaymentsMethods />} />
        <Route path="api" element={<API />} />
        <Route path="profit-slabs" element={<ProfitSlabs />} />
        <Route path="ticket-requests" element={<TicketRequests />} />
        <Route path="destinations" element={<Destinations />} />
        <Route path="reports" element={<Reports />} />
        <Route path="flights-pages" element={<FlightsPages />} />
        <Route path="customer-payments" element={<CustomerPayments />} />
        <Route path="transactions" element={<TransactionsPage />} />
        <Route path="accounts" element={<AccountsPage />} />
      </Route>
    </Routes>
  );
}
