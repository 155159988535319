import { gql } from "@apollo/client";

export const GET_BRNAD_FAQs = gql`
  query GetBrandFAQs($id: uuid) {
    brd_faq(where: { brd_id: { _eq: $id } }) {
      answer
      created_at
      id
      category
      ordering
      question
      page_id
      updated_at
      brd_flights_page {
        id
        destination_name
        destination_iata
      }
    }
  }
`;
