import { useQuery } from "@apollo/client";
import React from "react";
import travelHouseStore from "store/travelHouse/travelHouseStore";
import { GET_TRANSACTIONS } from "./graphql/Query";
import TransactionsTable from "./components/TransactionsTable";
import SidePopup from "~/components/common/SidePopup";
import AddEquity from "./components/AddEquity";
import VendorDeposit from "./components/VendorDeposit";
import AddManual from "./components/AddManual";
// import TicketRequestRow from "./components/TicketRequestRow";

export default function TransactionsList() {
  const { travelHouse }: any = travelHouseStore();

  const { loading, data, error } = useQuery(GET_TRANSACTIONS, {
    variables: { thp_id: travelHouse.id },
  });

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error in the query {error.message}</div>;

  return (
    <>
      <h1 className="text-gray-700 uppercase text-[18px] font-bold border-b-2 border-gray-100 mb-2">
        Journal Transactions ({data.acc_transactions.length})
      </h1>
      <TransactionsTable transactions={data.acc_transactions} />

      <SidePopup
        className="mb-[140px]"
        width="100%"
        component={<AddManual />}
        text="Manual Entry"
      />
      <SidePopup
        className="mb-[70px]"
        component={<VendorDeposit />}
        text="Vendor Deposit"
      />
      <SidePopup component={<AddEquity />} text="Add Capital Deposit" />
    </>
  );
}
