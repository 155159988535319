import React, { useState } from "react";
import { useQuery } from "@apollo/client";
// import gql queries
import { GET_INQUIRY } from "./graphql/Query";
import InquiryCard from "../../components/InquiryCard";
import SuggestionCard from "./components/SuggestionCard";
import UserCard from "~/components/UserCard";
import PassengersCard from "./components/PassengersCard";
import PaymentsCard from "./components/PaymentsCard";
import TicketRequest from "./components/TicketRequest";
import { useUserDefaultRole, useUserId } from "@nhost/react";
import { getTimeReadableFormat } from "~/utils/GetTimeReadable";
import moment from "moment";
import { FaEnvelopeOpenText } from "react-icons/fa";
import Inbox from "~/modules/InboxModule/segments/Inbox";
import { GET_TRAVEL_HOUSE_BY_ID } from "~/modules/InboxModule/segments/Inbox/graphql/Query";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import NoDataFound from "~/components/common/NoDataFound";
import Invoices from "./components/Invoices";

export default function InquiryDetail({ inquiry_id }: any) {
  const defaultRole = useUserDefaultRole();
  const user_id = useUserId();
  // get data based on query
  const { loading, data, error } = useQuery(GET_INQUIRY, {
    variables: { inquiry_no: inquiry_id },
  });
  const { travelHouse }: any = travelHouseStore();
  const { loading: travelHouseLoading, data: travelHouseData } = useQuery(
    GET_TRAVEL_HOUSE_BY_ID,
    {
      variables: { id: travelHouse?.id },
      fetchPolicy: "network-only",
    }
  );
  console.log("dataInq", data);
  const [showInbox, setShowInbox] = useState(false);
  // return loading while loading data
  if (loading) return <div>Loading...</div>;
  // Return error if get gql error
  if (error) return <div>Error in the query {error.message}</div>;

  if (
    defaultRole === "consultant" &&
    data.inq_list[0].status !== "pending" &&
    data.inq_list[0].picked_by !== user_id
  ) {
    return <NoDataFound />;
  }

  return (
    <>
      <h1 className="text-gray-700 uppercase text-[18px] flex  font-bold border-b-2 border-gray-100 mb-2 justify-between">
        <span className="flex">
          Inquiry #{data.inq_list[0].inquiry_no}{" "}
          {travelHouseData?.thp_list[0]?.email_refresh_token && (
            <FaEnvelopeOpenText
              className="ml-[10px] cursor-pointer"
              color="#3a3939"
              size="18px"
              onClick={() => setShowInbox(true)}
            />
          )}
        </span>
        {showInbox && (
          <div className="fixed inset-0 z-50 overflow-auto bg-gray-800 bg-opacity-50">
            <div className="modal mx-auto my-16 bg-white rounded-lg w-[80%] max-h-[80vh] p-6 overflow-y-auto">
              <button
                className="text-white ml-auto whitespace-nowrap mb-3 bg-[#135737] hover:bg-[#256a4a] focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-[#256a4a] dark:hover:bg-[#135737] focus:outline-none dark:focus:ring-blue-800"
                onClick={() => setShowInbox(false)}
              >
                Close
              </button>
              <Inbox defaultEmail={data?.inq_list[0]?.users?.email} />
            </div>
          </div>
        )}

        <span className="text-right">
          <span className=" text-[15px]">
            {getTimeReadableFormat(data.inq_list[0]?.created_at)}
          </span>
          <br />
          <span className=" text-[15px] font-normal">
            {moment(data.inq_list[0]?.created_at).format(
              "DD MMM, YYYY hh:mm A"
            )}
          </span>
        </span>
      </h1>
      <div className="">
        <div className="w-full">
          <div className="self-stretch flex flex-col items-start justify-start gap-[30px]">
            <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[33px] text-xs">
              <UserCard
                user={data.inq_list[0].users}
                editAble={
                  data.inq_list[0].status !== "completed" ? true : false
                }
                className="flex-1 min-h-[300px] rounded-8xs m-[15px] bg-white-100 shadow-[0px_0px_4px_rgba(0,_0,_0,_0.25)] border-none"
              />
              <InquiryCard
                inquiry={data.inq_list[0]}
                header={false}
                height="300px"
                editAble={
                  data.inq_list[0].status !== "completed" ? true : false
                }
              />
            </div>
          </div>
          <SuggestionCard inquiry={data.inq_list[0]} />
          <PassengersCard inquiry={data.inq_list[0]} />
          <Invoices inquiry={data.inq_list[0]} />
          <PaymentsCard inquiry={data.inq_list[0]} />

          {/* Ticket Request */}
          {defaultRole !== "user" ? (
            <TicketRequest inquiry={data.inq_list[0]} />
          ) : (
            <>
              {data?.inq_list[0]?.status === "completed" && (
                <TicketRequest inquiry={data.inq_list[0]} />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}
