import React from "react";
// Import components
import InputField from "components/FormInputs/InputField";
import SelectBox from "components/FormInputs/SelectBox";
// Import intial data
// import { legDefaultValue } from "../../../../data/InitalDefaultValue";
import { CabinClasses } from "~/data/cabinClasses";
import { legDefaultValue } from "~/data/InitalDefaultValue";

export default function FlightType({
  register,
  errors,
  control,
  setValue,
  unregister,
  getValues,
}: any) {
  return (
    <div>
      <div className="flex w-full bg-gray-100 rounded-lg p-2 flex-col">
        <h1 className="w-full font-bold text-left border-b-2 border-gray-300 mb-4">
          Trip Type
        </h1>
        <div className="flex">
          {/* Trip Type Type */}
          <div className="flex-1 self-stretch flex flex-row items-center justify-start gap-[30px]">
            {getValues("tripType") === 1 ? "One Way" : "Round Trip"}
          </div>
          {/* Cabin Class Select box*/}
          <div className="flex-1">
            <SelectBox
              control={control}
              fieldName={"cabinType"}
              label="Cabin Class"
              options={CabinClasses}
              selectedOption={
                CabinClasses.filter(
                  (cabin: any) => cabin.value === getValues("cabinType")
                )[0]
              }
              required={true}
              inputClasses="border rounded border-gray-200 pl-3"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
