import { useQuery } from "@apollo/client";
import React from "react";
import travelHouseStore from "store/travelHouse/travelHouseStore";
import { GET_INQUIRIES } from "./graphql/Query";
import TicketRequestRow from "./components/TicketRequestRow";

export default function TicketRequest() {
  const { travelHouse }: any = travelHouseStore();

  const { loading, data, error } = useQuery(GET_INQUIRIES, {
    variables: { th_id: travelHouse.id },
  });

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error in the query {error.message}</div>;

  return (
    <>
      <h1 className="text-gray-700 uppercase text-[18px] font-bold border-b-2 border-gray-100 mb-2">
        Tickets ({data.inq_ticket.length})
      </h1>
      <main className="w-full pt-2">
        <section className="container">
          <div className="flex flex-col">
            <div className="overflow-x-auto">
              <div className="inline-block min-w-full py-2 align-middle">
                <div className="overflow-hidden border border-gray-200  md:rounded-lg">
                  <table className="min-w-full text-center divide-y divide-gray-200">
                    <thead className="bg-gray-50 ">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 "
                        >
                          <div className="flex items-center gap-x-3">
                            <button className="flex items-center gap-x-2">
                              <span>Sr No.</span>
                            </button>
                          </div>
                        </th>

                        <th
                          scope="col"
                          className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 "
                        >
                          Sale Price
                        </th>

                        <th
                          scope="col"
                          className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 "
                        >
                          Received
                        </th>

                        <th
                          scope="col"
                          className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 "
                        >
                          Cancelation Charges
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 "
                        >
                          Fair Expiry
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 "
                        >
                          PNR
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 "
                        >
                          IBE
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 "
                        >
                          Cost
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 "
                        >
                          Attol
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 "
                        >
                          Miscellaneous
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 "
                        >
                          Status
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 "
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200 ">
                      {data.inq_ticket.length > 0 ? (
                        data.inq_ticket.map((ticket: any, i: number) => (
                          <TicketRequestRow key={i} ticket={ticket} index={i} />
                        ))
                      ) : (
                        <tr className="">
                          <td
                            colSpan={12}
                            className="px-4 py-4 text-sm font-medium text-gray-700  whitespace-nowrap"
                          >
                            <div className="inline-flex items-center gap-x-3">
                              <span>No Record Found</span>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
