import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

type State = {
  notifications: [];
};

type Actions = {
  setNotifications: (data: any) => void;
};

const notificationsStore = create<State & Actions>()(
  persist(
    (set, get) => ({
      notifications: [],
      addNotifications: (newNotification: any) =>
        set((state: any) => ({
          notifications: [...state.notifications, newNotification].sort(
            (a, b) =>
              new Date(b.created_at).getTime() -
              new Date(a.created_at).getTime()
          ),
        })),
      removeNotification: (notificationId: any) =>
        set((state: any) => ({
          notifications: state.notifications.filter(
            (notification: any) => notification.id !== notificationId
          ),
        })),
    }),
    {
      name: "notifications",
      storage: createJSONStorage(() => sessionStorage),
      onRehydrateStorage: () => (state: any) => {
        if (typeof state !== "undefined") {
          state.setHasHydrated(true);
        }
      },
    }
  )
);

export default notificationsStore;
