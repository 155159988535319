import React from "react";
import DestinationDetail from "./components/DestinationDetail";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import SeoSettings from "./components/SeoSettings";
import { GET_PAGE_DETAILS } from "./graphql/Query";
import TipsSection from "./components/TipsSection";

const DestinationUpdate = () => {
  const { page_id } = useParams();
  const { loading, data } = useQuery(GET_PAGE_DETAILS, {
    variables: { page_id: page_id },
  });
  if (loading) return <p>Loading ...</p>;
  console.log("xwt brandata ===>", data);
  const pageData = data?.brd_flights_pages?.at(0);
  if (pageData) {
    return (
      <div className="grid grid-cols-1 px-4 pt-6 xl:grid-cols-3 xl:gap-4 dark:bg-gray-900">
        <div className="mb-4 col-span-full xl:mb-2">
          <DestinationDetail page={pageData} />
          <TipsSection page={pageData} />
          <SeoSettings
            seo={pageData.brd_seo_settings?.[0]}
            brd_id={pageData.brd_id}
          />
        </div>
      </div>
    );
  } else {
    return <p>No page details found</p>;
  }
};

export default DestinationUpdate;
