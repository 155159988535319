import React, { useEffect } from "react";
import Layout from "~/components/common/Layout";
import useEmail from "~/hooks/emails/useEmail";
import DashboardModule from "~/modules/DashboardModule";
import DashboardStats from "~/modules/DashboardModule/segments/DashboardStats";

export default function Dashboard() {
  const emailSend = useEmail();

  useEffect(() => {
    const fetch = async () => {
      const variables: any = {
        flightNo: "123",
        origin: "BUD",
        depart: "LHR",
        UserEmail: "uk@guk.com",
      };

      const res = await emailSend(
        1,
        "39d77887-9784-4dbd-9674-57f9586b3156",
        "szulqarnain146@gmail.com",
        variables
      );
    };
    fetch();
  }, []);

  return (
    <div>
      <Layout>
        <DashboardModule>
          <DashboardStats />
        </DashboardModule>
      </Layout>
    </div>
  );
}
