import React, { useState } from "react";
import SuggestionSelect from "./components/SuggestionSelect";
import SuggestionUnSelect from "./components/SuggestionUnSelect";

export default function SelectButton({
  selected,
  suggestion,
  inquiry_id,
  inquiry,
  user_email,
  brand_url,
  brand_name,
  brand_domain,
  selected_suggestion_id,
  picked_by,
  user_id,
}: any) {
  return (
    <>
      {selected_suggestion_id === suggestion.id ? (
        <SuggestionUnSelect
          inquiry={inquiry}
          inquiry_id={inquiry_id}
          suggestion_id={suggestion.id}
          picked_by={picked_by}
          user_id={user_id}
        />
      ) : (
        !selected_suggestion_id && (
          <SuggestionSelect
            inquiry={inquiry}
            inquiry_id={inquiry_id}
            user_email={user_email}
            brand_url={brand_url}
            brand_domain={brand_domain}
            suggestion={suggestion}
            brand_name={brand_name}
            suggestion_id={suggestion.id}
            picked_by={picked_by}
            user_id={user_id}
          />
        )
      )}
    </>
  );
}
