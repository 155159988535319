// Insert New notifications

import { Realtime } from "ably/promises";
import travelHouseStore from "store/travelHouse/travelHouseStore";
import { GET_ALL_USERS, GET_ROLE_USERS } from "./graphql/Query";
import { useApolloClient } from "@apollo/client";
const ably = new Realtime(
  "RjlN_g.uxcIfA:rEZUNjXzLac9hqi4G05eAOw0mhD2tZJNcK4zkO2zv5E"
);

const channel = ably.channels.get("notification");

// get all users id
const getUserId = async (data: any, client: any) => {
  try {
    console.log("data?.role", data?.role);
    if (data?.role) {
      const response = await client.query({
        query: GET_ROLE_USERS,
        variables: data,
      });
      return response.data.brd_users;
    } else {
      const response = await client.query({
        query: GET_ALL_USERS,
        variables: data,
      });
      return response.data.brd_users;
    }
  } catch (error) {
    console.error("Error fetching user data:", error);
  }
};

//Make notification data
const makeNotificationData = async (
  user_id: any,
  brand_id: any,
  client: any
) => {
  let users: any = [];
  if (user_id?.length === 36) {
    users.push(user_id);
  } else {
    let req: any = { th_id: brand_id };
    if (user_id !== "all") {
      req = { th_id: brand_id, role: user_id };
    }
    const res = await getUserId(req, client);
    console.log("resUsers", user_id, res);
    res.map((user: any) => {
      users.push(user.user_id);
    });
  }
  console.log("users", user_id, users);
  return users;
};

const useNotifications = () => {
  const { travelHouse }: any = travelHouseStore();
  const client = useApolloClient();
  // Define All notification function inside notification object
  const notifications = {
    // New inquery function
    newNotification: async (
      user_id: any,
      headline: any,
      description: any,
      url: any
    ) => {
      const users = await makeNotificationData(user_id, travelHouse.id, client);
      console.log("users", users);
      // console.log();
      users.map(async (user: any) => {
        await channel.publish(user, {
          headline: headline,
          description: description,
          url: travelHouse.custom_url + `/` + url,
          read_status: "unread",
          created_at: new Date(),
        });
      });
    },
  };
  return notifications;
};

export default useNotifications;
