import { useQuery } from "@apollo/client";
import { useUserId } from "@nhost/react";
import { GET_ASSIGNED_BRANDS } from "./graphql/Query";

const useAssignedBrands = () => {
  const userID = useUserId();
  const { loading, data } = useQuery(GET_ASSIGNED_BRANDS, {
    variables: { id: userID },
  });

  if (data?.brd_users?.length > 0) {
    const brands: any = [];
    data?.brd_users?.map((brd: any) => {
      console.log(brd);
      brands.push(brd?.brd_id);
    });
    return brands;
  } else {
    return false;
  }
};

export default useAssignedBrands;
