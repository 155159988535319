export const getTimeReadableFormat = (timestamp: any) => {
  const currentDate: any = new Date();
  const inputDate: any = new Date(timestamp);
  const timeDiff = currentDate - inputDate;

  const minutes = Math.floor(timeDiff / (1000 * 60));
  const hours = Math.floor(timeDiff / (1000 * 60 * 60));
  const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

  if (minutes < 1) {
    return "Just now";
  } else if (minutes === 1) {
    return "1m ago";
  } else if (minutes < 60) {
    return minutes + "m ago";
  } else if (hours === 1) {
    return "1h ago";
  } else if (hours < 24) {
    const remainingMinutes = minutes - hours * 60;
    return hours + "h " + remainingMinutes + "m ago";
  } else if (days === 1) {
    const formattedTime = formatTime(inputDate);
    return "Yesterday  " + formattedTime;
  } else if (days < 7) {
    const dayOfWeek = getDayOfWeek(inputDate);
    const formattedTime = formatTime(inputDate);
    return dayOfWeek + " " + formattedTime;
  } else {
    const formattedDate = formatDate(inputDate);
    const formattedTime = formatTime(inputDate);
    return formattedDate + " " + formattedTime;
  }
};

function formatDate(date: any) {
  const options = { year: "numeric", month: "short", day: "numeric" };
  return date.toLocaleDateString(undefined, options);
}

function getDayOfWeek(date: any) {
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  return days[date.getDay()];
}

function formatTime(date: any) {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const amPm = hours >= 12 ? "PM" : "AM";

  hours = hours % 12;
  hours = hours ? hours : 12; // Handle midnight

  minutes = minutes < 10 ? "0" + minutes : minutes;

  return hours + ":" + minutes + " " + amPm;
}
