import {
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import ViewIcon from "modules/InquiriesModule/segments/InquiryDetail/assets/svg/ViewIcon";
import React, { useState } from "react";
import {
  ADD_TRANSACTION,
  EDIT_INQUIRY,
  EDIT_TRANSACTION,
} from "./graphql/Mutation";
import { toast } from "react-toastify";
import useNotifications from "hooks/useNotifications";
import { SEND_EMAIL } from "~/config/Mutation";
import { CustomerTemplates } from "~/utils/TemplatesAll";
import { generateRandom10DigitCode } from "~/utils/generateRandomId";
import moment from "moment";
import { sendGenericEmail } from "~/utils/sendEmail";
import {
  GET_EMAILS,
  GET_TRAVEL_HOUSE_BY_ID,
} from "~/modules/InboxModule/segments/Inbox/graphql/Query";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import { useUserData } from "@nhost/react";
import useEmail from "~/hooks/emails/useEmail";

export default function PaymentRecord({ index, transaction }: any) {
  const [modalOpen, setModelOpen] = useState(false);
  const [showReceipt, setShowReceipt] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const client = useApolloClient();
  const { travelHouse }: any = travelHouseStore();
  const userData = useUserData();
  const [sendEmail] = useMutation(SEND_EMAIL);

  const emailSend = useEmail();
  const { loading: travelHouseLoading, data: travelHouseData } = useQuery(
    GET_TRAVEL_HOUSE_BY_ID,
    {
      variables: { id: travelHouse?.id },
      fetchPolicy: "network-only",
    }
  );

  const [editTransaction] = useMutation(EDIT_TRANSACTION);
  const [editInquiry] = useMutation(EDIT_INQUIRY);
  const [addTransaction] = useMutation(ADD_TRANSACTION);

  const alert = useNotifications();

  // Confirm payment
  const execConfirm = async () => {
    setModelOpen(false);
    setIsLoading(true);
    const payload = {
      id: transaction.id,
      status: "paid",
    };

    const tenDigitCode: any = generateRandom10DigitCode();

    const transactions: any = [
      {
        brd_id: transaction?.inq_list.brd_id,
        def_acc_id: 3,
        inq_id: transaction.inq_list.id,
        payment_method_id: transaction.acc_payment_method.id,
        transactions_no: tenDigitCode,
        date: moment(new Date()).format("YYYY-MM-DD"),
        type: "debit",
        amount: parseFloat(transaction.amount),
      },
      {
        brd_id: transaction?.inq_list.brd_id,
        def_acc_id: 4,
        user_id: transaction.inq_list.users.id,
        inq_id: transaction.inq_list.id,
        transactions_no: tenDigitCode,
        date: moment(new Date()).format("YYYY-MM-DD"),
        type: "credit",
        amount: -transaction.amount,
      },
    ];
    console.log("formData", transactions);
    if (transactions[0].amount + transactions[1].amount === 0) {
      try {
        const res = await addTransaction({
          variables: {
            transactions: transactions,
          },
        });
        toast.success("Transaction added successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        await client.resetStore();
        try {
          const res = await editTransaction({ variables: payload });
          if (res.data?.update_inq_transection?.returning?.length > 0) {
            const res = await editInquiry({
              variables: { id: transaction.inq_list.id },
            });

            const emailVariables: any = {
              inquiryNo: transaction.inq_list?.inquiry_no,
              userFirstName: transaction.inq_list?.users?.displayName,
              userLastName: "",
              consultantName: transaction.inq_list.picked_user?.displayName,
              brandName: transaction.inq_list?.brd_list?.name,
              amount: transaction.amount,
            };

            await emailSend(
              9,
              transaction.inq_list.brd_id,
              transaction.inq_list.users?.email,
              emailVariables
            );

            toast.success("Payment Status Updated Successfully", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            // console.log("emailResp", emailResp);
            await alert.newNotification(
              transaction?.inq_list?.picked_by,
              `Your customer payment approved by accountant, please check your portal`,
              "",
              ``
            );
            await alert.newNotification(
              transaction?.inq_list?.user_id,
              `Your payment has approved by account department, please check your portal`,
              "",
              ``
            );

            setIsLoading(false);
            await client.resetStore();
          }
          console.log("res", res);
        } catch (e) {
          setIsLoading(false);
          console.log(e);
          toast.error(`${e}`);
        }
      } catch (e) {
        console.error("error", e);
      }
    } else {
      toast.error("Transaction Error");
    }
  };
  return (
    <>
      <tr className="">
        <td className="px-4 py-4 text-sm font-medium text-gray-700  whitespace-nowrap">
          <div className="inline-flex items-center gap-x-3">
            <span>#{index + 1}</span>
          </div>
        </td>
        <td className="px-4 py-4 text-sm text-gray-500  whitespace-nowrap">
          {transaction?.inq_list?.users?.displayName}
        </td>
        <td className="px-4 py-4 text-sm font-medium text-gray-700 whitespace-nowrap">
          <div className="inline-flex items-center px-3 py-1 rounded-full gap-x-2 text-emerald-500 bg-emerald-100/60 ">
            <h2 className="text-sm font-normal">
              {transaction?.acc_payment_method?.name}
            </h2>
          </div>
        </td>
        <td className="px-4 py-4 text-sm text-gray-500  whitespace-nowrap">
          {transaction?.ref_no}
        </td>
        <td className="px-4 py-4 text-sm text-gray-500  whitespace-nowrap">
          £{transaction?.amount}
        </td>

        <td className="px-4 py-4 text-sm text-gray-500  whitespace-nowrap">
          {transaction?.status ?? "pending"}
        </td>
        <td className="px-4 py-4 text-sm text-gray-500  whitespace-nowrap">
          <div className="flex items-center gap-2">
            <button
              type={"button"}
              onClick={() => setShowReceipt(true)}
              className="rounded py-1 px-3"
            >
              <ViewIcon />
            </button>
            {transaction?.status !== "paid" && (
              <button
                type={"button"}
                onClick={() => setModelOpen(true)}
                className="bg-primary rounded py-1 px-3 text-white"
              >
                {isLoading ? "Confirming.." : "Confirm Payment"}
              </button>
            )}
          </div>
        </td>
      </tr>

      {modalOpen && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
          style={{ zIndex: 50 }}
        >
          <div className="relative bg-white rounded-xl shadow-2xl p-8 w-11/12 sm:w-3/4 md:w-1/2 lg:w-1/3">
            <h2 className="mb-6 text-2xl font-bold text-gray-700">
              Are you sure?
            </h2>
            <div className="flex justify-end space-x-4">
              <button
                onClick={(event: any) => setModelOpen(false)}
                className="px-5 py-2 font-semibold text-gray-700 bg-red-500 bg-opacity-10 rounded-full hover:bg-opacity-20"
              >
                No
              </button>
              <button
                onClick={(event: any) => execConfirm()}
                className="px-5 py-2 font-semibold text-white bg-green-500 rounded-full hover:bg-green-600"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      )}

      {showReceipt && (
        <div className="fixed z-[999] top-0 left-0 w-full min-h-screen bg-[#00000070] flex justify-center items-center py-[100px]">
          <div className="container bg-white rounded-[10px] p-[30px] max-w-[900px] m-[20px] w-full relative">
            <button
              onClick={() => setShowReceipt(false)}
              className="text-[16px] text-white flex justify-center items-center h-[25px] w-[25px] bg-red-500 rounded-full absolute right-[-10px] top-[-10px]"
            >
              x
            </button>
            <div className="">
              {transaction?.receipt ? (
                <img src={transaction?.receipt} alt="" />
              ) : (
                <p className="text-center">No file available</p>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
