import { useApolloClient, useMutation } from "@apollo/client";
import SaveFloppy from "modules/InquiriesModule/segments/InquiryDetail/components/TicketRequest/components/TicketRequestCard/assets/SaveFloppy";
import moment from "moment";
import React, { useState } from "react";
import { ADD_TRANSACTION, EDIT_INQUIRY } from "./graphql/Mutation";
import { toast } from "react-toastify";
import useNotifications from "hooks/useNotifications";
import { generateRandom10DigitCode } from "~/utils/generateRandomId";

export default function TicketRequestRow({ ticket, index }: any) {
  const [edit, setEdit] = useState(false);
  const [status, setStatus] = useState(
    ticket?.status === "pending_approval" ? "awaiting_vendor" : ticket?.status
  );
  const [ibe, setIbe] = useState(ticket?.ibe);
  const [ticket_cost_price, setCost] = useState(ticket?.ticket_cost_price);
  let [ticket_attol_price, setAttol] = useState<any>(
    ticket?.ticket_attol_price
  );
  let [ticket_mis_cost, setMis] = useState<any>(ticket?.ticket_mis_cost);

  const [isLoading, setIsLoading] = useState(false);

  const client = useApolloClient();
  const [editInquiry] = useMutation(EDIT_INQUIRY);
  const [addTransaction] = useMutation(ADD_TRANSACTION);
  const alert = useNotifications();

  const getPaidAmount = () => {
    let sum = 0;
    ticket?.inq_list?.inq_transections.map((item: any) => {
      if (item.status === "paid") {
        sum += item.amount;
      }
    });
    return sum;
  };

  const updateInquiry = async () => {
    if (ticket?.status === "pending_approval") {
      if (!ibe) {
        toast.error("IBE is not available");
        return;
      } else if (!ticket_cost_price) {
        toast.error("Cost is not available");
        return;
      }

      setIsLoading(true);
      const tenDigitCode: any = generateRandom10DigitCode();
      if (ticket_attol_price) {
        ticket_attol_price = parseFloat(ticket_attol_price);
      } else {
        ticket_attol_price = 0;
      }
      if (ticket_mis_cost) {
        ticket_mis_cost = parseFloat(ticket_mis_cost);
      } else {
        ticket_mis_cost = 0;
      }

      const rawTransactions: any = [
        {
          brd_id: ticket?.inq_list.brd_id,
          def_acc_id: 6,
          inq_id: ticket.inq_list.id,
          transactions_no: tenDigitCode,
          date: moment(new Date()).format("YYYY-MM-DD"),
          type: "debit",
          amount: parseFloat(ticket_cost_price),
        },
        {
          brd_id: ticket?.inq_list.brd_id,
          def_acc_id: 7,
          inq_id: ticket.inq_list.id,
          transactions_no: tenDigitCode,
          date: moment(new Date()).format("YYYY-MM-DD"),
          type: "debit",
          amount: parseFloat(ticket_attol_price),
        },
        {
          brd_id: ticket?.inq_list.brd_id,
          def_acc_id: 8,
          inq_id: ticket.inq_list.id,
          transactions_no: tenDigitCode,
          date: moment(new Date()).format("YYYY-MM-DD"),
          type: "debit",
          amount: parseFloat(ticket_mis_cost),
        },
        {
          brd_id: ticket?.inq_list.brd_id,
          def_acc_id: 2,
          user_id: ticket.vendor_id,
          inq_id: ticket.inq_list.id,
          transactions_no: tenDigitCode,
          date: moment(new Date()).format("YYYY-MM-DD"),
          type: "credit",
          amount: -(
            parseFloat(ticket_cost_price) +
            parseFloat(ticket_attol_price) +
            parseFloat(ticket_mis_cost)
          ),
        },
      ];
      const transactions = rawTransactions.filter(
        (obj: any) => obj.amount !== 0
      );

      console.log("formData", transactions);
      try {
        const res = await addTransaction({
          variables: {
            transactions: transactions,
          },
        });
        toast.success("Transaction added", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        await client.resetStore();
      } catch (e) {
        console.error("error", e);
      }
      // setIsLoading(false);
      // return;
    }
    const payload = {
      status: status,
      ibe: ibe || null,
      ticket_cost_price: ticket_cost_price || null,
      ticket_attol_price: ticket_attol_price || null,
      ticket_mis_cost: ticket_mis_cost || null,
    };

    try {
      const res = await editInquiry({
        variables: { id: ticket?.id, inquiry: payload },
      });
      if (res.data?.update_inq_ticket?.returning?.length > 0) {
        toast.success("Ticket Status Updated Successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });

        await alert.newNotification(
          ticket?.inq_list?.picked_by,
          `Ticket request status update by admin, please check your portal`,
          "",
          ``
        );

        setIsLoading(false);
        setEdit(false);
        await client.resetStore();
      }
    } catch (e) {
      setIsLoading(false);
      setEdit(false);
      console.log(e);
      toast.error(`£{e}`);
    }
  };

  const offer_price =
    ticket?.inq_list?.selected_suggestion?.inq_suggestion_costs.reduce(
      (sum: any, obj: any) => sum + parseFloat(obj.sale_price),
      0
    );

  return (
    <>
      <tr className="">
        <td className="px-4 py-4 text-sm font-medium text-gray-700  whitespace-nowrap">
          <div className="inline-flex items-center gap-x-3">
            <span>#{index + 1}</span>
          </div>
        </td>
        <td className="px-4 py-4 text-sm   whitespace-nowrap">
          £{offer_price}
        </td>
        <td className="px-4 py-4 text-sm font-medium text-gray-700 whitespace-nowrap">
          £{getPaidAmount()}
        </td>

        <td className="px-4 py-4 text-sm   whitespace-nowrap">
          £{ticket?.cancelation_charges}
        </td>
        <td className="px-4 py-4 text-sm   whitespace-nowrap">
          {moment(ticket?.inq_list?.selected_suggestion?.fare_expiry).format(
            "DD MMM, YYYY"
          )}
        </td>
        <td className="px-4 py-4 text-sm   whitespace-nowrap">
          <>#{ticket?.pnr}</>
        </td>
        <td className="px-4 py-4 text-sm   whitespace-nowrap">
          {edit && ticket?.ibe === null ? (
            <input
              type="text"
              className="border-gray-300 border-2 text-[14px] p-2 h-[40px] w-[70px] rounded "
              defaultValue={ticket?.ibe}
              placeholder="IBE Number"
              onChange={(e: any) => {
                setIbe(e.target.value);
              }}
            />
          ) : (
            <>#{ticket?.ibe}</>
          )}
        </td>
        <td className="px-4 py-4 text-sm   whitespace-nowrap">
          {edit && ticket?.ibe === null ? (
            <input
              type="text"
              className="border-gray-300 border-2  text-[14px] p-2 h-[40px] w-[70px] rounded "
              defaultValue={ticket?.ticket_cost_price}
              placeholder="Cost Price"
              onChange={(e: any) => {
                setCost(e.target.value);
              }}
            />
          ) : (
            <>{ticket?.ticket_cost_price}</>
          )}
        </td>
        <td className="px-4 py-4 text-sm   whitespace-nowrap">
          {edit && ticket?.ibe === null ? (
            <input
              type="text"
              className="border-gray-300 border-2  text-[14px] p-2 h-[40px] w-[70px] rounded "
              defaultValue={ticket?.ticket_attol_price}
              placeholder="Attol Price"
              onChange={(e: any) => {
                setAttol(e.target.value);
              }}
            />
          ) : (
            <>{ticket?.ticket_attol_price}</>
          )}
        </td>
        <td className="px-4 py-4 text-sm   whitespace-nowrap">
          {edit && ticket?.ibe === null ? (
            <input
              type="text"
              className="border-gray-300 border-2  text-[14px] p-2 h-[40px] w-[70px] rounded "
              defaultValue={ticket?.ticket_mis_cost}
              placeholder="Miscellaneous"
              onChange={(e: any) => {
                setMis(e.target.value);
              }}
            />
          ) : (
            <>{ticket?.ticket_mis_cost}</>
          )}
        </td>
        <td className="px-4 py-4 text-sm   whitespace-nowrap">
          {edit ? (
            <select
              onChange={(e: any) => setStatus(e.target.value)}
              className="border-gray-300 border-2  text-[14px] p-2 h-[40px] rounded "
              name=""
              id=""
              defaultValue={
                ticket?.status === "pending_approval"
                  ? "awaiting_vendor"
                  : ticket?.status
              }
            >
              <option value="awaiting_vendor">Awaiting Vendor</option>
              <option value="queued_pending">Queued Pending</option>
              <option value="queued">Queued</option>
              <option value="issued">Issued</option>
            </select>
          ) : (
            <div className="inline-flex items-center px-3 py-1 rounded-full gap-x-2 text-emerald-500 bg-emerald-100/60">
              <h2 className="text-sm font-normal">
                <span className="capitalize">
                  {ticket?.status.replace("_", " ")}
                </span>
              </h2>
            </div>
          )}
        </td>
        <td className="px-4 py-4 text-sm   whitespace-nowrap">
          <div className="flex items-center gap-2">
            {edit ? (
              isLoading ? (
                <p>Loading..</p>
              ) : (
                <button
                  onClick={updateInquiry}
                  type="button"
                  className="rounded p-2 cursor-pointer hover:bg-green-400"
                >
                  <SaveFloppy />
                </button>
              )
            ) : (
              <span
                onClick={() => {
                  setEdit(true);
                }}
              >
                Edit
              </span>
            )}
          </div>
        </td>
      </tr>
    </>
  );
}
