import { gql } from "@apollo/client";

export const GET_INQUIRIES = gql`
  query GetInquiries($th_id: uuid!) {
    inq_ticket(
      where: { inq_list: { thp_id: { _eq: $th_id } } }
      order_by: { created_at: desc }
    ) {
      ibe
      id
      inq_id
      pnr
      refundable
      status
      url
      vendor_id
      cancelation_charges
      void_time
      ticket_cost_price
      ticket_attol_price
      ticket_mis_cost
      inq_list {
        id
        brd_id
        picked_by
        inq_transections {
          amount
          status
        }
        selected_suggestion {
          fare_expiry
          inq_suggestion_costs {
            sale_price
          }
        }
      }
    }
  }
`;
