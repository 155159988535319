import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import RequestForm from "./components/RequestForm";
import travelHouseStore from "store/travelHouse/travelHouseStore";
import { useQuery } from "@apollo/client";
import { GET_VENDORS } from "./graphql/Query";
import {
  getPaidAmount,
  getSalePrice,
} from "~/modules/InquiriesModule/utils/getInquiryStats";

export default function TicketRequestForm({ inquiry }: any) {
  const { travelHouse }: any = travelHouseStore();
  // get data based on query
  const { loading, data, error } = useQuery(GET_VENDORS, {
    variables: { th_id: travelHouse.id },
  });
  const [refundable, setRefundable] = useState("");

  // return loading while loading data
  if (loading) return <div>Loading...</div>;
  // Return error if get gql error
  if (error) return <div>Error in the query {error.message}</div>;

  return (
    <div className="self-stretch bg-white-100 max-w-[600px] flex flex-col p-[25px] items-start justify-start text-left text-mini text-darkslategray  border-[1px] border-solid border-lightsteelblue">
      <div className="self-stretch flex flex-col items-start justify-between">
        <div className="grid grid-cols-1 gap-4 w-full">
          <div className="">
            <label htmlFor="" className="text-[14px] font-[600] mb-2 block">
              Refundable {"*"}
            </label>
            <select
              onChange={(e: any) => {
                setRefundable(e.target.value);
              }}
              className="w-full px-3 py-3 border rounded focus:outline-none focus:border-green-500 leading-tight border-gray-300"
            >
              <option value="">--Select--</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>
        </div>
        {refundable === "yes" ? (
          <RequestForm
            paidAmount={getPaidAmount(inquiry)}
            refundable={refundable}
            inquiry={inquiry}
            vendors={data.users}
            fareExpiry={inquiry.selected_suggestion?.fare_expiry || undefined}
          />
        ) : refundable === "no" &&
          getPaidAmount(inquiry) >= getSalePrice(inquiry) ? (
          <RequestForm
            paidAmount={getPaidAmount(inquiry)}
            refundable={refundable}
            inquiry={inquiry}
            vendors={data.users}
            fareExpiry={inquiry.selected_suggestion?.fare_expiry || undefined}
          />
        ) : (
          <p>Please pay your invoice first, then you can proceed further.</p>
        )}
      </div>
    </div>
  );
}
