import React from "react";
import InputField from "~/components/FormInputs/InputField";

export default function PurchaseDetail({
  ticketPurchase,
  setTicketPurchase,
  register,
  errors,
}: any) {
  return (
    <div className="border-2 border-teal-900  rounded-[10px] mt-2">
      <h2
        className={` ${
          ticketPurchase
            ? "border-b-2 border-teal-900 rounded-tl-[8px] rounded-tr-[8px]"
            : "rounded-[8px] "
        } p-2 cursor-pointer bg-gray-100 `}
        onClick={() => {
          setTicketPurchase(!ticketPurchase);
        }}
      >
        <span>Purchased Detail</span>
      </h2>
      {ticketPurchase && (
        <div className="p-2">
          <InputField
            register={register}
            errors={errors}
            type="text"
            fieldName="ibe_no"
            required={true}
            label="IBE *"
            placeHolder="IBE Number"
          />
          <InputField
            register={register}
            errors={errors}
            type="number"
            fieldName="ticket_cost_price"
            required={true}
            label="Purchase Cost *"
            placeHolder="Purchase Cost"
          />
          <InputField
            register={register}
            errors={errors}
            type="number"
            fieldName="ticket_attol_price"
            required={true}
            label="Attol Charges"
            placeHolder="Attol Charges *"
          />
          <InputField
            register={register}
            errors={errors}
            type="number"
            fieldName="ticket_mis_cost"
            required={true}
            label="Miscellaneous Cost"
            placeHolder="Miscellaneous Cost *"
          />
        </div>
      )}
    </div>
  );
}
