import Stripe from "stripe";
import { STRIPE_SK } from "~/config/enums";

const stripeHelper = new Stripe(STRIPE_SK.KEY, {
  apiVersion: "2022-11-15",
});

export async function createCustomer(stripe: any, email: string, name: string) {
  const existingCustomers = await stripe.customers.list({ email });
  if (existingCustomers.data.length > 0) {
    // If a customer with the email exists, return that customer
    console.log("Customer already exists", existingCustomers.data[0]);
    return existingCustomers.data[0];
  }

  // If no existing customer found, create a new customer
  const customer = await stripe.customers.create({ email, name });
  console.log("Customer created", customer);
  return customer;
}

export async function createInvoiceItems(
  stripe: any,
  customerId: any,
  amount: any,
  currency: any,
  invoiceId: any
) {
  const invoiceItem = await stripe.invoiceItems.create({
    customer: customerId,
    invoice: invoiceId,
    amount: amount,
    currency: currency,
    description: "One-time charge",
  });
  console.log("invoiceItem", invoiceItem);
  return invoiceItem;
}

export async function createInvoice(
  stripe: any,
  customerId: any,
  dueDate: any
) {
  const invoice = await stripe.invoices.create({
    customer: customerId,
    collection_method: "send_invoice",
    due_date: dueDate,
  });

  console.log("invoice created", invoice);
  return invoice;
}

export async function getSubscriptions(subId: any) {
  // const customers = await stripeHelper.customers.list({ email });
  // const customer = customers.data[0];

  // if (!customer) {
  //   console.log("No customer found with that email address.");
  //   return;
  // }
  const subscriptions = await stripeHelper.subscriptions.retrieve(subId);

  // subscriptions.list({
  //   id: "sub_1NorvqG7sNvxSo7yAKdbhB02",
  // });

  console.log("subscriptions", subscriptions);
  return subscriptions;
}

export async function sendInvoice(stripe: any, invoiceId: any) {
  const sendInvoice = await stripe.invoices.sendInvoice(invoiceId);

  console.log("send invoice", sendInvoice);
  return sendInvoice;
}
