import { gql } from "@apollo/client";

export const INSERT_DEFAULT_SETTING = gql`
  mutation InsertDefaultSetting($data: brd_default_setting_insert_input!) {
    insert_brd_default_setting_one(object: $data) {
      id
    }
  }
`;

export const UPDATE_DEFAULT_SETTING = gql`
  mutation UpdateDefaultSetting(
    $id: brd_default_setting_pk_columns_input!
    $data: brd_default_setting_set_input!
  ) {
    update_brd_default_setting_by_pk(pk_columns: $id, _set: $data) {
      id
      features_heading
      features_sub_heading
    }
  }
`;

export const UPDATED_TIPS = gql`
  mutation UpdateTips(
    $id: brd_flights_pages_tips_pk_columns_input!
    $data: brd_flights_pages_tips_set_input!
  ) {
    update_brd_flights_pages_tips_by_pk(pk_columns: $id, _set: $data) {
      id
    }
  }
`;

export const INSERT_TIPS = gql`
  mutation InsertTips($data: brd_flights_pages_tips_insert_input!) {
    insert_brd_flights_pages_tips_one(object: $data) {
      id
    }
  }
`;
