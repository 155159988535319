export default function Loader({ width }: any) {
  // const widthData = `w-[${width}]`
  console.log("width", width);
  return (
    <>
      <div className="flex justify-center items-center h-screen">
        <div className={width}>
          <div className="image-container flex justify-center">
            <img
              src="/assets/img/rotate.png"
              alt=""
              className="max-w-[200px] rotating-image"
            />
          </div>
        </div>
      </div>
    </>
  );
}
