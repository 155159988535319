import moment from "moment";

export const formatData = (formData: any) => {
  let suggestions: any = [];

  const suggestionStopObj = (stop: any, ordering: any) => {
    return {
      id: stop?.id,
      airline_name: stop?.airline?.iata,
      airline_no: stop?.airlineNumber,
      arrival_datetime: moment(stop?.arrivalDateTime).format(
        "YYYY-MM-DD HH:mm:ss"
      ),
      departure_datetime: moment(stop?.departDateTime).format(
        "YYYY-MM-DD HH:mm:ss"
      ),
      from: stop?.departCity?.iata_code,
      to: stop?.arrivalCity?.iata_code,
      ordering: ordering + 1,
      created_at: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
    };
  };

  //   "2023-08-11T09:59:46.627Z"

  const suggestionObj = (legType: any, legDetail: any) => {
    let stops: any = [];
    legDetail.stops.forEach((stop: any, index: any) => {
      const stopObj = suggestionStopObj(stop, index);
      stops.push(stopObj);
    });

    return {
      inquiry_id: formData?.inquiry_id,
      suggestion_no: formData?.suggestion_no,
      id: legDetail?.suggestion_id,
      leg_type: legType,
      cost_price: null,
      offer_price: null,
      luggage: legDetail?.luggage,
      cabin_class: formData?.cabinType,
      hand_carry: legDetail?.handCary,
      fareExpiry: moment(legDetail?.fareExpiry).format("YYYY-MM-DD"),
      invoiceDueDate: moment(legDetail?.invoiceDueDate).format("YYYY-MM-DD"),
      suggestion_stops: {
        data: stops,
      },
      suggestion_cost: {
        data: [...formData?.suggestion_cost],
      },
    };
  };

  Array.from({ length: formData.tripType }, (item: any, index: any) =>
    index === 0 ? "departing" : "returning"
  ).forEach((legType: any, index: any) => {
    const sugestion = suggestionObj(
      legType,
      index === 0 ? formData?.departLeg : formData?.returnLeg
    );
    suggestions.push(sugestion);
  });

  return suggestions;
};

export const getDate = (dateInp: any) => {
  // const newDate = new Date();

  const dateString = dateInp;
  const dateParts = dateString.split("/");
  const timeParts = dateParts[2].split(" ");

  // Extract the date components
  const day = parseFloat(dateParts[0]);
  const month = parseFloat(dateParts[1]) - 1; // Months are zero-based (0-11)
  const year = parseFloat(timeParts[0]);

  // Extract the time components
  const time = timeParts[1].split(":");
  const hour = parseFloat(time[0]);
  const minute = parseFloat(time[1]);

  // Create the Date object
  const dateObj = new Date(year, month, day, hour, minute);
  return dateObj;
};
