import Layout from "components/common/Layout";
import React from "react";
import UsersModule from "~/modules/UsersModule";
import PaymentRequest from "~/modules/AccountModule/segments/PaymentRequest";

export default function CustomerPayments() {
  return (
    <div>
      <Layout>
        <UsersModule>
          <PaymentRequest />
        </UsersModule>
      </Layout>
    </div>
  );
}
