import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import { toast } from "react-toastify";
// Import compoonents
import FlightType from "./components/FlightType";
import FlightPrices from "./components/FlightPrices";
import FlightLeg from "./components/FlightLeg";
// Import graphql
import { ADD_SUGGESTION } from "./graphql/Mutation";
// Import utils
import { formatData } from "../../utils/formatData";
import FlightDates from "./components/FlightDates";
import useNotifications from "hooks/useNotifications";
import PassengersCost from "./components/PassengersCost";
import { SEND_EMAIL } from "~/config/Mutation";
import { CustomerTemplates } from "~/utils/TemplatesAll";
import { sendGenericEmail } from "~/utils/sendEmail";
import {
  GET_EMAILS,
  GET_TRAVEL_HOUSE_BY_ID,
} from "~/modules/InboxModule/segments/Inbox/graphql/Query";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import { useUserData, useUserDisplayName } from "@nhost/react";
import useEmail from "~/hooks/emails/useEmail";

export default function SuggestionForm({
  defaultValue,
  inquiry_id,
  inquiry,
  user_id,
  user_phone,
  user_email,
  brand_url,
  brand_name,
  brand_domain,
  passengers,
  setShowAddModal,
}: any) {
  const [addSuggestion] = useMutation(ADD_SUGGESTION);
  const client = useApolloClient();
  //button loading state
  const { travelHouse }: any = travelHouseStore();
  const [sendEmail] = useMutation(SEND_EMAIL);
  const [isLoading, setIsloading] = useState<any>(false);
  const userData = useUserData();
  const userName = useUserDisplayName();
  const emailSend = useEmail();

  const { loading: travelHouseLoading, data: travelHouseData } = useQuery(
    GET_TRAVEL_HOUSE_BY_ID,
    {
      variables: { id: travelHouse?.id },
      fetchPolicy: "network-only",
    }
  );
  // Define react hooks form
  const alert = useNotifications();
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    watch,
    setValue,
    getValues,
    unregister,
  } = useForm({
    defaultValues: defaultValue,
  });

  // Submit form function
  const onSubmit = async (formData: any) => {
    console.log("===>formRawData", formData);
    formData["inq_id"] = inquiry_id;
    console.log("===>formData", formatData(formData));

    setIsloading(true);
    if (!isLoading) {
      try {
        // Create Suggestion function call
        const res = await addSuggestion({
          variables: { suggestions: formatData(formData) },
        });
        console.log("res", res);

        if (res.data?.insert_inq_suggestion?.returning?.length > 0) {
          const variables = {
            from: {
              th_name: brand_name,
            },
            to: {
              email: user_email,
            },
            variables: {
              phone: user_phone,
              url: brand_url,
            },
          };

          const emailVariables: any = {
            inquiryNo: inquiry?.inquiry_no,
            brandName: inquiry?.brd_list?.name,
            userFirstName: inquiry?.users?.displayName,
            userLastName: "",
            consultantName: userName,
          };

          // console.log("inquiryPicked", inquiry);
          await emailSend(
            3,
            inquiry.brd_id,
            inquiry.users?.email,
            emailVariables
          );

          toast.success(`Suggestions added successfully`, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });

          await alert.newNotification(
            user_id,
            `Your consultant added a suggestion Please review in the portal.`,
            "",
            ``
          );

          await client.refetchQueries({
            include: "all", // Consider using "active" instead!
          });
        }
        setIsloading(false); // change button loading state
        setShowAddModal(false); // hide form modal
      } catch (e) {
        setIsloading(false);
        console.error("error", e);
      }
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* Flight types trip_type and cabin classes */}
      <FlightType
        errors={errors}
        register={register}
        control={control}
        setValue={setValue}
        unregister={unregister}
        getValues={getValues}
      />
      <hr className="border-gray-300  border-dashed my-4" />

      {/* Map flight legs based on trip type */}
      {Array.from({ length: watch("tripType") }, (item: any, index: any) =>
        index === 0 ? "departLeg" : "returnLeg"
      ).map((item: any, index: any) => {
        return (
          <>
            {/* flight Data component */}
            <FlightLeg
              key={index}
              leg={item}
              errors={errors}
              register={register}
              setValue={setValue}
              control={control}
              unregister={unregister}
              getValues={getValues}
            />
            <hr className="border-gray-300  border-dashed my-4" />
          </>
        );
      })}

      {/* Dates section */}
      <FlightDates setValue={setValue} control={control} errors={errors} />

      {/* Flight Prices section */}
      {/* <FlightPrices errors={errors} register={register} control={control} /> */}
      <PassengersCost
        passengers={passengers}
        errors={errors}
        register={register}
        control={control}
        setValue={setValue}
      />
      {/* Submit form button */}
      <button
        className={` ${
          isLoading ? "bg-blue-300 cursor-wait" : "bg-primary"
        }  h-[50px] p-2 w-half px-4 rounded-lg text-white mt-2`}
        type="submit"
      >
        {isLoading ? "Creating.." : "Create Suggestion"}
      </button>
    </form>
  );
}
