import { gql } from "@apollo/client";

export const RESTORE_EMAIL_TEMPLATE = gql`
  mutation RestoreEmailTemplates(
    $brd_id: uuid!
    $templates: [brd_emails_templates_insert_input!]!
  ) {
    delete_brd_emails_templates(where: { brd_id: { _eq: $brd_id } }) {
      returning {
        id
      }
    }
    insert_brd_emails_templates(objects: $templates) {
      affected_rows
      returning {
        id
      }
    }
  }
`;
