import { useState } from "react";
import UserBox from "./components/UserBox";
import UserEdit from "./components/UserEdit";

const UserCard = ({ user, className, brands, editAble }: any) => {
  const [edit, setEdit] = useState(false);

  return (
    <>
      <div
        className={`relative border-[1px] bg-white-100 p-[15px] rounded-8xs border-solid border-steelblue-100 mt-[10px] flex flex-col items-start justify-start text-left text-xs text-darkslategray ${className}`}
      >
        {!edit && <UserBox user={user} setEdit={setEdit} editAble={editAble} />}
        {edit && <UserEdit user={user} setEdit={setEdit} brands={brands} />}
      </div>
    </>
  );
};

UserCard.defaultProps = {
  className: "",
  brands: false,
  editAble: true,
};
export default UserCard;
