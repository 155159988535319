export const faqDestinations = [
  {
    title: "Destination",
    value: "Destination",
  },
  {
    title: "Booking",
    value: "Booking",
  },
  {
    title: "Cancelation",
    value: "Cancelation",
  },
  {
    title: "Refund",
    value: "Refund",
  },
];
