import React, { useEffect } from "react";
import InputField from "~/components/FormInputs/InputField";

const TipsFormCard = ({ ft, index, register, errors, setValue }: any) => {
  useEffect(() => {
    if (ft?.id) {
      setValue(`tips.${index}.id`, ft?.id);
    }
  });
  return (
    <>
      <div className="w-full max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700">
        {/* <div className="overflow-hidden mb-4">
          <label
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            htmlFor="value_icon"
          >
            Icon
          </label>
          <input
            className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
            aria-describedby="value_icon_help"
            id="value_icon"
            type="file"
            {...register(`tips.${index}.icon`)}
          />
          <p
            className="mt-1 text-sm text-gray-500 dark:text-gray-300"
            id="value_icon_help"
          >
            SVG, PNG, JPG or GIF (MAX. 400x400px).
          </p>
        </div> */}
        <div className="mb-4">
          <InputField
            register={register}
            errors={errors}
            fieldName={`tips.${index}.title`}
            inputClasses="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 py-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
            label="Title"
            placeHolder="Title"
            defaultValue={ft?.heading}
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="description"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Description
          </label>
          <textarea
            {...register(`tips.${index}.content`)}
            placeholder="Content"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
            defaultValue={ft?.description}
          ></textarea>
        </div>
      </div>
    </>
  );
};

export default TipsFormCard;
