import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { ACC_LIST, GET_DEFAULT_ACCOUNTS, GET_USER } from "./graphql/Query";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import { useForm } from "react-hook-form";
import InputField from "~/components/FormInputs/InputField";

export default function SingleTrasaction({
  paymentMethods,
  sr,
  setEntries,
  entries,
  register,
  errors,
  watch,
  debit,
  credit,
  getValues,
  setDebit,
  setCredit,
  setValue,
  unregister,
  reset,
  //   update,
  resetField,
}: any) {
  const { payMethodsLoading, payMethodsData, payMethodsErrors } =
    paymentMethods;

  const { travelHouse }: any = travelHouseStore();
  const {
    loading: def_loading,
    data: def_data,
    error: def_error,
  } = useQuery(GET_DEFAULT_ACCOUNTS);

  const [acc_list, setAccList] = useState<any>([]);

  // get data based on query
  const { loading, data, error } = useQuery(ACC_LIST, {
    variables: { thp_id: travelHouse.id },
  });

  const {
    loading: usersLoading,
    data: usersData,
    error: usersErrors,
  } = useQuery(GET_USER, {
    variables: { thp_id: travelHouse.id },
  });

  const debitW = watch(`tr.${sr}.debit`);
  const creditW = watch(`tr.${sr}.credit`);

  const updateDebitCredit = () => {
    console.log("getValues", getValues("tr"));
    setDebit(
      getValues("tr")?.reduce(
        (total: any, entry: any) => total + parseFloat(entry.credit || 0),
        0
      )
    );
    setCredit(
      getValues("tr")?.reduce(
        (total: any, entry: any) => total + parseFloat(entry.debit || 0),
        0
      )
    );
  };
  useEffect(() => {
    updateDebitCredit();
  }, [debitW, creditW]);

  useEffect(() => {
    // let credit: any = document.getElementsByName(`tr.${sr}.credit`)[0];
    // credit.value = "";
    setValue(`tr.${sr}.credit`, "");
    // reset(`tr.${sr}.credit`);
    // update(sr, { credit: 0 });
    // resetField(`tr.${sr}.credit`);
    updateDebitCredit();
  }, [debitW]);

  useEffect(() => {
    // let debit: any = document.getElementsByName(`tr.${sr}.debit`)[0];
    // debit.value = "";
    setValue(`tr.${sr}.debit`, "");
    // reset(`tr.${sr}.debit`);
    // update(`${sr}.debit`, "");
    // update(sr, { debit: 0 });
    // resetField(`tr.${sr}.debit`);
    updateDebitCredit();
  }, [creditW]);

  useEffect(() => {
    if (def_data?.def_acc_list && data?.acc_list) {
      setAccList([...def_data?.def_acc_list, ...data?.acc_list]);
    }
  }, [def_data, data]);

  return (
    <tr className="">
      <td className="px-4 py-4 text-sm font-medium text-gray-700  ">
        <div className="inline-flex  gap-x-3">{sr + 1}</div>
      </td>

      <td className="px-4 py-4 text-sm font-medium text-gray-700  ">
        <select
          {...register(`tr.${sr}.acc_id`, { required: true })}
          className="text-sm  w-full border-gray-200 py-[2px] px-4 rounded-[4px]"
        >
          {def_loading && <option value="">Loading accounts ...</option>}
          {!def_loading &&
            def_data &&
            acc_list.map((data: any) => (
              <option key={data.id} value={data.id}>
                {data.name}
              </option>
            ))}
        </select>
      </td>
      <td className="px-4  text-sm font-medium text-gray-700  ">
        <InputField
          register={register}
          errors={errors}
          type="number"
          fieldName={`tr.${sr}.credit`}
          inputClasses="py-[4px] px-4 text-right"
        />
      </td>
      <td className="px-4  text-sm font-medium text-gray-700  ">
        <InputField
          register={register}
          errors={errors}
          type="number"
          fieldName={`tr.${sr}.debit`}
          inputClasses="py-[4px] px-4 text-right"
        />
      </td>
      <td className="px-4 text-sm font-medium text-gray-700  ">
        <InputField
          register={register}
          errors={errors}
          type="text"
          fieldName={`tr.${sr}.description`}
          inputClasses="py-[4px] px-4"
        />
      </td>
      <td>
        <select
          {...register(`tr.${sr}.user_id`)}
          className="text-sm  w-full border-gray-200 py-[2px] px-4 rounded-[4px]"
        >
          <option value="">N/A</option>
          {usersLoading && <option>Loading Users ...</option>}
          {!usersLoading &&
            usersData &&
            usersData.users.map((user: any) => (
              <option key={user.id} value={user.id}>
                {user.displayName} ({user.defaultRole})
              </option>
            ))}
        </select>
      </td>
      <td>
        <select
          {...register(`tr.${sr}.payment_method_id`)}
          className="text-sm  w-full border-gray-200 py-[2px] px-4 rounded-[4px]"
        >
          <option value="">N/A</option>
          {payMethodsLoading && <option>Loading Payment Methods ...</option>}
          {!payMethodsLoading &&
            payMethodsData &&
            payMethodsData.acc_payment_methods.map((pay: any) => (
              <option key={pay.id} value={pay.id}>
                {pay.name}
              </option>
            ))}
        </select>
      </td>
      <td>
        {entries.length === sr + 1 && entries.length > 2 && (
          <button
            onClick={() => {
              let arr = [...entries];
              arr.splice(sr, 1);
              setEntries([...arr]);
              unregister(`tr.${sr}`);
              updateDebitCredit();
            }}
          >
            Remove
          </button>
        )}
      </td>
    </tr>
  );
}
