import React from "react";
import BrandGeneralInfo from "./components/BrandGeneralInfo";
import BrandStylingInfo from "./components/BrandStylingInfo";
import DomainVerification from "./components/DomainVerification";
import LegalInformation from "./components/LegalInformation";
import SocialMediaLinks from "./components/SocialMediaLinks";
import SeoSettings from "./components/SeoSettings";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_BRAND_DETAILS } from "./graphql/Query";
import Loader from "~/components/common/loader/Loader";
import GoogleTagInfo from "./components/GoogleTagInfo";

const UpdateBrand = () => {
  const { brd_id } = useParams();
  const { loading, data } = useQuery(GET_BRAND_DETAILS, {
    variables: { brd_id: brd_id },
  });
  if (loading) return <Loader w="200px" />;
  console.log("xwt brandata ===>", data);
  const brandData = data?.brd_list?.at(0);
  if (brandData) {
    return (
      <div className="grid grid-cols-1 px-4 pt-6 xl:grid-cols-3 xl:gap-4 dark:bg-gray-900">
        <div className="mb-4 col-span-full xl:mb-2">
          <BrandGeneralInfo brand={brandData} />
          {/* <BrandStylingInfo brand={brandData} /> */}
          {/* <DomainVerification brand={brandData} /> */}
          {/* <LegalInformation brand={brandData} /> */}
          {/* <SocialMediaLinks brand={brandData} /> */}
          {/* <GoogleTagInfo brand={brandData} /> */}
          {/* <SeoSettings brand={brandData} /> */}
        </div>
      </div>
    );
  } else {
    return <p>No brand details found</p>;
  }
};

export default UpdateBrand;
