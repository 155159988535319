import { useApolloClient, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { GET_DEF_EMAIL_TEMPLATES } from "./graphql/Query";
import { RESTORE_EMAIL_TEMPLATE } from "./graphql/Mutation";

export default function RestoreTemplates({ brd_id }: any) {
  const [restoreEmailTemplate] = useMutation(RESTORE_EMAIL_TEMPLATE);
  const [isLoading, setIsLoading] = useState(false);
  const client = useApolloClient();
  const restoreEmails = async () => {
    setIsLoading(true);
    const defEamil = await client.query({
      query: GET_DEF_EMAIL_TEMPLATES,
    });

    const templates: any = [];
    defEamil?.data?.def_emails_templates.map((temp: any) => {
      const template = {
        brd_id,
        subject: temp.subject,
        content: temp.content,
        template_id: temp.id,
      };
      templates.push(template);
    });

    console.log("templates", templates);

    const restoreRes = await restoreEmailTemplate({
      variables: {
        brd_id,
        templates,
      },
    });

    await client.resetStore();
    console.log("restoreRes", restoreRes);
    setIsLoading(false);
  };

  return (
    <div className="w-full flex justify-between">
      <h1>Email Templates</h1>
      <button
        onClick={() => {
          restoreEmails();
        }}
        className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
      >
        {isLoading ? "Restoreing.." : "Restore All Templates"}
      </button>
    </div>
  );
}
