import { useQuery } from "@apollo/client";
import React from "react";
import { GET_DOMAINS_BRANDS, GET_DOMAINS_THP } from "./graphql/Query";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import DomainsCardBrand from "./components/DomainsCardBrand";
import DomainsCardTHP from "./components/DomainsCardTHP";
import SidePopup from "~/components/common/SidePopup";
import DomainsAddBrand from "./components/DomainsAddBrand";

const DomainsList = () => {
  const { travelHouse }: any = travelHouseStore();

  const { loading, data, error } = useQuery(GET_DOMAINS_BRANDS, {
    variables: { thp_id: travelHouse.id },
  });

  const { loading: thpLoading, data: thpData } = useQuery(GET_DOMAINS_THP, {
    variables: { id: travelHouse.id },
  });
  console.log("AllData", data);
  const getAdminUsers = (data: any) => {
    const uniqueAdmins: any = new Set();

    data?.brd_list?.forEach((brd: any) => {
      brd.thp_list.brd_users.forEach((userWrapper: any) => {
        const user = userWrapper.users;
        if (user.defaultRole === "admin") {
          uniqueAdmins.add(user.email);
        }
      });
    });

    return [...uniqueAdmins];
  };

  const adminUsers = getAdminUsers(data);
  console.log("adminUsers", adminUsers);

  // return loading while loading data
  if (loading) return <div>Loading...</div>;
  // Return error if get gql error
  if (error) return <div>Error in the query {error.message}</div>;
  return (
    <>
      {/* <h1 className="text-gray-700 uppercase text-[18px] font-bold border-b-2 border-gray-100 mb-2">
        Domains
      </h1>
      <div className="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-3 gap-4">
        {data.brd_list.map((brand: any, index: any) => (
          <DomainsCardBrand key={index} brand={brand} />
        ))}
        {data.brd_list.length === 0 && <h2>No record found</h2>}
      </div> */}
      {data.brd_list.length !== 0 && (
        <div className="w-[450px]">
          <>
            <h1 className="text-gray-700 mb-4 uppercase text-[18px] font-bold border-b-2 border-gray-100">
              PORTAL
            </h1>
            <DomainsCardTHP
              adminUsers={adminUsers}
              brands={data.brd_list}
              travelHouse={data.brd_list[0].thp_list}
            />
          </>
        </div>
      )}
      {data.brd_list.length === 0 && <p className="">No Record Found</p>}
      {/* <SidePopup
        text="Add Domain"
        component={<DomainsAddBrand adminUsers={adminUsers} />}
      /> */}
    </>
  );
};

export default DomainsList;
