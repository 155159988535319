import { gql } from "@apollo/client";

export const UPDATE_HEADINGS = gql`
  mutation UpdatePage(
    $id: brd_pages_pk_columns_input!
    $data: brd_pages_set_input!
  ) {
    update_brd_pages_by_pk(pk_columns: $id, _set: $data) {
      id
    }
  }
`;
