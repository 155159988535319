import React from "react";
import SuggestionStop from "./components/SuggestionStop";

export default function FlightDetail({ suggestion_leg, index }: any) {
  console.log("FlightDetail", suggestion_leg);
  return (
    <div className="flex-1 min-h-[313px] flex flex-col items-start flex-end justify-between">
      <div className=" w-full">
        <div className="flex justify-between w-full">
          <b>{suggestion_leg.type}</b>
          <b>Cabin: {suggestion_leg?.cabin || "N/A"}</b>
        </div>

        <hr className="h-px mb-2 bg-gray-500 border-0 w-full" />
      </div>
      <div className="self-stretch flex flex-col items-center justify-between">
        {suggestion_leg?.inq_suggestion_leg_stops.map((stop: any, i: any) => (
          <>
            <SuggestionStop stop={stop} />
            {/* {suggestion?.suggestion_stops.length !== i + 1 && (
              <hr className="h-px bg-gray-400 my-[5px] border-0 w-full" />
            )} */}
          </>
        ))}
      </div>
      <div className="w-full">
        <hr className="h-px bg-gray-500 border-0 w-full" />
        <div className="self-stretch flex flex-row items-start justify-start mt-2">
          <div className="flex flex-row items-start justify-start gap-[41px]">
            <div className="flex flex-row items-center justify-start gap-[10px]">
              <strong>Hand Carry:</strong>
              <div className="relative inline-block w-[39px] h-[15px] shrink-0">
                {suggestion_leg.hand_carry} kg
              </div>
            </div>
            <div className="flex flex-row items-center justify-start gap-[6px]">
              <strong>Luggage:</strong>
              <div className="relative inline-block w-[39px] h-[15px] shrink-0">
                {suggestion_leg.luggage} kg
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
