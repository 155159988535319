import { gql } from "@apollo/client";

export const GET_CUSTOMERS = gql`
  query GetCustomers($th_id: uuid!) {
    users(
      where: {
        brd_users: {
          thp_id: { _eq: $th_id }
          users: { defaultRole: { _eq: "user" } }
        }
      }
    ) {
      email
      id
      displayName
      phoneNumber
      metadata
      defaultRole
      disabled
      brd_users {
        user_id
        brd_id
        thp_id
      }
    }
  }
`;
