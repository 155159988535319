import React, { useState } from "react";
import TicketRequestForm from "./components/TicketRequestForm";
import TicketRequestCard from "./components/TicketRequestCard";
import CloseInquiry from "./components/CloseInquiry";
import CompleteInquiry from "./components/CompleteInquiry";
import { useUserDefaultRole } from "@nhost/react";

export default function TicketRequest({ inquiry }: any) {
  const [hideForm, setHideForm] = useState(false);
  const role = useUserDefaultRole();

  return (
    <div className="self-stretch flex flex-col items-start justify-start gap-[30px] mb-5">
      <div className="self-stretch flex justify-between pt-0 px-0 pb-[5px] items-start border-b-[1px] border-solid border-lightsteelblue">
        <b className="self-stretch relative leading-[20px] w-full">Ticket</b>
        <div className="flex w-[250px] gap-2">
          {role !== "user" && inquiry.status !== "completed" && (
            <CompleteInquiry inquiry={inquiry} />
          )}
        </div>
      </div>

      {inquiry?.inq_tickets[0] ? (
        <TicketRequestCard inquiry={inquiry} />
      ) : (
        <TicketRequestForm inquiry={inquiry} setHideForm={setHideForm} />
      )}
    </div>
  );
}
