import { useApolloClient, useMutation } from "@apollo/client";
import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { INSERT_SEO, UPDATE_SEO } from "./graphql/Mutation";
import InputField from "~/components/FormInputs/InputField";
import { useParams } from "react-router-dom";

const HomePageSeo = ({ page }: any) => {
  const { brd_id } = useParams();
  const seo = page?.brd_seo_settings?.at(0);
  const [insertSeo] = useMutation(INSERT_SEO);
  const [updateSeo] = useMutation(UPDATE_SEO);
  const client = useApolloClient();
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    setValue,
  } = useForm();

  const onSubmit = async (inpData: any) => {
    // console.log("xwt inptdata", inpData);
    if (seo?.id) {
      const res = await updateSeo({
        variables: {
          id: { id: seo?.id },
          data: {
            site_name: inpData?.site_name,
            default_title: inpData?.default_title,
            default_description: inpData?.default_description,
            default_keywords: inpData?.default_keywords,
            author_company: inpData?.author_company,
            canonical_url: inpData?.canonical_url,
            og_image_url: inpData?.og_image_url,
            twitter_site_handle: inpData?.twitter_site_handle,
            twitter_creator_handle: inpData?.twitter_creator_handle,
            locale: inpData?.locale,
          },
        },
      });

      if (res?.data?.update_brd_seo_setting_by_pk?.id) {
        toast.success("SEO  updated successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        await client.refetchQueries({
          include: "all",
        });
      }
    } else {
      const res = await insertSeo({
        variables: {
          data: {
            brd_id: brd_id,
            site_name: inpData?.site_name,
            default_title: inpData?.default_title,
            default_description: inpData?.default_description,
            default_keywords: inpData?.default_keywords,
            author_company: inpData?.author_company,
            canonical_url: inpData?.canonical_url,
            og_image_url: inpData?.og_image_url,
            twitter_site_handle: inpData?.twitter_site_handle,
            twitter_creator_handle: inpData?.twitter_creator_handle,
            locale: inpData?.locale,
            page_id: page.id,
          },
        },
      });

      if (res?.data?.insert_brd_seo_setting_one?.id) {
        toast.success("SEO  updated successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        await client.refetchQueries({
          include: "all",
        });
      }
    }
  };

  return (
    <div className="p-4 bg-white border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 sm:p-6 dark:bg-gray-800">
      <h3 className="text-xl font-semibold dark:text-white">SEO Settings</h3>
      {/* <p className="mb-4 mt-0 text-sm text-gray-500 dark:text-gray-300">
        Available variables are {`{{depart_destination}}`},{" "}
        {`{{arrival_destination}}`}, {`{{airline}}`}, {`{{starting_price}}`}
      </p> */}
      <form onSubmit={handleSubmit(onSubmit)} action="#">
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6 sm:col-span-3">
            <InputField
              register={register}
              errors={errors}
              fieldName="site_name"
              inputClasses="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 py-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              label="Site Name"
              placeHolder="Site Name"
              defaultValue={seo?.site_name}
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <InputField
              register={register}
              errors={errors}
              fieldName="default_title"
              inputClasses="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 py-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              label="Default Title"
              placeHolder="Default Title"
              defaultValue={seo?.default_title}
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <label
              htmlFor="defaultDescription"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Default Description
            </label>
            <textarea
              {...register("default_description")}
              id="defaultDescription"
              placeholder="Enter default description"
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              defaultValue={seo?.default_description}
            ></textarea>
          </div>

          <div className="col-span-6 sm:col-span-3">
            <InputField
              register={register}
              errors={errors}
              fieldName="default_keywords"
              inputClasses="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 py-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              label="Default Keywords"
              placeHolder="Keywords"
              defaultValue={seo?.default_keywords}
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <InputField
              register={register}
              errors={errors}
              fieldName="author_company"
              inputClasses="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 py-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              label="Author/Company"
              placeHolder="Author OR Company"
              defaultValue={seo?.author_company}
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <InputField
              register={register}
              errors={errors}
              fieldName="canonical_url"
              inputClasses="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 py-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              label="Canonical URL"
              placeHolder="URL"
              defaultValue={seo?.canonical_url}
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <InputField
              register={register}
              errors={errors}
              fieldName="og_image_url"
              inputClasses="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 py-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              label="OpenGraph Image URL"
              placeHolder="Enter Image URL"
              defaultValue={seo?.og_image_url}
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <label
              htmlFor="twitterCardType"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Twitter Card Type
            </label>
            <select
              name="twitterCardType"
              id="twitterCardType"
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
            >
              <option value="summary">Summary</option>
              <option value="summary_large_image">
                Summary with Large Image
              </option>
            </select>
          </div>
          <div className="col-span-6 sm:col-span-3">
            <InputField
              register={register}
              errors={errors}
              fieldName="twitter_site_handle"
              inputClasses="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 py-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              label="Twitter Site Handle"
              placeHolder="Enter Twitter Handle"
              defaultValue={seo?.twitter_site_handle}
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <InputField
              register={register}
              errors={errors}
              fieldName="twitter_creator_handle"
              inputClasses="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 py-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              label="Twitter Creator Handle"
              placeHolder="Enter Creator Handle"
              defaultValue={seo?.twitter_creator_handle}
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <InputField
              register={register}
              errors={errors}
              fieldName="locale"
              inputClasses="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 py-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              label="Locale"
              placeHolder="Enter locale"
              defaultValue={seo?.locale}
            />
          </div>

          <div className="col-span-6 sm:col-full">
            <button
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
              type="submit"
            >
              Save SEO Settings
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default HomePageSeo;
