import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useApolloClient, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import InputField from "components/FormInputs/InputField";
import { EDIT_PAYMENT_METHOD } from "./graphql/Mutation";
import { GET_BRANDS } from "./graphql/Query";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";

export default function PaymentMethodEdit({
  payment_method,
  setEdit,
  payment_methods,
}: any) {
  const [editPaymentMethod, { data }] = useMutation(EDIT_PAYMENT_METHOD);
  const client = useApolloClient();
  const { travelHouse }: any = travelHouseStore();
  // get data based on query
  const {
    loading,
    data: brands,
    error,
  } = useQuery(GET_BRANDS, {
    variables: { thp_id: travelHouse.id },
  });

  const {
    formState: { errors },
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      defaultPayment: payment_method.default,
      brd_id: payment_method?.brd_id,
    },
  });

  const setDefaultPaymentMethodToFalse = async () => {
    let defaultPaymentMethod;

    // If the payment method being edited is of type 'bank'
    if (payment_method.type === "bank") {
      defaultPaymentMethod = payment_methods.find(
        (method: any) =>
          method.default === true &&
          method.type === "bank" &&
          method.brd_id === payment_method.brd_id
      );
    }
    // If the payment method being edited is of type 'stripe' or 'stripe-thp'
    else if (
      payment_method.type === "stripe" ||
      payment_method.type === "stripe-thp"
    ) {
      defaultPaymentMethod = payment_methods.find(
        (method: any) =>
          method.default === true &&
          method.brd_id === payment_method.brd_id &&
          (method.type === "stripe" || method.type === "stripe-thp")
      );
    }

    if (defaultPaymentMethod) {
      const { __typename, ...paymentMethodData } = defaultPaymentMethod; // Exclude the __typename property

      paymentMethodData.default = false;

      let variables = {
        id: defaultPaymentMethod.id,
        paymentMethod: { ...paymentMethodData },
      };
      delete variables.paymentMethod.brd_list;
      const res = await editPaymentMethod({ variables: variables });
      console.log("res", res);
    }
  };

  const onSubmit = async (data: any) => {
    console.log("data", data);
    if (data.defaultPayment === "true") {
      await setDefaultPaymentMethodToFalse();
    }
    const paymentMethodData = {
      ...data,
      default: data.defaultPayment,
    };
    delete paymentMethodData.defaultPayment;

    if (
      paymentMethodData.type === "bank" &&
      !paymentMethodData.stripe_pub &&
      !paymentMethodData.stripe_secret
    ) {
      paymentMethodData.stripe_pub = null;
      paymentMethodData.stripe_secret = null;
    } else if (
      paymentMethodData.type === "stripe" &&
      !paymentMethodData.account_no &&
      !paymentMethodData.account_title &&
      !paymentMethodData.swift_code
    ) {
      paymentMethodData.account_no = null;
      paymentMethodData.account_title = null;
      paymentMethodData.swift_code = null;
    } else if (paymentMethodData.type === "stripe-thp") {
      paymentMethodData.stripe_pub = null;
      paymentMethodData.stripe_secret = null;
      paymentMethodData.account_no = null;
      paymentMethodData.account_title = null;
      paymentMethodData.swift_code = null;
    }

    const variables = {
      id: payment_method.id,
      paymentMethod: paymentMethodData,
    };
    console.log("variables", variables);
    try {
      const res = await editPaymentMethod({ variables: variables });
      console.log(res);
      if (res?.data?.update_acc_payment_methods_by_pk?.id) {
        setEdit(false);
        await client.resetStore();
      }
    } catch (e) {
      console.log(e);
      toast.error(`${e}`);
    }
  };

  // return loading while loading data
  if (loading) return <div>Loading...</div>;
  // Return error if get gql error
  if (error) return <div>Error in the query {error.message}</div>;

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-full">
      <h2 className="flex justify-center font-extrabold">
        Edit Payment Method
      </h2>
      <div>
        <div>
          <select
            {...register("brd_id", { required: true })}
            defaultValue={payment_method?.brd_id}
            disabled
            className=" mb-4 border  text-sm rounded-lg block w-full p-2.5 "
          >
            {brands.brd_list.map((brand: any, index: any) => (
              <option value={brand.id}>{brand.name}</option>
            ))}
          </select>
        </div>
        <div className="mt-2 mb-2">
          <InputField
            register={register}
            errors={errors}
            fieldName="name"
            required={true}
            placeHolder="Payment Method Name"
            defaultValue={payment_method.name}
          />
        </div>
        {payment_method.type === "bank" && (
          <>
            <div className="mt-2 mb-2">
              <InputField
                register={register}
                errors={errors}
                fieldName="account_no"
                required={true}
                placeHolder="Account Number"
                defaultValue={payment_method.account_no}
              />
            </div>
            <div className="mt-2 mb-2">
              <InputField
                register={register}
                errors={errors}
                required={true}
                fieldName="account_title"
                placeHolder="Account Title"
                defaultValue={payment_method.account_title}
              />
            </div>
            <div className="mt-2 mb-2">
              <InputField
                register={register}
                errors={errors}
                fieldName="swift_code"
                required={true}
                placeHolder="Sort Code / SWIFT Code"
                defaultValue={payment_method.swift_code}
              />
            </div>
          </>
        )}

        {payment_method.type === "stripe" && (
          <>
            <div className="mt-2 mb-2">
              <InputField
                register={register}
                errors={errors}
                required={true}
                fieldName="stripe_pub"
                placeHolder="Stripe Public Key"
                defaultValue={payment_method.stripe_pub}
              />
            </div>
            <div className="mt-2 mb-2">
              <InputField
                register={register}
                errors={errors}
                required={true}
                fieldName="stripe_secret"
                placeHolder="Stripe Secret Key"
                defaultValue={payment_method.stripe_secret}
              />
            </div>
          </>
        )}
      </div>
      <select
        {...register("defaultPayment", { required: true })}
        className=" mb-4 border  text-sm rounded-lg block w-full p-2.5 "
      >
        <option value="">-- Select Payment Method --</option>
        <option value="true">True</option>
        <option value="false">False</option>
      </select>

      <div className="flex gap-2 w-full">
        <button
          className="w-full button btn bg-red-500 text-white rounded-md p-2"
          onClick={() => {
            setEdit(false);
          }}
        >
          Close
        </button>
        <button
          className="w-full btn bg-green-500 text-white rounded-md p-2"
          type="submit"
        >
          Save
        </button>
      </div>
    </form>
  );
}
