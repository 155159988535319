import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import InputField from "~/components/FormInputs/InputField";
import {
  INSERT_DEFAULT_SETTING,
  UPDATE_DEFAULT_SETTING,
} from "./graphql/Mutation";
import { GET_DEFAULT_SETTINGS } from "./graphql/Query";
import { useFileUpload } from "@nhost/react";
import { ALLOWED_IMAGE_FORMATS } from "~/config/constants";

const BlogsSettings = ({ setSettings, settings }: any) => {
  const { brd_id } = useParams();
  const { loading, data } = useQuery(GET_DEFAULT_SETTINGS, {
    variables: { id: brd_id },
  });
  const [insertDefaultSettings] = useMutation(INSERT_DEFAULT_SETTING);
  const [updateDefaultSettings] = useMutation(UPDATE_DEFAULT_SETTING);
  const [logoFile, setLogoFile] = useState<any | null>(null);
  const { upload: uploadLogo, progress: logoUploadProgress } = useFileUpload();
  const client = useApolloClient();
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    setValue,
  } = useForm();
  if (loading) return <p>Loading...</p>;

  const handleLogoUpload = async (event: any) => {
    const file = event.target.files[0];

    if (
      !ALLOWED_IMAGE_FORMATS.includes(file.type.split("/").at(1).toLowerCase())
    ) {
      toast.error("Invalid File format");
    } else {
      console.log("etc file selected");
      setLogoFile({ file });
    }
  };
  const defSettings = data?.brd_default_setting?.at(0);
  const onSubmit = async (inpData: any) => {
    if (logoFile) {
      const logoUploadRes = await uploadLogo(logoFile);
      inpData["image"] = logoUploadRes?.id || null;
    }
    if (defSettings?.id) {
      const res = await updateDefaultSettings({
        variables: {
          id: { id: defSettings?.id },
          data: {
            blogs_heading: inpData?.blogs_heading,
            blogs_sub_heading: inpData?.blogs_sub_heading,
            blogs_featured_image: inpData?.image,
          },
        },
      });
      if (res?.data?.update_brd_default_setting_by_pk?.id) {
        toast.success("Settings updated successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        await client.refetchQueries({
          include: "all",
        });
      }
    } else {
      const res = await insertDefaultSettings({
        variables: {
          data: {
            brd_id: brd_id,
            blogs_heading: inpData?.blogs_heading,
            blogs_sub_heading: inpData?.blogs_sub_heading,
            blogs_featured_image: inpData?.image,
          },
        },
      });

      if (res?.data?.insert_brd_default_setting_one?.id) {
        toast.success("Settings updated successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        await client.refetchQueries({
          include: "all",
        });
      }
    }
  };

  return (
    <>
      <div
        onClick={() => setSettings(false)}
        className="bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-30"
      ></div>
      <div
        id="drawer-faq-setting-default"
        className={`fixed top-0 right-0 z-40 w-full h-screen max-w-md p-4 overflow-y-auto transition-transform bg-white dark:bg-gray-800 ${
          settings ? "translate-x-0" : "translate-x-full"
        }`}
        aria-labelledby="drawer-label"
        aria-hidden="true"
      >
        <h5
          id="drawer-label"
          className="inline-flex items-center mb-6 text-sm font-semibold text-gray-500 uppercase dark:text-gray-400"
        >
          Blogs Page Settings
        </h5>
        <button
          onClick={() => setSettings(false)}
          type="button"
          data-drawer-dismiss="drawer-faq-setting-default"
          aria-controls="drawer-faq-setting-default"
          className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-2.5 right-2.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
        >
          <svg
            aria-hidden="true"
            className="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
          <span className="sr-only">setting menu</span>
        </button>
        <form onSubmit={handleSubmit(onSubmit)} action="#">
          <div className="space-y-4">
            <div>
              <label className="mb-2 block">Hero Image for Blogs page</label>
              <input
                type="file"
                onChange={handleLogoUpload}
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              />
              {logoUploadProgress && (
                <p className="font-thin">
                  Image Upload Progress: {logoUploadProgress}%
                </p>
              )}
              <p
                className="mt-1 text-sm text-gray-500 dark:text-gray-300"
                id="light_logo_help"
              >
                SVG, PNG, JPG or GIF (MAX. 800x400px).
              </p>
            </div>
            <div>
              <InputField
                register={register}
                errors={errors}
                fieldName="blogs_heading"
                inputClasses="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 py-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                label="Blogs Page Heading"
                placeHolder="Blogs Page Heading"
                defaultValue={defSettings?.blogs_heading}
              />
            </div>
            <div>
              <InputField
                register={register}
                errors={errors}
                fieldName="blogs_sub_heading"
                inputClasses="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 py-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                label="Blogs Page Sub Heading"
                placeHolder="Blogs Page Sub Heading"
                defaultValue={defSettings?.blogs_sub_heading}
              />
            </div>
          </div>
          <div className="bottom-0 left-0 flex justify-center w-full pb-4 mt-4 space-x-4 sm:px-4 sm:mt-0">
            <button
              type="submit"
              className="w-full justify-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Update
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default BlogsSettings;
