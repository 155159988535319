export const stopDefaultValue = {
  airline: "",
  airlineNumber: "",
  departCity: "",
  departDateTime: "",
  arrivalCity: "",
  arrivalDateTime: "",
};

export const legDefaultValue = {
  landCary: "",
  luggage: "",
  stops: [stopDefaultValue],
};

export const suggestion_cost = {
  tax_price: "",
  cost_price: "",
  sale_price: "",
  passenger_id: "",
};

export const initialDefaultValue: any = {
  tripType: 1,
  cabinType: "economy",
  departLeg: legDefaultValue,
  returnLeg: legDefaultValue,
  suggestion_cost: [suggestion_cost],
  offerPrice: null,
  costPrice: null,
};
