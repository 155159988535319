import React from "react";
import Layout from "~/components/common/Layout";
import InquiriesModule from "~/modules/InquiriesModule";
import Inquiries from "~/modules/InquiriesModule/segments/cusotmers/Inquiries";

export default function Bookings() {
  return (
    <div>
      <Layout>
        <InquiriesModule>
          <Inquiries />
        </InquiriesModule>
      </Layout>
    </div>
  );
}
