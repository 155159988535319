// import InputField from "components/FormInputs/InputField";
import React from "react";
import InputField from "components/FormInputs/InputField";
import { CalendarBox } from "components/FormInputs/CalendarBox";

export default function FlightDates({
  errors,
  setValue,
  control,
  getValues,
}: any) {
  return (
    <div className="flex flex-row gap-4">
      {/* Flight Fare Expiry */}
      <CalendarBox
        control={control}
        setValue={setValue}
        errors={errors}
        fieldName="fareExpiry"
        label="Fare Expiry"
        defaultValue={getValues(`fareExpiry`) || ""}
      />
      {/* Flight Invoice Due Date */}
      <CalendarBox
        control={control}
        setValue={setValue}
        errors={errors}
        fieldName="invoiceDueDate"
        label="Invoice Due Date"
        defaultValue={getValues(`invoiceDueDate`) || ""}
      />
    </div>
  );
}
