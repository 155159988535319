// Import react router v6
import { Routes, Route } from "react-router-dom";
// Import protected routes
import { ProtectedRoute } from "./ProtectedRoute";
// Import Pages
import ProfilePage from "../pages/consultant/ProfilePage";
import Dashboard from "../pages/consultant/Dashboard";
import InquiriesPage from "../pages/consultant/InquiriesPage";
import MyComission from "../pages/consultant/MyComission";
import Inquiry from "../pages/consultant/Inquiry";
import InboxPage from "~/pages/consultant/Inbox";
// import InboxPage from "~/pages/admin/Inbox";

export default function ConsultantRoutes() {
  return (
    <Routes>
      <Route path="/consultant" element={<ProtectedRoute role="consultant" />}>
        <Route index element={<Dashboard />} />
        <Route path="profile" element={<ProfilePage />} />
        <Route path="inquiries" element={<InquiriesPage />} />
        <Route path="inbox" element={<InboxPage />} />
        <Route path="inquiry/:inquiry_id" element={<Inquiry />} />
        <Route path="commission" element={<MyComission />} />
      </Route>
    </Routes>
  );
}
