import React from "react";
import InputField from "~/components/FormInputs/InputField";

export default function CostRow({
  passenger,
  register,
  errors,
  setValue,
  index,
}: any) {
  setValue(`suggestion_cost.${index}.passenger_id`, passenger.id);
  return (
    <div className="flex flex-row gap-4">
      <div className="w-[60%] pt-[30px]">
        {passenger.first_name + " " + passenger.last_name} (
        {passenger.type.toUpperCase()})
      </div>
      {/* Flight Offer Price */}
      <InputField
        label="Cost Price"
        register={register}
        errors={errors}
        type="number"
        placeHolder="Cost Price"
        fieldName={`suggestion_cost.${index}.cost_price`}
        required={true}
      />
      {/* Flight Cost Price */}
      <InputField
        label="Tax Amount"
        register={register}
        errors={errors}
        type="number"
        placeHolder="Tax"
        fieldName={`suggestion_cost.${index}.tax_price`}
        required={true}
      />
      {/* Flight Cost Price */}
      <InputField
        label="Sale Price"
        register={register}
        errors={errors}
        type="number"
        placeHolder="Sale Price"
        fieldName={`suggestion_cost.${index}.sale_price`}
        required={true}
      />
    </div>
  );
}
