"use client";
import React, { useState } from "react";
import SidePopupButton from "./components/SidePopupButton";
import SidePopupBar from "./components/SidePopupBar";
import { SidePopupProps } from "./types";
import commonStore from "~/store/commonStore/commonStore";

const SidePopup = ({
  component: Component,
  buttonIcon,
  text,
  className,
  width,
}: SidePopupProps) => {
  // const { sidePopup, setSidePopup } = commonStore();
  const [sidePopup, setSidePopup] = useState(false);
  return (
    <>
      <SidePopupButton
        className={className}
        icon={buttonIcon}
        text={text}
        setShow={setSidePopup}
      />
      {sidePopup && (
        <SidePopupBar setShow={setSidePopup} width={width}>
          {Component && React.cloneElement(Component, { setSidePopup })}
        </SidePopupBar>
        // <SidePopupBar
        //   component={<Component setClose={setSidePopup} />}
        //   setShow={setSidePopup}
        // />
      )}
    </>
  );
};

SidePopup.defaultProps = {
  className: "",
  buttonIcon: "",
  text: "",
  width: "600px",
};

export default SidePopup;
