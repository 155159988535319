// Import react router v6
import { Routes, Route } from "react-router-dom";
// Import protected routes
import { ProtectedRoute } from "./ProtectedRoute";
// Import pages
import ProfilePage from "../pages/user/ProfilePage";
import Dashboard from "../pages/user/Dashboard";
import Bookings from "../pages/user/Bookings";
import Payments from "../pages/user/Payments";
import Inquiry from "pages/user/Inquiry";

export default function UserRoutes() {
  return (
    <Routes>
      <Route path="/user" element={<ProtectedRoute role="user" />}>
        <Route index element={<Dashboard />} />
        <Route path="profile" element={<ProfilePage />} />
        <Route path="bookings" element={<Bookings />} />
        <Route path="payments" element={<Payments />} />
        <Route path="inquiry/:inquiry_id" element={<Inquiry />} />
      </Route>
    </Routes>
  );
}
