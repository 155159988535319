import { Realtime } from "ably/promises";
import travelHouseStore from "store/travelHouse/travelHouseStore";
import { useApolloClient } from "@apollo/client";
const ably = new Realtime(
  "RjlN_g.uxcIfA:rEZUNjXzLac9hqi4G05eAOw0mhD2tZJNcK4zkO2zv5E"
);

const channel = ably.channels.get("getUpdate");

export default function useRefetch() {
  const { travelHouse }: any = travelHouseStore();
  const client = useApolloClient();
  const refetch = async () => {
    await channel.publish(travelHouse.id, {
      created_at: new Date(),
    });
    const res = await client.resetStore();
    console.log("Ably Updated");
  };
  return refetch;
}
