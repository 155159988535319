import { useApolloClient, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import InputField from "~/components/FormInputs/InputField";
import PhoneBox from "~/components/FormInputs/PhoneBox";
import { toast } from "react-toastify";
import LocationBox from "~/components/FormInputs/LocationBox";
import AirlineBox from "~/components/FormInputs/AirlineBox";
import { ALLOWED_IMAGE_FORMATS } from "~/config/constants";
import { useFileUpload } from "@nhost/react";
import { UPDATE_DESTINATION } from "./graphql/Mutation";

const DestinationDetail = ({ page }: any) => {
  const [updateDestination] = useMutation(UPDATE_DESTINATION);
  const { upload: uploadLogo, progress: logoUploadProgress } = useFileUpload();
  const client = useApolloClient();
  const [logoFile, setLogoFile] = useState<any | null>(null);

  const [featuredFile, setFeatureFile] = useState<any | null>(null);
  const [featured, setFeatured] = useState<any | null>(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    setValue,
    reset,
  } = useForm();

  const onSubmit = async (inpData: any) => {
    console.log("xwt update destination ===>", inpData);

    if (logoFile) {
      const logoUploadRes = await uploadLogo(logoFile);
      inpData["image"] = logoUploadRes?.id || null;
    } else {
      inpData["image"] = page?.featured_image;
    }

    if (featuredFile) {
      const featuredFileRes = await uploadLogo(featuredFile);
      inpData["featureImage"] = featuredFileRes?.id || null;
    } else {
      inpData["featureImage"] = page?.featured_destination_image;
    }

    let originName;
    if (inpData?.origin?.city_name?.toLowerCase()) {
      originName = inpData?.origin?.city_name?.toLowerCase();
    } else {
      originName = page?.origin_name.toLowerCase();
    }

    let destinationName;
    if (inpData?.destination?.city_name?.toLowerCase()) {
      destinationName = inpData?.destination?.city_name?.toLowerCase();
    } else {
      destinationName = page?.destination_name.toLowerCase();
    }

    const res = await updateDestination({
      variables: {
        id: page?.id,
        data: {
          origin_name: inpData?.origin?.name,
          destination_name: inpData?.destination?.name,
          origin_iata: inpData?.origin?.iata_code,
          destination_iata: inpData?.destination?.iata_code,
          featured_image: inpData?.image,
          show_in_footer: inpData?.show_in_footer,
          airline_iata: inpData?.airline.iata,
          base_price: inpData?.base_price,
          slug: `${originName.replaceAll(
            " ",
            "-"
          )}-to-${destinationName?.replaceAll(" ", "-")}`,
          show_in_home: inpData?.show_in_home,
          show_featured_destination: inpData?.show_featured_destination,
          featured_destination_image: inpData?.featureImage,
        },
      },
    });
    console.log("res", res);
    if (res?.data?.update_brd_flights_pages?.affected_rows > 0) {
      toast.success("Destination added successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      await client.refetchQueries({
        include: "all",
      });
    }
  };

  const handleLogoUpload = async (event: any) => {
    const file = event.target.files[0];

    if (
      !ALLOWED_IMAGE_FORMATS.includes(file.type.split("/").at(1).toLowerCase())
    ) {
      toast.error("Invalid File format");
    } else {
      console.log("etc file selected");
      setLogoFile({ file });
    }
  };

  const handleFeatureUpload = async (event: any) => {
    const file = event.target.files[0];
    if (
      !ALLOWED_IMAGE_FORMATS.includes(file.type.split("/").at(1).toLowerCase())
    ) {
      toast.error("Invalid File format");
    } else {
      console.log("etc file selected");
      setFeatureFile({ file });
    }
  };

  return (
    <div className="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800">
      <h3 className="mb-4 text-xl font-semibold dark:text-white">
        Destination Base Settings
      </h3>
      <form onSubmit={handleSubmit(onSubmit)} action="#">
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6 sm:col-span-3">
            <LocationBox
              control={control}
              errors={errors}
              setValue={setValue}
              fieldName="origin"
              label="Departure"
              inputClasses="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 py-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              required={true}
              defaultValue={page.origin_iata}
              placeholder="Select departure"
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <LocationBox
              control={control}
              errors={errors}
              setValue={setValue}
              fieldName="destination"
              label="Arrival"
              defaultValue={page.destination_iata}
              inputClasses="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 py-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              required={true}
              placeholder="Select arrival"
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <AirlineBox
              control={control}
              errors={errors}
              setValue={setValue}
              fieldName={`airline`}
              label="Airline"
              placeholder="Choose your Airline"
              defaultValue={page.airline_iata}
              required={true}
              inputClasses="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 py-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <InputField
              register={register}
              errors={errors}
              fieldName="base_price"
              type="number"
              inputClasses="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 py-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              label="Base Price (Starting from)"
              placeHolder="Enter Base Price"
              defaultValue={page.base_price}
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <div className="col-span-6 sm:col-span-3 overflow-hidden">
              <label>Background image for destination hero section</label>
              <input
                type="file"
                onChange={handleLogoUpload}
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              />
              {logoUploadProgress && (
                <p className="font-thin">
                  Image Upload Progress: {logoUploadProgress}%
                </p>
              )}
              <p
                className="mt-1 text-sm text-gray-500 dark:text-gray-300"
                id="light_logo_help"
              >
                SVG, PNG, JPG or GIF (MAX. 800x400px).
              </p>
            </div>
          </div>
          <div className="col-span-6 sm:col-span-3">
            <span className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Placements
            </span>
            <ul className="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
              <li className="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                <div className="flex items-center pl-3">
                  <input
                    id="vue-checkbox-list"
                    type="checkbox"
                    value=""
                    defaultChecked={page.show_in_home ? true : false}
                    {...register("show_in_home")}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                  />
                  <label
                    htmlFor="vue-checkbox-list"
                    className="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Home page hot destinations
                  </label>
                </div>
              </li>
              <li className="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                <div className="flex items-center pl-3">
                  <input
                    id="react-checkbox-list"
                    type="checkbox"
                    {...register("show_in_footer")}
                    value=""
                    defaultChecked={page.show_in_footer ? true : false}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                  />
                  <label
                    htmlFor="react-checkbox-list"
                    className="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Footer flight links
                  </label>
                </div>
              </li>
              <li className="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                <div className="flex items-center pl-3">
                  <input
                    id="react-checkbox-list-3"
                    type="checkbox"
                    onClick={() => {
                      setFeatured(!featured);
                    }}
                    {...register("show_featured_destination")}
                    defaultChecked={
                      page.show_featured_destination ? true : false
                    }
                    value=""
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                  />
                  <label
                    htmlFor="react-checkbox-list-3"
                    className="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Featured Destination
                  </label>
                </div>
              </li>
            </ul>
          </div>
          {featured && (
            <div className="col-span-6 sm:col-span-3">
              <div className="col-span-6 sm:col-span-3 overflow-hidden">
                <label className="mb-2 block">Featured Image</label>
                <input
                  type="file"
                  onChange={handleFeatureUpload}
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                />
                {logoUploadProgress && (
                  <p className="font-thin">
                    Image Upload Progress: {logoUploadProgress}%
                  </p>
                )}
                <p
                  className="mt-1 text-sm text-gray-500 dark:text-gray-300"
                  id="light_logo_help"
                >
                  SVG, PNG, JPG or GIF (MAX. 800x400px).
                </p>
              </div>
            </div>
          )}
          <div className="col-span-6 sm:col-full">
            <button
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
              type="submit"
            >
              Save Destination
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default DestinationDetail;
