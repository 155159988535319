import { gql } from "@apollo/client";

export const ACC_LIST = gql`
  query GetAccList($thp_id: uuid!) {
    acc_list(where: { thp_id: { _eq: $thp_id } }) {
      id
      thp_id
      type
      name
      created_at
      updated_at
    }
  }
`;

export const GET_BRANDS = gql`
  query GetBrandsQuery($thp_id: uuid) {
    brd_list(where: { thp_id: { _eq: $thp_id } }) {
      created_at
      domain
      id
      name
      subdomain
      thp_id
      updated_at
      brd_details {
        logo
        primary_color
        secondary_color
        theme_id
        updated_at
        id
        created_at
      }
    }
  }
`;

export const GET_BRANDS_PAYMENTS_METHODS = gql`
  query GetPaymentMethods($brd_id: uuid!) {
    acc_payment_methods(
      where: { brd_id: { _eq: $brd_id } }
      order_by: { brd_id: asc }
    ) {
      account_no
      name
      account_title
      stripe_pub
      stripe_secret
      default
      swift_code
      type
      created_at
      updated_at
      id
      thp_id
      brd_id
      brd_list {
        name
      }
    }
  }
`;

export const GET_USER = gql`
  query GetUsers($role: String, $thp_id: uuid!) {
    users(
      where: {
        brd_users: {
          thp_id: { _eq: $thp_id }
          users: { defaultRole: { _eq: $role } }
        }
      }
    ) {
      email
      id
      displayName
      phoneNumber
      metadata
      defaultRole
      disabled
      brd_users {
        user_id
        brd_id
        thp_id
      }
    }
  }
`;
