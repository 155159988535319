import {
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import { useClickOutside } from "@mantine/hooks";
import {
  useUserData,
  useUserDefaultRole,
  useUserDisplayName,
  useUserEmail,
  useUserId,
} from "@nhost/react";
import moment from "moment";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import CheckMark from "~/assets/svg/CheckMark";
import { SEND_EMAIL } from "~/config/Mutation";
import useNotifications from "~/hooks/useNotifications";
import {
  GET_EMAILS,
  GET_TRAVEL_HOUSE_BY_ID,
} from "~/modules/InboxModule/segments/Inbox/graphql/Query";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import { getTimeReadableFormat } from "~/utils/GetTimeReadable";
import { EDIT_INQUIRY } from "./graphql/Mutation";
import { CustomerTemplates } from "~/utils/TemplatesAll";
import { sendGenericEmail } from "~/utils/sendEmail";
import { toast } from "react-toastify";
import { FaEye } from "react-icons/fa";
import useEmail from "~/hooks/emails/useEmail";
import { getCityName } from "~/utils/utilsFunctions";

export default function InquiryRow({ inquiry }: any) {
  const [opened, setOpened] = useState(false);
  const ref = useClickOutside(() => setOpened(false));
  const emailSend = useEmail();

  const userDefaultRole = useUserDefaultRole();
  const userDisplayName = useUserDisplayName();
  const userEmail = useUserEmail();

  const userId = useUserId();

  const { travelHouse }: any = travelHouseStore();
  const [sendEmail] = useMutation(SEND_EMAIL);
  const userData = useUserData();

  const { loading: travelHouseLoading, data: travelHouseData } = useQuery(
    GET_TRAVEL_HOUSE_BY_ID,
    {
      variables: { id: travelHouse?.id },
      fetchPolicy: "network-only",
    }
  );
  const [isloading, setIsloading] = useState(false);
  const client = useApolloClient();
  const alert: any = useNotifications();
  // console.log("inquiryTest", inquiry);
  const [editInquiry] = useMutation(EDIT_INQUIRY);

  // Navigation hooks
  const navigate = useNavigate();

  // Accept Inquiry Button
  const accept = async () => {
    console.log("userByUserId", inquiry?.users);
    setIsloading(true);
    try {
      // Create passenger function call
      const res = await editInquiry({
        variables: { id: inquiry.id, picked_by_id: userId },
      });
      if (res.data?.update_inq_list?.returning?.length > 0) {
        const variables: any = {
          inquiryNo: inquiry?.inquiry_no,
          brandName: inquiry?.brd_list?.name,
          userFirstName: inquiry?.users?.displayName,
          userLastName: "",
          consultantEmail: userData?.metadata?.alias || userData?.email,
          consultantName: userDisplayName,
        };

        // console.log("inquiryPicked", inquiry);
        await emailSend(1, inquiry.brd_id, inquiry.users?.email, variables);

        toast.success("Picked Successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        await alert.newNotification(
          inquiry?.users?.id,
          `Your Consultant Picked your Inquiry `,
          "",
          ``
        );

        await client.resetStore();

        navigate(`../inquiry/${inquiry.inquiry_no}`);
      }

      // setIsloading(false); // change button loading state
      // setIsloading(false);
    } catch (e: any) {
      // setIsloading(false);
      console.error("error", e);
      toast.error(e, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  return (
    <tr className="hover:bg-gray-100 dark:hover:bg-gray-700">
      <td className="w-4 p-4">
        <div className="flex items-center">
          <input
            id="checkbox-623232"
            aria-describedby="checkbox-1"
            type="checkbox"
            className="w-4 h-4 border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
          />
          <label htmlFor="checkbox-623232" className="sr-only">
            checkbox
          </label>
        </div>
      </td>
      <td className="p-4 text-sm font-normal text-gray-500 whitespace-nowrap dark:text-gray-400">
        <div className="text-base font-normal text-gray-900 dark:text-white">
          #{inquiry.inquiry_no}
          <pre className="text-sm font-semibold">
            {getTimeReadableFormat(inquiry?.created_at)}
          </pre>
        </div>
      </td>
      {userDefaultRole !== "user" && (
        <td className="items-center p-4 space-x-6 whitespace-nowrap">
          <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
            <div className="text-base font-semibold text-gray-900 dark:text-white capitalize">
              {inquiry?.users?.displayName}
            </div>
            <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
              {inquiry?.users?.email}
            </div>
            <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
              {inquiry?.users?.phoneNumber || inquiry?.users?.metadata?.phone}
            </div>
          </div>
        </td>
      )}

      {userDefaultRole !== "consultant" && (
        <td className="items-center p-4 space-x-6 whitespace-nowrap">
          <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
            <div className="text-base font-semibold text-gray-900 dark:text-white capitalize">
              {inquiry?.picked_user?.displayName}
            </div>
            <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
              {inquiry?.brd_list?.name}
            </div>
            <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
              {inquiry?.picked_user?.phoneNumber ||
                inquiry?.picked_user?.metadata?.phone}
            </div>
          </div>
        </td>
      )}

      <td className="p-4 text-sm font-normal text-gray-500 whitespace-nowrap dark:text-gray-400">
        <div className="text-base text-center font-normal text-gray-900 dark:text-white">
          {inquiry.from}
          <pre className="text-sm">({getCityName(inquiry.from)})</pre>
        </div>
      </td>
      <td className="p-4 text-base font-medium text-gray-900 whitespace-nowrap dark:text-white">
        <div className="text-base text-center font-normal text-gray-900 dark:text-white">
          {inquiry.to}
          <pre className="text-sm">({getCityName(inquiry.to)})</pre>
        </div>
      </td>
      <td className="p-4 text-base font-medium text-gray-900 whitespace-nowrap dark:text-white">
        <div className="text-base font-normal text-gray-900 dark:text-white">
          {moment(inquiry.departure_datetime).format("DD-MM-YYYY")}
          <br />
          {inquiry.trip_type === "oneWay" ? (
            <span>-</span>
          ) : (
            moment(inquiry.arrival_datetime).format("DD-MM-YYYY")
          )}
        </div>
      </td>
      <td className="p-4 text-sm font-normal text-gray-500 whitespace-nowrap dark:text-gray-400">
        <div className="text-base text-gray-900 dark:text-white capitalize">
          {inquiry?.status === "pending" ? "Unassign" : inquiry?.status}
        </div>
      </td>
      <td className="p-4 text-base font-medium text-gray-900 whitespace-nowrap dark:text-white">
        <div className="text-base font-normal text-gray-900 dark:text-white">
          {inquiry.inq_passengers.length} Passengers
        </div>
        <div className="text-base text-gray-900 dark:text-white">
          {inquiry.approx_budget ? `£${inquiry.approx_budget}` : ""}
        </div>
      </td>
      <td className="p-4 space-x-2 whitespace-nowrap">
        {inquiry.status === "pending" && userDefaultRole === "consultant" ? (
          <></>
        ) : (
          <Link
            to={`../inquiry/${inquiry.inquiry_no}`}
            className="inline-flex items-center p-2 text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          >
            <FaEye size="25px" />
          </Link>
        )}

        {/* Detail dropdown button */}
        {/* <button
          className="inline-flex items-center p-2 text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          type="button"
          ref={ref}
          onClick={() => setOpened(true)}
        >
          Detail
        </button> */}

        {inquiry.status === "pending" && (
          <button
            type="button"
            onClick={() => {
              accept();
            }}
            className="text-white  bg-blue-700 hover:bg-blue-800 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center mr-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 dark:hover:bg-blue-500"
          >
            <CheckMark />
          </button>
        )}

        {opened && (
          <div
            ref={ref}
            className="absolute z-10 right-0 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600"
          >
            <ul className="py-2 text-sm text-gray-700 dark:text-gray-200">
              <li>
                <Link
                  to={`../inquiry/${inquiry.inquiry_no}`}
                  className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                >
                  Details
                </Link>
              </li>
              {/* <li>
                <a
                  href="#"
                  className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                >
                  Accept
                </a>
              </li> */}
            </ul>
          </div>
        )}
      </td>
    </tr>
  );
}
