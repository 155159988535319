import React from "react";
import FeatureFormCard from "./components/FeatureFormCard";
import InputField from "~/components/FormInputs/InputField";
import { useForm } from "react-hook-form";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { GET_DEFAULT_SETTINGS } from "./graphql/Query";
import { useParams } from "react-router-dom";
import {
  INSERT_DEFAULT_SETTING,
  INSERT_FEATURES,
  UPDATED_FEATURES,
  UPDATE_DEFAULT_SETTING,
} from "./graphql/Mutation";
import { toast } from "react-toastify";
import { useFileUpload } from "@nhost/react";

const EditFeatureSection = ({ page }: any) => {
  const { brd_id } = useParams();
  const { upload, progress: logoUploadProgress } = useFileUpload();
  const { loading, data: headingsData } = useQuery(GET_DEFAULT_SETTINGS, {
    variables: { id: brd_id },
  });
  const [updateDefaultSettings] = useMutation(UPDATE_DEFAULT_SETTING);
  const [insertSettings] = useMutation(INSERT_DEFAULT_SETTING);
  const [updateFeature] = useMutation(UPDATED_FEATURES);
  const [insertFeature] = useMutation(INSERT_FEATURES);
  const client = useApolloClient();
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();
  if (loading) return <p>Loading...</p>;
  const settings = headingsData?.brd_default_setting?.at(0);
  const features = page?.brd_list?.brd_features;
  const onSubmit = async (inpData: any) => {
    // console.log("xwt ipnData", inpData);
    inpData?.features?.map(async (ft: any) => {
      if (ft?.id) {
        if (ft?.title !== "") {
          const finalObj: any = {
            title: ft?.title,
            content: ft?.content,
          };
          if (ft?.icon?.length > 0) {
            const iconRes = await upload(ft?.icon[0]);
            finalObj["icon_url"] = iconRes?.id || null;
          }
          const res = await updateFeature({
            variables: {
              id: { id: ft.id },
              ftData: finalObj,
            },
          });
        }
      } else {
        if (ft?.title) {
          const finalObj: any = {
            title: ft?.title,
            content: ft?.content,
            brd_id: brd_id,
          };
          if (ft?.icon?.length > 0) {
            console.log("xwt icno upload ===>", ft?.icon[0]);
            const iconRes = await upload(ft?.icon[0]);
            console.log("xwt icno res ===>", iconRes);
            finalObj["icon_url"] = iconRes?.id || null;
          }
          const res = await insertFeature({
            variables: {
              data: finalObj,
            },
          });
        }
      }
    });
    // console.log("xwt settings id", settings.id, settings?.id);
    // return 0;
    if (settings?.id) {
      const res = await updateDefaultSettings({
        variables: {
          id: { id: settings?.id },
          data: {
            features_heading: inpData?.features_heading,
            features_sub_heading: inpData?.features_sub_heading,
          },
        },
      });
      if (res?.data?.update_brd_default_setting_by_pk?.id) {
        toast.success("Features data updated successfully");
        await client.refetchQueries({
          include: "all",
        });
      }
    } else {
      const res = await insertSettings({
        variables: {
          data: {
            features_heading: inpData?.features_heading,
            features_sub_heading: inpData?.features_sub_heading,
            brd_id: brd_id,
          },
        },
      });
      console.log("xwt insert res ===>", res);
      if (res?.data?.insert_brd_default_setting_one?.id) {
        toast.success("Features data updated successfully");
        await client.refetchQueries({
          include: "all",
        });
      }
    }
  };
  return (
    <div className="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800">
      <h3 className="mb-4 text-xl font-semibold dark:text-white">
        Our Values Section
      </h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6 sm:col-span-3">
            <InputField
              register={register}
              errors={errors}
              fieldName="features_heading"
              inputClasses="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 py-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              label="Heading"
              placeHolder="Heading"
              defaultValue={settings?.features_heading}
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <InputField
              register={register}
              errors={errors}
              fieldName="features_sub_heading"
              inputClasses="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 py-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              label="Sub Heading"
              placeHolder="Features sub heading"
              defaultValue={settings?.features_sub_heading}
            />
          </div>
          <div className="col-span-full flex space-x-9">
            <FeatureFormCard
              key={0}
              ft={features[0]}
              index={0}
              register={register}
              errors={errors}
              setValue={setValue}
            />
            <FeatureFormCard
              key={1}
              ft={features[1]}
              index={1}
              register={register}
              errors={errors}
              setValue={setValue}
            />
            <FeatureFormCard
              key={2}
              ft={features[2]}
              index={2}
              register={register}
              errors={errors}
              setValue={setValue}
            />
            <FeatureFormCard
              key={3}
              ft={features[3]}
              index={3}
              register={register}
              errors={errors}
              setValue={setValue}
            />
          </div>

          <div className="col-span-6 sm:col-full">
            <button
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
              type="submit"
            >
              Save Value Section
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditFeatureSection;
