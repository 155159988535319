import { gql } from "@apollo/client";

export const EDIT_USER = gql`
  mutation EditUser(
    $id: uuid!
    $newUser: users_set_input!
    $newUserBrands: [brd_users_insert_input!]!
  ) {
    updateUsers(where: { id: { _eq: $id } }, _set: $newUser) {
      affected_rows
    }

    delete_brd_users(where: { user_id: { _eq: $id } }) {
      affected_rows
    }

    # Insert new user_brands records
    insert_brd_users(objects: $newUserBrands) {
      affected_rows
    }
  }
`;
