import { gql } from "@apollo/client";

export const GET_TRANSACTIONS = gql`
  query GetTransactions($thp_id: uuid!) {
    inq_transection(
      where: {
        status: { _eq: "pending" }
        inq_list: { thp_id: { _eq: $thp_id } }
      }
      order_by: { created_at: desc }
    ) {
      amount
      receipt
      ref_no
      status
      created_at
      updated_at
      id
      inq_id
      payment_method_id
      inq_list {
        inquiry_no
        users {
          id
          displayName
          email
        }
        picked_user {
          id
          displayName
          email
        }
        id
        picked_by
        user_id
        brd_id
        brd_list {
          name
          domain
        }
      }
      acc_payment_method {
        id
        name
      }
    }
  }
`;
