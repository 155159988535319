import React, { useState } from "react";
import Allinquires from "./components/Allinquires";
import InquiryFilterButtons from "~/modules/InquiriesModule/components/InquiryTable/components/InquiryFilterButtons";
import Assigned from "./components/Assigned";
import InProcess from "./components/InProcess";
import Completed from "./components/Completed";
import Unassign from "./components/Unassign";
import { useForm } from "react-hook-form";
import Search from "./components/Search";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import { useUserId } from "@nhost/react";
import { GET_ALL_BRD } from "./graphql/Query";
import { useQuery } from "@apollo/client";

const Inquiries = () => {
  const [activeTab, setActiveTab] = useState<String>("Unassign");
  const [search, setSearch] = useState<String>("");
  const [all, setAll] = useState<any>(null);
  const [unAssign, setUnAssign] = useState<any>(null);
  const [assigned, setAssigned] = useState<any>(null);
  const [inprocess, setInprocess] = useState<any>(null);
  const [completed, setCompleted] = useState<any>(null);
  const { travelHouse }: any = travelHouseStore();
  const userId = useUserId();

  // get data based on query
  const { loading, data, error } = useQuery(GET_ALL_BRD, {
    variables: {
      thp_id: travelHouse.id,
      user_id: userId,
    },
  });
  //  Use react hook
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    setValue,
  } = useForm();

  // Save Passsenger function
  const onSubmit = async (data: any) => {
    console.log("data", data.inquiry_id);
    setSearch(data.inquiry_id);
    setActiveTab("Search");
  };
  // return loading while loading data
  if (loading) return <div>Loading...</div>;
  // Return error if get gql error
  if (error) return <div>Error in the query {error.message}</div>;

  let brd_ids: any = [];
  data.brd_users.forEach((brd: any) => {
    brd_ids.push(brd.brd_id);
  });
  console.log("brd_ids", brd_ids);
  return (
    <div className="bg-gray-50 dark:bg-gray-800">
      <div
        id="main-content"
        className="relative w-full h-full overflow-y-auto bg-gray-50 dark:bg-gray-900"
      >
        <div className="p-4 bg-white block sm:flex items-center justify-between border-b border-gray-200 lg:mt-1.5 dark:bg-gray-800 dark:border-gray-700">
          <div className="w-full mb-1">
            <div className="items-center justify-between block sm:flex md:divide-x md:divide-gray-100 dark:divide-gray-700">
              <div className="flex items-center mb-4 sm:mb-0">
                <form className="sm:pr-3" onSubmit={handleSubmit(onSubmit)}>
                  <label htmlFor="inquiries-search" className="sr-only">
                    Search
                  </label>
                  <div className="relative w-48 mt-1 sm:w-64 xl:w-96">
                    <input
                      type="text"
                      {...register("inquiry_id", {
                        required: true ? `* This is required` : false,
                      })}
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Enter Inquiry #Id"
                    />
                  </div>
                </form>
                <InquiryFilterButtons
                  stats={{ all, unAssign, assigned, completed, inprocess }}
                  setActiveTab={setActiveTab}
                  activeTab={activeTab}
                />
              </div>
            </div>
          </div>
        </div>
        <Allinquires activeTab={activeTab} setAll={setAll} />
        <Search activeTab={activeTab} inquiry_no={search} />
        <Assigned activeTab={activeTab} setAssigned={setAssigned} />
        <Unassign activeTab={activeTab} setUnAssign={setUnAssign} />
        <InProcess activeTab={activeTab} setInprocess={setInprocess} />
        <Completed activeTab={activeTab} setCompleted={setCompleted} />
      </div>
    </div>
  );
};

export default Inquiries;
