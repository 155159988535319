import { useForm } from "react-hook-form";
import { useApolloClient, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import moment from "moment";

// Import Data
import {
  PassengersType,
  PassengersGenders,
} from "../../../../data/InitalDefaultValue";

// Import Components
import InputField from "components/FormInputs/InputField";
import CountryBox from "components/FormInputs/CountryBox";
import { CalendarBox } from "components/FormInputs/CalendarBox";
import SelectBox from "components/FormInputs/SelectBox";

// Import GraphQl
import { EDIT_PASSENGER } from "../../../../graphql/Mutation";
import useNotifications from "hooks/useNotifications";
import { useUserDefaultRole } from "@nhost/react";

const PassengerEdit = ({
  inquiry_id,
  passenger,
  hideForm,
  user_id,
  picked_by,
}: any) => {
  const client = useApolloClient();
  const [editPassenger] = useMutation(EDIT_PASSENGER);
  const alert = useNotifications();
  const userRole = useUserDefaultRole();
  //  Use react hook
  const selectedType = PassengersType.filter(
    (ps: any) => ps.value === passenger.type
  );

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      type: passenger.type,
      gender: passenger.gender,
      first_name: passenger.first_name,
      last_name: passenger.last_name,
      dob: passenger.dob,
      nationality: passenger.nationality,
      passport_no: passenger.passport_no,
      passport_expiry: passenger.passport_expiry,
    },
  });

  // Save Passsenger function
  const onSubmit = async (data: any) => {
    data["id"] = passenger.id;
    data["nationality"] = data?.nationality?.code || "";
    data["passport_expiry"] =
      moment(data?.passport_expiry).format("YYYY-MM-DD HH:mm:ss") || "";
    data["dob"] = moment(data?.dob).format("YYYY-MM-DD HH:mm:ss") || "";

    try {
      // Create passenger function call
      const res = await editPassenger({
        variables: { ...data },
      });
      if (res.data?.update_inq_passengers?.returning?.length > 0) {
        toast.success("Passenger added successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });

        if (userRole === "user") {
          await alert.newNotification(
            picked_by,
            `Your customer has edit passenger. Please check your portal`,
            "",
            ``
          );
        } else {
          await alert.newNotification(
            user_id,
            `Your consultant has edit passenger. Please check your portal`,
            "",
            ``
          );
        }

        await client.resetStore();
      }
      //   setIsloading(false); // change button loading state
      hideForm(false); // hide form modal
    } catch (e) {
      hideForm(false);
      console.error("error", e);
    }
  };

  function isValidDateFormat(dateString: any) {
    const date = new Date(dateString);
    return !isNaN(date.getTime());
  }

  return (
    <>
      <div className="self-stretch flex-1 min-h-[350px] rounded bg-white-100 flex flex-col p-[25px] items-center justify-between gap-[20px] text-left text-mini text-darkslategray border-[1px] border-solid border-lightsteelblue">
        <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
          <div className="self-stretch flex gap-5 flex-row pt-0  justify-between px-0 pb-2.5 items-center  border-b-[1px] border-solid border-lightsteelblue">
            <SelectBox
              control={control}
              fieldName={"type"}
              label="Type"
              options={PassengersType}
              selectedOption={
                PassengersType.filter(
                  (ps: any) => ps.value === passenger.type
                )[0]
              }
              inputClasses="border rounded border-gray-200 pl-3"
            />
            <SelectBox
              control={control}
              fieldName={"gender"}
              label="Gender"
              options={PassengersGenders}
              selectedOption={
                PassengersGenders.filter(
                  (ps: any) => ps.value === passenger.gender
                )[0]
              }
              inputClasses="border rounded border-gray-200 pl-3"
            />
          </div>
          <div className="self-stretch flex flex-row pt-0 px-0 pb-2.5 items-center justify-start border-b-[1px] border-solid border-lightsteelblue">
            <div className="flex-1 rounded-8xs flex flex-col items-start justify-start">
              <div className="self-stretch relative leading-[130%]">
                <div className="grid grid-cols-1 2xl:grid-cols-2 2xl:gap-5 items-center w-full">
                  <InputField
                    label="First Name"
                    register={register}
                    type="text"
                    errors={errors}
                    placeHolder="First Name"
                    fieldName={`first_name`}
                    defaultValue={getValues("first_name")}
                  />
                  <InputField
                    label="Last Name"
                    register={register}
                    type="text"
                    errors={errors}
                    placeHolder="Last Name"
                    fieldName={`last_name`}
                    defaultValue={getValues("last_name")}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-row pt-0  justify-between px-0 pb-2.5 items-center  border-b-[1px] border-solid border-lightsteelblue">
            <div className="grid grid-cols-1 2xl:grid-cols-2 2xl:gap-5 items-center w-full">
              <CalendarBox
                control={control}
                setValue={setValue}
                errors={errors}
                fieldName="dob"
                label="Date of Birth"
                defaultValue={
                  isValidDateFormat(getValues("dob")) ? getValues("dob") : null
                }
              />

              <CountryBox
                control={control}
                setValue={setValue}
                errors={errors}
                fieldName="nationality"
                label="Nationality"
                defaultValue={getValues("nationality")}
              />
            </div>
          </div>
          <div className="self-stretch flex flex-row items-center justify-between">
            <div className="grid grid-cols-1 2xl:grid-cols-2 2xl:gap-5 items-center w-full">
              <InputField
                label="Passport No."
                register={register}
                type="text"
                errors={errors}
                placeHolder="Passport No."
                fieldName={`passport_no`}
                defaultValue={getValues("passport_no")}
              />
              <CalendarBox
                control={control}
                setValue={setValue}
                errors={errors}
                fieldName="passport_expiry"
                label="Passport Expiry"
                defaultValue={
                  isValidDateFormat(getValues("passport_expiry"))
                    ? getValues("passport_expiry")
                    : null
                }
              />
            </div>
          </div>
          <div className="flex gap-2 w-full border-t-[1px] border-solid border-lightsteelblu mt-2 pt-2">
            <button
              className="w-full button btn bg-red-500 text-white  rounded-md p-2"
              onClick={() => {
                hideForm(false);
              }}
            >
              Close
            </button>
            <button
              className="w-full btn bg-green-500 text-white rounded-md p-2"
              type="submit"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default PassengerEdit;
