// Import react router v6
import { Routes, Route } from "react-router-dom";
// Import protected routes
import { ProtectedRoute } from "./ProtectedRoute";
// Import pages
import Dashboard from "../pages/super-admin/Dashboard";
import ProfilePage from "~/pages/super-admin/ProfilePage";
import TravelHouses from "~/pages/super-admin/TravelHouses";

export default function SuperAdminRoutes() {
  return (
    <Routes>
      <Route
        path="/super-admin"
        element={<ProtectedRoute role="super-admin" />}
      >
        <Route index element={<Dashboard />} />
        <Route path="profile" element={<ProfilePage />} />
        <Route path="travel-houses" element={<TravelHouses />} />
      </Route>
    </Routes>
  );
}
