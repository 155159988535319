import { useUserDefaultRole } from "@nhost/react";
import React from "react";

export default function InquiresHead() {
  const role = useUserDefaultRole();
  return (
    <thead className="bg-gray-100 dark:bg-gray-700">
      <tr>
        <th scope="col" className="p-4">
          <div className="flex items-center">
            <input
              id="checkbox-all"
              aria-describedby="checkbox-1"
              type="checkbox"
              className="w-4 h-4 border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
            />
            <label htmlFor="checkbox-all" className="sr-only">
              checkbox
            </label>
          </div>
        </th>
        <th
          scope="col"
          className="p-4 text-xs font-medium text-left text-gray-500 uppercase dark:text-gray-400"
        >
          ID & <br />
          Received Time
        </th>
        {role !== "user" && (
          <th
            scope="col"
            className="p-4 text-xs font-medium text-left text-gray-500 uppercase dark:text-gray-400"
          >
            User
          </th>
        )}
        {role !== "consultant" && (
          <th
            scope="col"
            className="p-4 text-xs font-medium text-left text-gray-500 uppercase dark:text-gray-400"
          >
            Consultant
          </th>
        )}
        <th
          scope="col"
          className="p-4 text-xs font-medium text-left text-gray-500 uppercase dark:text-gray-400"
        >
          Departure
        </th>
        <th
          scope="col"
          className="p-4 text-xs font-medium text-left text-gray-500 uppercase dark:text-gray-400"
        >
          Arival
        </th>
        <th
          scope="col"
          className="p-4 text-xs font-medium text-left text-gray-500 uppercase dark:text-gray-400"
        >
          Date
        </th>
        <th
          scope="col"
          className="p-4 text-xs font-medium text-left text-gray-500 uppercase dark:text-gray-400"
        >
          Status
        </th>
        <th
          scope="col"
          className="p-4 text-xs font-medium text-left text-gray-500 uppercase dark:text-gray-400"
        >
          Passenger <br />& Budget
        </th>
        <th
          scope="col"
          className="p-4 text-xs font-medium text-left text-gray-500 uppercase dark:text-gray-400"
        >
          Action
        </th>
      </tr>
    </thead>
  );
}
