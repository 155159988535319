import { useQuery } from "@apollo/client";
import React from "react";
import travelHouseStore from "store/travelHouse/travelHouseStore";
import { GET_TRAVEL_HOUSES } from "./graphql/Query";
import SingleRecord from "./SingleRecord";

export default function TravelHouseList() {
  const { travelHouse }: any = travelHouseStore();

  const { loading, data, error } = useQuery(GET_TRAVEL_HOUSES);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error in the query {error.message}</div>;

  return (
    <main className="mx-auto  w-full">
      <section className="container  mx-auto">
        <div className="flex flex-col">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden border border-gray-200  md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50 ">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5  text-sm font-normal text-left rtl:text-right text-gray-500 "
                      >
                        <div className="flex  gap-x-3">
                          <button className="flex  gap-x-2">
                            <span>Sr No.</span>
                          </button>
                        </div>
                      </th>

                      <th
                        scope="col"
                        className=" py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 "
                      >
                        Name
                      </th>

                      <th
                        scope="col"
                        className=" py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 "
                      >
                        Plan
                      </th>

                      <th
                        scope="col"
                        className=" py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 "
                      >
                        Subdomain
                      </th>

                      <th
                        scope="col"
                        className=" py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 "
                      >
                        Domain
                      </th>
                      <th
                        scope="col"
                        className=" py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 "
                      >
                        Activated At
                      </th>

                      <th
                        scope="col"
                        className=" py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 "
                      >
                        Suspended At
                      </th>
                      <th
                        scope="col"
                        className=" py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 "
                      >
                        Expiry
                      </th>
                      <th
                        scope="col"
                        className=" py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 "
                      >
                        Created At
                      </th>

                      <th
                        scope="col"
                        className=" py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 "
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200 ">
                    {data.thp_list.length > 0 &&
                      data.thp_list.map((travelHouse: any, i: number) => (
                        <SingleRecord
                          key={i}
                          travelHouse={travelHouse}
                          index={i}
                        />
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
