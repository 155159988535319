import { useApolloClient, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import InputField from "~/components/FormInputs/InputField";
import { UPDATE_HEADINGS } from "./graphql/Mutation";
import { toast } from "react-toastify";
import { ALLOWED_IMAGE_FORMATS } from "~/config/constants";
import { useFileUpload } from "@nhost/react";

const EditHeroSection = ({ page }: any) => {
  const [updateHeadings] = useMutation(UPDATE_HEADINGS);
  const [logoFile, setLogoFile] = useState<any | null>(null);
  const { upload: uploadLogo, progress: logoUploadProgress } = useFileUpload();
  const client = useApolloClient();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const handleLogoUpload = async (event: any) => {
    const file = event.target.files[0];

    if (
      !ALLOWED_IMAGE_FORMATS.includes(file.type.split("/").at(1).toLowerCase())
    ) {
      toast.error("Invalid File format");
    } else {
      console.log("etc file selected");
      setLogoFile({ file });
    }
  };

  const onSubmit = async (inpData: any) => {
    if (logoFile) {
      const logoUploadRes = await uploadLogo(logoFile);
      inpData["featured_image"] = logoUploadRes?.id || null;
    }
    const res = await updateHeadings({
      variables: {
        id: { id: page.id },
        data: inpData,
      },
    });
    if (res?.data?.update_brd_pages_by_pk?.id) {
      toast.success("Headings updated successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      await client.refetchQueries({
        include: "all",
      });
    }
  };

  return (
    <div className="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800">
      <h3 className="mb-4 text-xl font-semibold dark:text-white">
        Hero Section
      </h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6 sm:col-span-3">
            <InputField
              register={register}
              errors={errors}
              fieldName="page_heading"
              inputClasses="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 py-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              label="Main Heading"
              placeHolder="Main heading"
              defaultValue={page?.page_heading}
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <InputField
              register={register}
              errors={errors}
              fieldName="page_sub_heading"
              inputClasses="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 py-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              label="Sub Heading"
              placeHolder="Sub heading"
              defaultValue={page?.page_sub_heading}
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <InputField
              register={register}
              errors={errors}
              fieldName="page_sub_heading_2"
              inputClasses="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 py-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              label="Description"
              placeHolder="Description"
              defaultValue={page?.page_sub_heading_2}
            />
          </div>
          <div className="col-span-6 sm:col-span-3 overflow-hidden">
            <label
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              htmlFor="hero_image"
            >
              Background image for hero section
            </label>
            <input
              className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
              aria-describedby="hero_image_help"
              id="hero_image"
              type="file"
              onChange={handleLogoUpload}
            />
            <p
              className="mt-1 text-sm text-gray-500 dark:text-gray-300"
              id="hero_image_help"
            >
              SVG, PNG, JPG or GIF (MAX. 800x400px).
            </p>
          </div>
          <div className="col-span-6 sm:col-full">
            <button
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
              type="submit"
            >
              Save Hero Section
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditHeroSection;
