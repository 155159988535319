import { useApolloClient, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { DELETE } from "./graphql/Mutation";
import { toast } from "react-toastify";
import EditTestimonial from "../EditTestimonial";
import { NavLink } from "react-router-dom";

const PostRow = ({ post }: any) => {
  const [deleteRow] = useMutation(DELETE);
  const client = useApolloClient();
  const [edit, setEdit] = useState(false);

  const deleteCard = async (id: any) => {
    const res = await deleteRow({ variables: { id } });
    if (res?.data?.delete_brd_posts?.affected_rows > 0) {
      toast.success("Post deleted successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      await client.resetStore();
    } else {
      toast.error("Something went wrong!");
    }
  };

  return (
    <>
      <tr className="hover:bg-gray-100 dark:hover:bg-gray-700">
        <td className="w-4 p-4">
          <div className="flex items-center">
            <input
              id="checkbox-194556"
              aria-describedby="checkbox-1"
              type="checkbox"
              className="w-4 h-4 border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
            />
            <label htmlFor="checkbox-194556" className="sr-only">
              checkbox
            </label>
          </div>
        </td>
        <td className="p-4 text-sm font-normal text-gray-500 whitespace-nowrap dark:text-gray-400">
          <div className="text-base font-semibold text-gray-900 dark:text-white">
            {post?.post_title} <br />
          </div>
        </td>
        <td className="p-4 text-base font-medium text-gray-900 whitespace-nowrap dark:text-white">
          {post?.excerpt} <br />
        </td>

        <td className="p-4 space-x-2 whitespace-nowrap">
          <NavLink
            className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            to={`/admin/brands/${post.brd_id}/destinations/update/${post.id}`}
          >
            <svg
              className="w-4 h-4 mr-2"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"></path>
              <path
                fill-rule="evenodd"
                d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                clip-rule="evenodd"
              ></path>
            </svg>
            Update
          </NavLink>
          <button
            type="button"
            onClick={() => deleteCard(post.id)}
            className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-red-700 rounded-lg hover:bg-red-800 focus:ring-4 focus:ring-red-300 dark:focus:ring-red-900"
          >
            <svg
              className="w-4 h-4 mr-2"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                clip-rule="evenodd"
              ></path>
            </svg>
            Delete item
          </button>
          {/* {edit && <EditTestimonial edit={edit} tsm={tsm} setEdit={setEdit} />} */}
        </td>
      </tr>
    </>
  );
};

export default PostRow;
