import React, { useState, useRef, useEffect } from "react";
import { Realtime } from "ably/promises";

import _ from "lodash";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import Bell from "assets/svg/Bell";
import { getTimeReadableFormat } from "utils/GetTimeReadable";
import notificationsStore from "store/notificationsStore/notificationsStore";
import { useNavigate } from "react-router-dom";
import commonStore from "store/commonStore/commonStore";
import { useUserId } from "@nhost/react";
import useNotifications from "hooks/useNotifications";
import { beepBase64 } from "data/sounds/beep";
import { useApolloClient } from "@apollo/client";
import { configureAbly, useChannel } from "@ably-labs/react-hooks";
import { ABLY } from "~/config/enums";
import { generateRandomId } from "~/utils/generateRandomId";

// const channel = ably.channels.get("notification");
configureAbly({ key: ABLY.ABLY_API_KEY, clientId: generateRandomId() });

function Notifications() {
  const { notifications, addNotifications, removeNotification }: any =
    notificationsStore();
  const [notificationDropdown, setNotificationDropdown]: any = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { pushPermissions } = commonStore();
  const client = useApolloClient();

  const userId: any = useUserId();

  const alert = useNotifications();

  // Get role from cookies
  const cookies = new Cookies();
  const role = cookies.get("role");

  const navigate = useNavigate();

  const [channel] = useChannel("notification", userId, async (message: any) => {
    console.log("Received message:", message.data);
    const snd = new Audio(beepBase64);
    snd.play();
    const payload = message.data;
    const not = { ...payload, id: Math.random().toString(36).substr(2) };

    addNotifications(not);
    // setNotificationDetail([...notificationDetail, not]);
    // Send Toster Notification
    toast.success(payload.headline, { autoClose: 5 * 60000 });

    await client.resetStore();

    if (pushPermissions === "granted") {
      let headline;
      if (role === "consultant") {
        headline = "Inquiry";
      } else {
        headline = "Booking";
      }

      const pushNotification = new Notification(headline, {
        body: payload.headline,
        icon: "",
      });
      pushNotification.addEventListener("click", () => {
        navigate(`https://` + payload.url);
      });
    }
  });

  const removeNoti = (id: any, url: any) => {
    removeNotification(id);
    // navigate(`https://${url}`);
  };

  return (
    <div className="relative flex items-center h-[72px]">
      <button
        onClick={() => {
          setNotificationDropdown(!notificationDropdown);
        }}
        className="relative flex items-center pl-2"
        title="Notifications"
      >
        <Bell />
        {notifications.length > 0 && (
          <span className="absolute bg-[red] h-[20px] leading-none p-[3px] rounded-[50%] right-[-10px] pl-[5px] pr-[5px]  mt-[-30px] text-[white]">
            {notifications.length}
          </span>
        )}
      </button>
      {notificationDropdown && (
        <>
          <div
            className="z-20 z-50 shadow-[0px_0px_4px_rgba(0,_0,_0,_0.25)] max-h-[500px] overflow-y-scroll w-[400px] right-[0] top-[70px] absolute max-w-sm rounded-xl text-sm text-gray-700 dark:text-gray-200 bg-white dark:bg-gray-700"
            id="notification-dropdown"
          >
            <div className="leading-none block rounded-t-xl bg-gray-200 py-2 px-4 text-center text-base font-medium text-gray-700 dark:bg-gray-700 dark:text-gray-400">
              Notifications
            </div>
            <div>
              {notifications.length > 0 ? (
                <>
                  {notifications?.map((noti: any) => {
                    return (
                      <div
                        onClick={() => {
                          removeNoti(noti.id, noti.url);
                        }}
                        className="leading-tight	flex px-4 py-3 border-b hover:bg-gray-100 dark:hover:bg-gray-600 dark:border-gray-600"
                      >
                        <div className="flex-shrink-0">
                          <span className="w-[17px] h-[17px] mt-[3px] inline-block absolute rounded-[100%] mr-2 border-[#27AE60] border-[5px]"></span>
                        </div>
                        <div className="w-full pl-[30px]">
                          <div className="text-gray-500 font-normal text-sm mb-1.5 dark:text-gray-400">
                            {noti.headline}
                          </div>
                          <div className="text-xs font-medium text-blue-700 dark:text-blue-400">
                            {getTimeReadableFormat(noti.created_at)}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <div className="bg-white  h-[35px] pt-2 flex w-full rounded-md shadow-xl">
                  <div className="w-[100%]">
                    <h3 className="leading-normal text-[14px] text-center">
                      No record found!
                      <button
                        onClick={() => {
                          alert.newNotification(
                            "admin",
                            "Heading Line",
                            "Description",
                            "url here"
                          );
                          console.log("all", userId);
                        }}
                      >
                        test
                      </button>
                    </h3>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* <div
            ref={dropdownRef}
            id="userMenu"
            className="absolute bg-white p-[15px] rounded-[10px] overflow-hidden shadow-custom-1 z-30 max-h-[500px] overflow-y-scroll w-[400px] right-[0] top-[47px]"
          >
            {notifications.length > 0 ? (
              <>
                {notifications?.map((noti: any) => {
                  return (
                    <div
                      key={noti.id}
                      onClick={() => {
                        removeNoti(noti.id, noti.url);
                      }}
                      className={`cursor-pointer ${
                        noti.read_status === "unread"
                          ? "bg-[#dcdcdc]"
                          : "bg-white"
                      }
                    mb-[10px] p-[10px] overflow-hidden h-[100px] pt-2 flex w-full rounded-md shadow-custom`}
                    >
                      <div className="w-[100%]">
                        <div className="leading-normal">
                          <span>
                            <span className="w-[17px] h-[17px] mt-[3px] inline-block absolute rounded-[100%] mr-2 border-[#27AE60] border-[5px]"></span>
                            <span className="ml-[25px]">
                              <span className="text-sm font-thin leading-none text-[#27AE60]">
                                Inquiry
                              </span>
                              <span className="font-bold pd-2 inline-block ml-2 mr-2">
                                <span className="bg-black h-1 w-1 inline-block rounded-[50%]"></span>
                              </span>
                              <span className="text-[12px] leading-none font-thin">
                                {getTimeReadableFormat(noti.created_at)}
                              </span>
                            </span>
                          </span>
                        </div>
                        <p className="leading-normal text-[14px] pt-[5px] font-medium	">
                          {noti.headline}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <div className="bg-white mb-[10px] h-[35px] pt-2 flex w-full rounded-md shadow-xl">
                <div className="w-[100%]">
                  <h3 className="leading-normal text-[14px] text-center">
                    No notification
                    <button
                      onClick={() => {
                        alert.newNotification(
                          "consultant",
                          "Heading Line",
                          "Description",
                          "url here"
                        );
                        console.log("all", userId);
                      }}
                    ></button>
                  </h3>
                </div>
              </div>
            )}
          </div> */}
        </>
      )}
    </div>
  );
}

export default Notifications;
