import { gql } from "@apollo/client";

export const GET_TESTIMONIALS = gql`
  query GetTestimonials($id: uuid) {
    brd_testimonials(where: { brd_id: { _eq: $id } }) {
      company
      created_at
      designation
      feedback
      id
      name
      rating
      updated_at
    }
  }
`;
