import { gql } from "@apollo/client";

export const GET_DEFAULT_SETTINGS = gql`
  query GetDefaultSettings($id: uuid) {
    brd_default_setting(where: { brd_id: { _eq: $id } }) {
      brd_id
      faq_heading
      faq_sub_heading
      id
      testimonial_heading
      testimonial_sub_heading
      destinations_main_heading
      destinations_button_text
      destinations_footer_text
      hot_destinations_heading
      hot_destinations_sub_heading
      created_at
      updated_at
    }
  }
`;
