import React, { useState } from "react";
import { VERIFY_DOMAIN } from "../../../../graphql/Query";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import InputField from "~/components/FormInputs/InputField";
import { useForm } from "react-hook-form";
import { ADD_DOMAIN_THP, UPDATE_DOMAIN } from "../../../../graphql/Mutation";
// import { ADD_DOMAIN_THP } from "../../../../graphql/Mutation";
import { useApolloClient } from "@apollo/client";
import { toast } from "react-toastify";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import { SEND_EMAIL } from "~/config/Mutation";
import { AdminTemplates } from "~/utils/TemplatesAll";
import { sendGenericEmail } from "~/utils/sendEmail";
import {
  GET_EMAILS,
  GET_TRAVEL_HOUSE_BY_ID,
} from "~/modules/InboxModule/segments/Inbox/graphql/Query";
import { useUserData } from "@nhost/react";

const DomainsBoxTHP = ({ brands, travelHouse, adminUsers }: any) => {
  const [saveLoading, setSaveLoading] = useState(false);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const { loading, data, error, refetch } = useQuery(VERIFY_DOMAIN, {
    variables: { domain: travelHouse?.domain },
  });
  const [updateDomain] = useMutation(UPDATE_DOMAIN);
  const [sendEmail] = useMutation(SEND_EMAIL);
  const userData = useUserData();

  const { loading: travelHouseLoading, data: travelHouseData } = useQuery(
    GET_TRAVEL_HOUSE_BY_ID,
    {
      variables: { id: travelHouse?.id },
      fetchPolicy: "network-only",
    }
  );

  const handleRefresh = async () => {
    setRefreshLoading(true);
    await refetch();
    setRefreshLoading(false);
  };
  const client = useApolloClient();
  const {
    formState: { errors },
    register,
    handleSubmit,
    reset,
    control,
    watch,
    setValue,
    getValues,
  } = useForm();
  console.log("travelHouse", travelHouse);

  const onFormSubmit = async (formData: any) => {
    console.log("formData", formData);
    setSaveLoading(true);
    const response = await client.query({
      query: ADD_DOMAIN_THP,
      variables: {
        domain: formData.portalDomain + formData.selectedDomain,
      },
    });
    console.log("domainRes", response);
    const domainResponse = await updateDomain({
      variables: {
        id: travelHouse.id, // Assuming that contactDetail has a reference to the brand's ID
        domain: formData.portalDomain + formData.selectedDomain,
      },
    });
    console.log("domainAddRes", domainResponse);
    if (!domainResponse.data) {
      setSaveLoading(false);
      throw new Error("Failed to update domain.");
    }

    adminUsers.forEach(async (email: any) => {
      const variables = {
        to: {
          email: email,
        },
        variables: {
          portalDomain: formData.portalDomain,
        },
      };
      const { emails, body, subject } = AdminTemplates.AddDnsRecords(variables);
      // const emailResp = await sendEmail({
      //   variables: {
      //     emails: emails,
      //     body: body,
      //     subject,
      //     from: "info@" + formData.selectedDomain.replace(/^\.+/, ""),
      //   },
      // });
      // console.log("emailResp", emailResp);
      try {
        const emailData = {
          emails: emails,
          body: body,
          subject,
          from: "info@" + formData.selectedDomain.replace(/^\.+/, ""),
        };
        if (travelHouseData?.thp_list[0]?.email_refresh_token) {
          // await sendEmailGmail({
          //   variables: {
          //     refresh_token: travelHouseData?.thp_list[0]?.email_refresh_token,
          //     sendEmail: true,
          //     to: emails,
          //     subject: subject,
          //     body: body,
          //     from: userData?.metadata?.alias,
          //     th_id: travelHouse?.id,
          //   },
          // });
          const response = await client.query({
            query: GET_EMAILS,
            variables: {
              refresh_token: travelHouseData?.thp_list[0]?.email_refresh_token,
              sendEmail: true,
              to: emails,
              subject: subject,
              body: body,
              from: userData?.metadata?.alias,
              th_id: travelHouse?.id,
              alias: false,
            },
          });
          console.log("response", response);
        } else {
          const emailResp = await sendGenericEmail(emailData, sendEmail);
          console.log("emailResp", emailResp);
        }
        // Handle success or do any additional logic here
      } catch (error) {
        // Handle error
        console.error("Error sending email:", error);
      }
    });

    // End of the domain update block
    reset();
    await client.resetStore();
    setSaveLoading(false);
    toast.success("Domain updated successfully", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  };
  console.log("dataVerify", data);
  console.log("brands", brands);
  return (
    <>
      {loading ? (
        "Loading..."
      ) : (
        <>
          <div className="m-4 rounded-lg shadow-lg bg-white">
            <div className="p-4 flex justify-between border-b">
              <p className="text-lg text-gray-600">{travelHouse.name}</p>
              <button
                className="text-lg text-blue-600 hover:text-blue-800 transition-all duration-200"
                onClick={handleRefresh}
              >
                {refreshLoading ? "..." : "Refresh"}
              </button>
            </div>
            <div className="p-4 flex gap-10 items-center border-b">
              <p className="text-lg text-gray-600">
                Default Domain: {travelHouse.subdomain}
              </p>
            </div>
            <p className="text-lg px-4 pt-4 text-gray-600">Custom Domain:</p>
            <form onSubmit={handleSubmit(onFormSubmit)} className="w-full p-4">
              <div className="flex">
                <InputField
                  register={register}
                  errors={errors}
                  fieldName="portalDomain"
                  required={true}
                  placeHolder="Portal Domain"
                  defaultValue={travelHouse?.domain?.split(".")?.[0]}
                />
                <select
                  {...register(
                    "selectedDomain"
                    // , { required: true }
                  )}
                  className="bg-gray-50 mb-4 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  defaultValue={travelHouse?.domain?.split(".")?.[1]}
                >
                  {brands.map((brand: any) => (
                    <option value={`.${brand.domain}`}>.{brand.domain}</option>
                  ))}
                </select>
              </div>
              <div className="flex  justify-center">
                <button
                  className="w-[50%] btn bg-green-500 text-white rounded-md p-2"
                  type="submit"
                >
                  {saveLoading ? "Saving..." : "Save"}
                </button>
              </div>
            </form>
            {/* <div className="p-4 flex gap-10 items-center border-b">
          <p className="text-lg text-gray-600">
            Whitelabele Domain: {travelHouse.domain}
          </p>
        </div> */}

            {data?.verifyVercelDomain?.domain?.misconfigured === true && (
              <div className="p-4 grid grid-cols-2 gap-4 border-b">
                <span className="text-gray-600 font-semibold">Type:</span>
                <span className="text-gray-500">CNAME</span>
                <span className="text-gray-600 font-semibold">Name:</span>
                <span className="text-gray-500">
                  {travelHouse?.domain?.split(".")?.[0]}
                </span>
                <span className="text-gray-600 font-semibold">Value:</span>
                <span className="text-gray-500">cname.vercel-dns.com</span>
              </div>
            )}

            <div className="p-4 flex gap-10 items-center">
              <p className="text-lg text-gray-600">
                {data?.verifyVercelDomain?.domain?.misconfigured === true ? (
                  <span className="text-red-600">Unverified</span>
                ) : (
                  <span className="text-green-600">Verified</span>
                )}
              </p>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default DomainsBoxTHP;
