import { useApolloClient, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { ADD_TICKET, ADD_TRANSACTION } from "./graphql/Mutation";
import { toast } from "react-toastify";
import moment from "moment";
import { DateTimeBox } from "components/FormInputs/DateTimeBox";
import useNotifications from "hooks/useNotifications";
import PurchaseDetail from "./components/PurchaseDetail";
import InputField from "~/components/FormInputs/InputField";
import { generateRandom10DigitCode } from "~/utils/generateRandomId";

export default function RequestForm({
  refundable,
  paidAmount,
  inquiry,
  fareExpiry,
  vendors,
}: any) {
  const [isloading, setIsloading] = useState(false);
  const [addTicket] = useMutation(ADD_TICKET);
  const [addTransaction] = useMutation(ADD_TRANSACTION);
  const client = useApolloClient();

  const alert = useNotifications();
  const [ticketPurchase, setTicketPurchase] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  }: any = useForm();

  // Custom validation function
  const validatecharge = (value: any) => {
    if (Number(value) <= paidAmount) {
      return true;
    } else {
      return "Paid amount must be greater then and equal to cancelation charges";
    }
  };

  const onSubmit = async (formData: any) => {
    formData["inq_id"] = inquiry.id;
    formData["status"] = "pending_approval";
    formData["fair_expiry"] = moment(formData["fair_expiry"]).format(
      "YYYY-MM-DD HH:mm:ss"
    );

    formData["void_time"] = moment(formData["void_time"]).format(
      "YYYY-MM-DD HH:mm:ss"
    );

    setIsloading(true);

    const payload = {
      inq_id: inquiry.id,
      pnr: formData["pnr_no"],
      ibe: formData["ibe_no"] || null,
      ticket_cost_price: formData["ticket_cost_price"] || null,
      ticket_attol_price: formData["ticket_attol_price"] || null,
      ticket_mis_cost: formData["ticket_mis_cost"] || null,
      void_time: formData["void_time"],
      vendor_id: formData["vendor_id"],
      refundable: refundable,
      cancelation_charges: formData["cancelation_charges"],
      status:
        formData["ibe_no"] === "" ? "pending_approval" : "awaiting_vendor",
    };

    console.log("payload");

    if (formData["ibe_no"]) {
      let ticket_attol_price = formData["ticket_attol_price"];
      let ticket_mis_cost = formData["ticket_mis_cost"];
      let ticket_cost_price = formData["ticket_cost_price"];
      const tenDigitCode: any = generateRandom10DigitCode();
      if (ticket_attol_price) {
        ticket_attol_price = parseFloat(ticket_attol_price);
      } else {
        ticket_attol_price = 0;
      }
      if (ticket_mis_cost) {
        ticket_mis_cost = parseFloat(ticket_mis_cost);
      } else {
        ticket_mis_cost = 0;
      }

      const rawTransactions: any = [
        {
          brd_id: inquiry.brd_id,
          def_acc_id: 6,
          inq_id: inquiry.id,
          transactions_no: tenDigitCode,
          date: moment(new Date()).format("YYYY-MM-DD"),
          type: "debit",
          amount: parseFloat(ticket_cost_price),
        },
        {
          brd_id: inquiry.brd_id,
          def_acc_id: 7,
          inq_id: inquiry.id,
          transactions_no: tenDigitCode,
          date: moment(new Date()).format("YYYY-MM-DD"),
          type: "debit",
          amount: parseFloat(ticket_attol_price),
        },
        {
          brd_id: inquiry.brd_id,
          def_acc_id: 8,
          inq_id: inquiry.id,
          transactions_no: tenDigitCode,
          date: moment(new Date()).format("YYYY-MM-DD"),
          type: "debit",
          amount: parseFloat(ticket_mis_cost),
        },
        {
          brd_id: inquiry.brd_id,
          def_acc_id: 2,
          user_id: formData["vendor_id"],
          inq_id: inquiry.id,
          transactions_no: tenDigitCode,
          date: moment(new Date()).format("YYYY-MM-DD"),
          type: "credit",
          amount: -(
            parseFloat(ticket_cost_price) +
            parseFloat(ticket_attol_price) +
            parseFloat(ticket_mis_cost)
          ),
        },
      ];
      const transactions = rawTransactions.filter(
        (obj: any) => obj.amount !== 0
      );

      console.log("formData", transactions);
      try {
        const res = await addTransaction({
          variables: {
            transactions: transactions,
          },
        });
        toast.success("Transaction added", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        await client.resetStore();
      } catch (e) {
        console.error("error", e);
      }
    }

    try {
      // Create Suggestion function call
      console.log("formData", formData);
      const res = await addTicket({
        variables: { ticket: { ...payload } },
      });
      if (res.data?.insert_inq_ticket?.returning?.length > 0) {
        toast.success("Ticket Request generated successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        await alert.newNotification(
          "admin",
          `Early ticket issuance request initiated by consultant`,
          "",
          ``
        );
        await client.resetStore();
      }
      setIsloading(false);
      //   setHideForm(false); // hide form modal
    } catch (e) {
      setIsloading(false);
      //   setHideForm(false);
      console.error("error", e);
    }
  };

  return (
    <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
      <>
        <div className="grid grid-cols-1 gap-4">
          <div className="">
            <label htmlFor="" className="text-[14px] font-[600] mb-2 block">
              Select Vendor {"*"}
            </label>
            <select
              {...register("vendor_id", {
                required: "Vendor is required",
              })}
              aria-invalid={errors.vendor_id ? "true" : "false"}
              className="w-full px-3 py-3 border rounded focus:outline-none focus:border-green-500 leading-tight border-gray-300"
            >
              <option value="">--Select Vendor--</option>
              {vendors.map((vendor: any) => {
                return (
                  <option key={`${vendor?.id}`} value={`${vendor?.id}`}>
                    {vendor.displayName}
                  </option>
                );
              })}
            </select>
            {errors.vendor_id && (
              <p role="alert" className="text-red-500 text-[12px] m-1">
                {errors.vendor_id.message}
              </p>
            )}
          </div>
        </div>
        <div className="grid grid-cols-1 gap-4">
          <div className="">
            <label htmlFor="" className="text-[14px] font-[600] mb-2 block">
              Cancelation charges {"*"}
            </label>
            <input
              className="border border-slate-300 py-[5px] h-[44px] px-[15px] text-[14px] rounded-[5px] w-full"
              type="number"
              placeholder="Cancelation charges"
              {...register("cancelation_charges", {
                required: "Charges are required",
                validate: validatecharge,
              })}
              aria-invalid={errors.cancelation_charges ? "true" : "false"}
            />
            {errors.cancelation_charges && (
              <p role="alert" className="text-red-500 text-[12px] m-1">
                {errors.cancelation_charges.message}
              </p>
            )}
          </div>
        </div>

        <div className="grid grid-cols-1 gap-4">
          <InputField
            register={register}
            errors={errors}
            type="text"
            fieldName="pnr_no"
            required={true}
            label=" PNR *"
            placeHolder="PNR"
          />
        </div>

        <div className="grid grid-cols-1 gap-4">
          <DateTimeBox
            control={control}
            setValue={setValue}
            errors={errors}
            fieldName={`void_time`}
            label="Void Time *"
            inputClasses={"bg-white rounded-lg py-2 mt-[2px]"}
            maxDate={moment(fareExpiry).format("YYYY-MM-DD")}
          />
        </div>

        <PurchaseDetail
          ticketPurchase={ticketPurchase}
          setTicketPurchase={setTicketPurchase}
          register={register}
          errors={errors}
        />

        {fareExpiry ? (
          <button
            type="submit"
            className={`text-white px-5 py-2 rounded hover:cursor-pointer bg-teal-700 transition-all mt-[20px]`}
          >
            {isloading ? "Requesting ..." : "Request"}
          </button>
        ) : (
          <p role="alert" className="text-red-500 text-[12px] m-1">
            Fare expiry not found in selected suggestion!
          </p>
        )}
      </>
    </form>
  );
}
