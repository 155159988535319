import { useUserDefaultRole } from "@nhost/react";
import React from "react";
import CheckMark from "~/assets/svg/CheckMark";
import DotLoader from "~/components/common/DotLoader";

export default function SingleButton({
  activeTab,
  setActiveTab,
  className,
  name,
  no,
}: any) {
  const role = useUserDefaultRole();
  return (
    <button
      className={`flex gap-2 text-white  hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 ${className}`}
      type="button"
      onClick={() => {
        setActiveTab(name);
      }}
      data-drawer-target="drawer-update-inquiry-default"
      data-drawer-show="drawer-update-inquiry-default"
      aria-controls="drawer-update-inquiry-default"
      data-drawer-placement="right"
    >
      {activeTab === name && <CheckMark />}
      {name === "Unassign" ? (role !== "admin" ? "New" : "Unassigned") : name}
      <span className="inline-flex items-center justify-center w-8 h-5 ml-2 text-xs font-semibold text-gray-800 bg-white rounded-full">
        {no ? no : no === 0 ? 0 : <DotLoader />}
      </span>
    </button>
  );
}
