import React from "react";
import moment from "moment";
import {
  getCityName,
  timeDuration,
} from "../../../../../../../../../../../../utils/utilsFunctions";

export default function SuggestionStop({ stop }: any) {
  return (
    <div className="self-stretch flex flex-row items-center justify-start">
      <div className="flex flex-col items-start justify-start gap-[1px]">
        <div className="self-stretch relative text-3xs ">From</div>
        <div className="self-stretch relative text-13xl  text-tomato">
          {stop.depart}
        </div>
        <div className="self-stretch relative">{getCityName(stop.depart)}</div>
        <div className="self-stretch relative">
          {moment(stop.depart_datetime).zone(0).format("DD-MM-YYYY")} |{" "}
          {moment(stop.depart_datetime).zone(0).format("HH:mm")}{" "}
        </div>
        {/* <div className="self-stretch flex flex-row items-center justify-start gap-[6px]">
      <img
        className="relative w-[11px] h-[11px]"
        alt=""
        src="/img/vector19.svg"
      />
      <div className="relative">Flexible</div>
    </div> */}
      </div>
      <img
        className="flex-1 relative max-w-full overflow-hidden h-px"
        alt=""
        src="/img/vector-8.svg"
      />
      <div className="flex flex-col items-center justify-center gap-[6px]">
        <div className="relative inline-block w-fit mx-3 h-[15px] shrink-0">
          {timeDuration(stop?.depart_datetime, stop?.arrival_datetime)}
        </div>
        <div className="flex flex-row items-center justify-start gap-[6px]">
          {/* <img
          className="relative w-3 h-3"
          alt=""
          src="/img/vector20.svg"
        />
        
         */}

          {/* <AirlineLogoImage
              src={`${stop.airline_name}`}
              className="relative w-3 h-3"
              alt="air"
            /> */}
          <div className="relative inline-block w-[50px] h-3.5 shrink-0">
            {stop.airline_iata}-{stop?.flight_no}
          </div>
        </div>
      </div>
      <img
        className="flex-1 relative max-w-full overflow-hidden h-px"
        alt=""
        src="/img/vector-686.svg"
      />
      <div className="flex flex-col items-end justify-start text-right">
        <div className="self-stretch relative text-3xs ">To</div>
        <div className="self-stretch relative text-13xl  text-tomato">
          {stop?.arrival}
        </div>
        <div className="self-stretch relative">
          {getCityName(stop?.arrival)}
        </div>
        <div className="self-stretch relative">
          {moment(stop.arrival_datetime).zone(0).format("DD-MM-YYYY")} |{" "}
          {moment(stop.arrival_datetime).zone(0).format("HH:mm")}
        </div>
        {/* <div className="flex flex-row items-center justify-center gap-[6px]">
      <div className="relative box-border w-[11px] h-[11px] border-[1px] border-solid border-darkgray" />
      <div className="relative">Flexible</div>
    </div> */}
      </div>
    </div>
  );
}
