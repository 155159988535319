import React from "react";
import { SideBarPopupBarProps } from "../../types";
import { FaArrowAltCircleLeft, FaTimes } from "react-icons/fa";

const SidePopupBar = ({ setShow, children, width }: SideBarPopupBarProps) => {
  return (
    <div className="modal-container fixed left-0 top-0 flex justify-end h-full w-full bg-[#00000070] z-[5]">
      <div
        className={`modal-box bg-[#fff] min-w-[300px] h-full flex items-center w-full max-w-[${width}] items-start overflow-y-auto   pt-[50px] p-[20px] relative`}
      >
        <button
          className="absolute top-[20px] cursor-pointer left-[20px]"
          onClick={() => {
            setShow(false);
          }}
        >
          <FaTimes className="text-red-500 " />
        </button>
        {children}
      </div>
    </div>
  );
};

export default SidePopupBar;
