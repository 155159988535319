enum URLs {
  GRAPHQL_URL = "https://xtvnyxqrjgccgtjyvcsf.hasura.eu-central-1.nhost.run/v1/graphql",
  FILE_URL = "https://xtvnyxqrjgccgtjyvcsf.storage.eu-central-1.nhost.run/v1/files/",
}

enum HASURA_KEYS {
  CLIENT_NAME = "hasura-console",
  SECRET = "ZbXZaEMRjKfo:JdSiHX=Z#0j5o$FJ_FJ",
}

enum NHOST {
  SUB_DOMAIN = "xtvnyxqrjgccgtjyvcsf",
  REGIION = "eu-central-1",
}

enum STRIPE_SK {
  KEY = "sk_test_51IWUwdG7sNvxSo7yxnDrUbh4l96P6sMtHklfitO5mfHNuT34jUTZ6ho5vFhflua2zjXQKvN29kmDuFUdYGonghyy00KZTnpAeW",
}

enum STRIPE_PK {
  KEY = "pk_test_51IWUwdG7sNvxSo7y73pBe40sNU7Rcw7XZ8EdTQJs7qLdcJGywLBWNIPeDKt1IYt9RMptb8QvIml5uEUFm6UWONsc00PjI27fEf",
}

enum CONFIG {
  BASE_URL = "https://localhost:3000",
}

enum GOOGLE {
  CLIENT_ID = "690884006674-nuu3h6nqoahj6elqddctkpcked3kofbt.apps.googleusercontent.com",
  CLIENT_SECRET = "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",
  REDIRECT_URI = "http://localhost:3000",
}

enum ABLY {
  ABLY_API_KEY = "RjlN_g.uxcIfA:rEZUNjXzLac9hqi4G05eAOw0mhD2tZJNcK4zkO2zv5E",
}

export { URLs, HASURA_KEYS, NHOST, STRIPE_SK, STRIPE_PK, CONFIG, GOOGLE, ABLY };
