import React, { useState } from "react";
import SuggestionBox from "./components/SuggestionBox";
import SuggestionNav from "./components/SuggestionNav";
import { sortSuggestions } from "../../../../../../utils/SortSuggestion";
import AddSuggestion from "./components/AddSuggestion";
import { useUserDefaultRole } from "@nhost/react";

export default function SuggestionCard({ inquiry }: any) {
  const [showAddModal, setShowAddModal] = useState<any>(false);

  // const suggestions = sortSuggestions(inquiry?.inq_suggestions);
  const [active, setActive] = useState<any>(inquiry?.inq_suggestions?.[0]?.id);

  const userDefaultRole = useUserDefaultRole();
  console.log("inquiryHere", inquiry);
  return (
    <div className="self-stretch flex flex-col items-start justify-start gap-[30px] mb-5 text-lightsteelblue">
      <div className=" flex self-end justify-end">
        {userDefaultRole !== "user" && inquiry.status !== "completed" && (
          <button
            onClick={() => setShowAddModal(!showAddModal)}
            className="ml-4 mt-2 px-4 py-1 border border-secondary text-secondary rounded-full inline-block hover:bg-secondary hover:text-white tansition-all hover:cursor-pointer"
          >
            Add Suggestion
          </button>
        )}
      </div>

      <SuggestionNav
        suggestions={inquiry?.inq_suggestions}
        active={active}
        setActive={setActive}
      />

      <div className="self-stretch flex flex-col items-start justify-start">
        {inquiry?.inq_suggestions?.map((suggestion: any, i: any) => (
          <SuggestionBox
            key={i}
            suggestion={suggestion}
            inquiry={inquiry}
            brand_name={inquiry.brd_list.name}
            brand_url={inquiry.brd_list.subdomain}
            brand_domain={inquiry.brd_list.domain}
            user_email={inquiry?.users?.email}
            user_id={inquiry?.users?.id}
            picked_by={inquiry?.picked_by}
            selected_suggestion_id={inquiry?.suggestion_id}
            selected={inquiry.suggestion_id === suggestion.id ? true : false}
            active={active === suggestion.id ? true : false}
          />
        ))}
      </div>

      {showAddModal && (
        <AddSuggestion
          setShowAddModal={setShowAddModal}
          inquiry_id={inquiry.id}
          inquiry={inquiry}
          brand_name={inquiry.brd_list.name}
          brand_url={inquiry.brd_list.subdomain}
          brand_domain={inquiry.brd_list.domain}
          passengers={inquiry?.inq_passengers}
          arrival={inquiry?.to}
          user_id={inquiry?.users?.id}
          user_email={inquiry?.users?.email}
          user_phone={inquiry?.users?.phoneNumber}
        />
      )}
    </div>
  );
}
