import React from "react";
import { useQuery } from "@apollo/client";
// import gql queries
import { GET_TRAVEL_HOUSE } from "./graphql/Query";
import travelHouseStore from "../../../../store/travelHouse/travelHouseStore";
import { useNavigate } from "react-router-dom";
import { useUserDefaultRole, useUserId } from "@nhost/react";
import NoTravelHouse from "./components/NoTravelHouse";

export default function VerifyUser() {
  const navigate = useNavigate();
  const userDefaultRole = useUserDefaultRole();
  const userId = useUserId();
  const { setTravelHouse }: any = travelHouseStore();

  // Check if user is on Local ENV get etravelo as a travel house

  let customUrl = "abc-portalabc.travelhouseportal.com";
  // get data based on query
  const { loading, data, error } = useQuery(GET_TRAVEL_HOUSE, {
    variables: { custom_url: customUrl, user_id: userId },
  });

  // return loading while loading data
  if (loading) return <div>Loading...</div>;
  // Return error if get gql error
  if (error) return <div>Error in the query verify {error.message}</div>;

  if (data.thp_list.length !== 0) {
    setTravelHouse(data.thp_list[0]);
    navigate(`../${userDefaultRole}`);
  } else {
    if (userDefaultRole === "super-admin") {
      navigate(`../${userDefaultRole}`);
    }
  }

  return (
    <div className="">
      {data.thp_list.length !== 0 && (
        <h2>
          Travel House Assigned, But something went wrong with you! {":("}
        </h2>
      )}
      {data.thp_list.length === 0 && <NoTravelHouse />}
    </div>
  );
}
