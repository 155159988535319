import React from "react";

const Bell = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.39062 8.12508C4.38956 7.38415 4.53513 6.65028 4.81894 5.96584C5.10275 5.28137 5.51917 4.65984 6.04407 4.137C6.56912 3.61416 7.19244 3.20034 7.87802 2.91939C8.56375 2.63847 9.29816 2.49596 10.0391 2.50008C13.1328 2.52354 15.6093 5.09384 15.6093 8.19538V8.75008C15.6093 11.547 16.1953 13.172 16.7109 14.0626C16.7657 14.1574 16.7946 14.265 16.7946 14.3745C16.7948 14.4841 16.7661 14.5917 16.7115 14.6866C16.6569 14.7816 16.5783 14.8605 16.4837 14.9155C16.3889 14.9706 16.2813 14.9997 16.1719 15.0001H3.82819C3.71863 14.9997 3.61105 14.9706 3.51645 14.9155C3.42169 14.8605 3.34311 14.7816 3.28848 14.6866C3.23386 14.5917 3.20517 14.4841 3.20532 14.3745C3.20547 14.265 3.23431 14.1574 3.28909 14.0626C3.80469 13.172 4.39062 11.547 4.39062 8.75008V8.12508Z"
        fill="black"
        fill-opacity="0.1"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.76562 8.75007V8.12507C3.76562 8.12507 3.76387 6.87864 4.24158 5.72645C4.24158 5.72645 4.71929 4.57425 5.6031 3.69411C5.6031 3.69411 6.48691 2.81397 7.64108 2.34105C7.64108 2.34105 8.79525 1.86814 10.0425 1.87508C10.0425 1.87508 11.3108 1.88468 12.4657 2.39135C12.4657 2.39135 13.5768 2.87881 14.4322 3.75745C14.4322 3.75745 15.2842 4.63266 15.7515 5.76121C15.7515 5.76121 16.2344 6.92735 16.2344 8.19538V8.75007C16.2344 8.75007 16.2344 11.992 17.2518 13.7494C17.2518 13.7494 17.4194 14.0396 17.4197 14.374C17.4197 14.374 17.42 14.7083 17.2533 14.9982C17.2533 14.9982 17.0866 15.288 16.7975 15.4559C16.7975 15.4559 16.5084 15.6239 16.1741 15.6251L3.82812 15.6251C3.82812 15.6251 3.49157 15.6239 3.20246 15.4559C3.20246 15.4559 2.91335 15.288 2.74669 14.9982C2.74669 14.9982 2.58003 14.7083 2.58032 14.374C2.58032 14.374 2.58062 14.0396 2.7478 13.7501C2.7478 13.7501 3.76562 11.992 3.76562 8.75007ZM5.01562 8.75007C5.01562 8.75007 5.01562 12.3277 3.83032 14.3751L16.1697 14.3751C16.1697 14.3751 14.9844 12.3272 14.9844 8.75007V8.19538C14.9844 8.19538 14.9844 6.11659 13.5365 4.6294C13.5365 4.6294 12.0871 3.1406 10.0356 3.12506C10.0356 3.12506 9.03807 3.11951 8.11502 3.49772C8.11502 3.49772 7.19197 3.87593 6.48514 4.57982C6.48514 4.57982 5.77831 5.28372 5.39627 6.20519C5.39627 6.20519 5.01422 7.12666 5.01562 8.12507V8.75007Z"
        fill="#1C1C1C"
      />
      <path
        d="M13.125 15.625V15C13.125 14.6548 12.8452 14.375 12.5 14.375C12.1548 14.375 11.875 14.6548 11.875 15V15.625C11.875 16.4016 11.3258 16.9508 11.3258 16.9508C10.7766 17.5 10 17.5 10 17.5C9.22335 17.5 8.67417 16.9508 8.67417 16.9508C8.125 16.4016 8.125 15.625 8.125 15.625V15C8.125 14.6548 7.84518 14.375 7.5 14.375C7.15482 14.375 6.875 14.6548 6.875 15L6.875 15.625C6.875 16.9194 7.79029 17.8347 7.79029 17.8347C8.70558 18.75 10 18.75 10 18.75C11.2944 18.75 12.2097 17.8347 12.2097 17.8347C13.125 16.9194 13.125 15.625 13.125 15.625Z"
        fill="#1C1C1C"
      />
    </svg>
  );
};

export default Bell;
