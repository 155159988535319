import React from "react";
// Import components
import InputField from "components/FormInputs/InputField";
import SelectBox from "components/FormInputs/SelectBox";
// Import intial data
import { legDefaultValue } from "../../../../../../../../../../../../data/InitalDefaultValue";
import { CabinClasses } from "~/data/cabinClasses";

export default function FlightType({
  register,
  errors,
  control,
  setValue,
  unregister,
  getValues,
}: any) {
  return (
    <div>
      <div className="flex w-full bg-gray-100 rounded-lg p-2 flex-col">
        <h1 className="w-full font-bold text-left border-b-2 border-gray-300 mb-4">
          Trip Type
        </h1>
        <div className="flex">
          {/* Trip Type Type */}
          <div className="flex-1 self-stretch flex flex-row items-center justify-start gap-[30px]">
            <div
              className="flex flex-row items-center justify-center gap-[5px]"
              onClick={() => {
                setValue("returnLeg", legDefaultValue);
              }}
            >
              <InputField
                label="One Way"
                register={register}
                type="radio"
                errors={errors}
                required={true}
                defaultValue={1}
                fieldName="tripType"
                checked={getValues("tripType") === 1 ? true : false}
                containerClasses="flex"
              />
            </div>
            <div className="flex flex-row items-center justify-center gap-[5px]">
              <InputField
                label="Round Trip"
                register={register}
                type="radio"
                errors={errors}
                required={true}
                defaultValue={2}
                fieldName="tripType"
                checked={getValues("tripType") === 2 ? true : false}
                containerClasses="flex"
              />
            </div>
          </div>
          {/* Cabin Class Select box*/}
          <div className="flex-1">
            <SelectBox
              control={control}
              fieldName={"cabinType"}
              label="Cabin Class"
              options={CabinClasses}
              selectedOption={CabinClasses[2]}
              required={true}
              inputClasses="border rounded border-gray-200 pl-3"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
