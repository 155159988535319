import { Fragment, useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { AiOutlineCheck } from "react-icons/ai";
import { LuChevronsUpDown, LuOption } from "react-icons/lu";

import { SelectBoxProps } from "./types";
import { Controller } from "react-hook-form";
import IconRenderer from "../IconRenderer";

const SelectBox = ({
  control,
  fieldName,
  register,
  errors,
  required,
  label,
  containerClasses,
  inputClasses,
  chevronClasses,
  checkIconClasses,
  optionClasses,
  optionContainerClasses,
  optionHoverClasses,
  labelClasses,
  errorClasses,
  options,
  selectedOption,
  iconComponent,
}: SelectBoxProps) => {
  const [winWidth, setWinWidth] = useState(0);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(
    selectedOption ? selectedOption : options[0]
  );

  useEffect(() => {
    if (window !== undefined) {
      if (open) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "unset";
      }
    }
  }, [open]);

  useEffect(() => {
    if (window !== undefined) {
      setWinWidth(window.innerWidth);
    }
  }, []);

  useEffect(() => {
    if (window !== undefined) {
      window.addEventListener("resize", () => {
        setWinWidth(window.innerWidth);
      });
    }
  });

  return (
    <div className={`w-full relative z-[1] ${containerClasses}`}>
      {label && (
        <label
          htmlFor="fruit"
          className={`text-[16px] mb-2 block ${labelClasses}`}
        >
          {label}:
        </label>
      )}
      <Controller
        control={control}
        name={fieldName}
        rules={{
          required: required || false,
        }}
        defaultValue={selected.value}
        render={({ field: { onChange } }: any) => (
          <Listbox value={selected.value} onChange={onChange}>
            <div className={`${winWidth > 600 ? "relative" : ""}`}>
              <Listbox.Button
                onClick={() => setOpen(!open)}
                className={`block w-full p-[5px] text-left ${inputClasses}`}
              >
                <span className="block truncate">{selected.title}</span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  {/* <LuChevronsUpDown
                                className={`h-5 w-5 text-gray-400 ${chevronClasses}`}
                                aria-hidden="true"
                            /> */}
                  {iconComponent && (
                    <IconRenderer iconComponent={iconComponent} />
                  )}
                </span>
              </Listbox.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <div
                  className={
                    winWidth < 600
                      ? "overlay  fixed top-0 left-0 w-full h-screen z-[19] bg-[#00000070]"
                      : ""
                  }
                >
                  <Listbox.Options
                    className={`${
                      winWidth > 600
                        ? "absolute max-h-60"
                        : "w-full fixed translate-y-0 transition-all transform bottom-0 left-0 h-[50vh] overflow-y-scroll"
                    } z-[20] mt-1  w-full min-w-[300px] overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm  ${optionContainerClasses}`}
                  >
                    {options.map((opt: any, index: number) => (
                      <Listbox.Option
                        onClick={() => {
                          setSelected(opt);
                          setOpen(false);
                        }}
                        key={index}
                        className={({ active }) =>
                          `relative cursor-default select-none py-2 pl-10 pr-4 ${
                            active
                              ? `bg-amber-100 text-amber-900 ${optionHoverClasses}`
                              : "text-gray-900"
                          }
                                    ${optionClasses}
                                    `
                        }
                        value={opt.value}
                      >
                        {({ selected }) => (
                          <>
                            <span
                              className={`block truncate ${
                                selected ? "font-medium" : "font-normal"
                              }`}
                            >
                              {opt.title}
                            </span>
                            {selected ? (
                              <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                <AiOutlineCheck
                                  className={`w-5 h-5 ${checkIconClasses}`}
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </div>
              </Transition>
            </div>
          </Listbox>
        )}
      />
    </div>
  );
};

export default SelectBox;
