import React from "react";
import { useQuery } from "@apollo/client";
// import gql queries
import { GET_PAYMENT_METHODS } from "./graphql/Query";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import { useUserDefaultRole } from "@nhost/react";
// Import components
import PaymentMethodCard from "./components/PaymentMethodCard";
import PaymentMethodAdd from "./components/PaymentMethodAdd";
import SidePopup from "~/components/common/SidePopup";

export default function PaymentMethodsList() {
  const { travelHouse }: any = travelHouseStore();
  const userDefaultRole = useUserDefaultRole();
  // get data based on query
  const { loading, data, error } = useQuery(GET_PAYMENT_METHODS, {
    variables: { th_id: travelHouse.id, role: userDefaultRole },
  });
  // return loading while loading data
  if (loading) return <div>Loading...</div>;
  // Return error if get gql error
  if (error) return <div>Error in the query {error.message}</div>;

  return (
    <>
      <div className="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-4">
        {data.acc_payment_methods.map((payment_method: any, index: any) => (
          <PaymentMethodCard
            key={index}
            payment_method={payment_method}
            payment_methods={data.acc_payment_methods}
          />
        ))}
        <SidePopup component={<PaymentMethodAdd />} />
        {/* No Record Found */}
        {data.acc_payment_methods.length === 0 && <h2>No record found</h2>}
      </div>
    </>
  );
}
