import moment from "moment";
import React from "react";
import { URLs } from "~/config/enums";
import {
  getPaidAmount,
  getSalePrice,
} from "~/modules/InquiriesModule/utils/getInquiryStats";

export default function InvoiceDetail({ inquiry, invoice }: any) {
  return (
    <div>
      <div className="col-span-12 mx-4 mb-4 rounded-lg bg-white p-4 shadow dark:bg-gray-800 sm:p-6 md:mx-6 lg:my-6 xl:col-span-10 xl:col-start-2 xl:p-8 2xl:col-span-8 2xl:col-start-3">
        <div className="space-y-6 overflow-hidden p-4 md:p-8">
          <div className="sm:flex">
            <div className="mb-5 text-2xl font-bold dark:text-white sm:mb-0 sm:text-3xl">
              Invoice #{invoice?.invoice_no}
            </div>
            <div className="space-y-3 text-left sm:ml-auto sm:text-right">
              <img
                className="h-[70px] float-right	"
                src={`${URLs.FILE_URL}${inquiry?.brd_list?.brd_details?.[0]?.logo}`}
              />
              <div className="clear-both"></div>
              <div className="space-y-1">
                <div className="text-lg font-semibold text-gray-900 dark:text-white">
                  {inquiry?.brd_list?.name}
                </div>
                <div className="text-sm font-normal text-gray-900 dark:text-white">
                  {inquiry?.brd_list?.brd_contact_details?.[0]?.address1} <br />
                  {inquiry?.brd_list?.brd_contact_details?.[0]?.city}{" "}
                  {inquiry?.brd_list?.brd_contact_details?.[0]?.country}
                </div>
              </div>
              <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
                {moment(inquiry.created_at).format("DD MMM YYYY hh:mm a")}
              </div>
            </div>
          </div>
          <div className="sm:w-72">
            <div className="mb-4 text-base font-bold uppercase text-gray-900 dark:text-white">
              Bill to
            </div>
            <p>{inquiry?.users?.displayName}</p>
            <p>{inquiry?.users?.phoneNumber}</p>
            <address className="text-base font-normal text-gray-500 dark:text-gray-400">
              {inquiry?.users?.metadata?.addressLine1}{" "}
              {inquiry?.users?.metadata?.addressLine2}{" "}
              {inquiry?.users?.metadata?.postalCode}{" "}
              {inquiry?.users?.metadata?.city}{" "}
            </address>
          </div>
          <div className="my-8 flex flex-col">
            <div className="overflow-x-auto border-b border-gray-200 dark:border-gray-600">
              <div className="inline-block min-w-full align-middle">
                <div className="overflow-hidden shadow">
                  <div
                    data-testid="table-element"
                    className="relative overflow-x-auto shadow-md sm:rounded-lg"
                  >
                    <table className="w-full text-left text-sm text-gray-500 dark:text-gray-400 min-w-full text-gray-900">
                      <thead className="bg-gray-50 text-xs uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                          <th className="px-6 py-3">Sr</th>
                          <th className="px-6 py-3">Description</th>
                          <th className="px-6 py-3">Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {invoice?.inq_invoice_items.map((item: any, i: any) => {
                          return (
                            <tr data-testid="table-row-element" className="">
                              <td className="px-6 py-4 whitespace-nowrap p-4 text-sm font-normal">
                                <div className="text-base font-semibold">
                                  {i + 1}
                                </div>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap p-4 text-base font-normal text-gray-500 dark:text-gray-400">
                                {item.description}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap p-4 text-base font-semibold">
                                {item.amount}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="space-y-3 sm:ml-auto sm:w-72 sm:text-right">
            {/* <div className="flex justify-between">
              <div className="text-sm font-medium uppercase text-gray-500 dark:text-gray-400">
                Paid Amount
              </div>
              <div className="text-base font-medium text-gray-900 dark:text-white">
                {getPaidAmount(inquiry)}
              </div>
            </div> */}
            <div className="flex justify-between">
              <div className="text-sm font-medium uppercase text-gray-500 dark:text-gray-400">
                Total Amount
              </div>
              <div className="text-base font-medium text-gray-900 dark:text-white">
                {invoice?.inq_invoice_items?.reduce(
                  (sum: any, obj: any) => sum + parseFloat(obj.amount),
                  0
                )}
              </div>
            </div>
            {/* <div className="flex justify-between">
              <div className="text-sm font-medium uppercase text-gray-500 dark:text-gray-400">
                Due Amount
              </div>
              <div className="text-base font-medium text-gray-900 dark:text-white">
                {getSalePrice(inquiry) - getPaidAmount(inquiry)}
              </div>
            </div> */}
            {/* <div className="flex justify-between">
              <div className="text-base font-semibold uppercase text-gray-900 dark:text-white">
                Total
              </div>
              <div className="text-base font-bold text-gray-900 dark:text-white">
                $351.00
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
