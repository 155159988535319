import React from "react";
import { FaExclamationCircle } from "react-icons/fa";

interface NoDataFoundProps {
  text?: string;
  classNames?: {
    container?: string;
    text?: string;
  };
  icon?: any;
}

const NoDataFound = ({ text, classNames, icon }: NoDataFoundProps) => {
  return (
    <div
      className={`flex flex-col items-center gap-[7px] my-2 ${classNames?.container}`}
    >
      {icon && icon}
      <p className={`text-center  text-[24px] ${classNames?.text}`}>
        {text ? text : `No record found!`}
      </p>
    </div>
  );
};

NoDataFound.defaultProps = {
  icon: <FaExclamationCircle className="text-[30px] text-red-500" />,
};

export default NoDataFound;
