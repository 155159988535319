import React, { useEffect } from "react";
import { Controller } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { PhoneBoxProps } from "./types";

const PhoneBox = ({
  control,
  fieldName,
  errors,
  label,
  setValue,
  defaultValue,
  labelClasses,
  errorClasses,
  containerClasses,
}: PhoneBoxProps) => {
  useEffect(() => {
    if (defaultValue) {
      setValue(fieldName, defaultValue);
    }
  }, []);
  return (
    <div className={`w-full ${containerClasses}`}>
      {label && (
        <label htmlFor="fruit" className={`text-[16px] ${labelClasses}`}>
          {label}:
        </label>
      )}
      <Controller
        control={control}
        name={fieldName}
        defaultValue={defaultValue}
        render={({ field }) => (
          <PhoneInput
            {...field}
            inputProps={{
              className:
                "w-full h-[43px] pl-[50px] px-3 py-2 border border-gray-200 rounded focus:outline-none leading-tight",
            }}
            country={"gb"} // Set your desired default country
            enableAreaCodes={true} // Show countries dropdown
            value={defaultValue}
          />
        )}
      />
      {errors[fieldName] && (
        <span
          role="alert"
          className={`text-red-500 m-1 text-[12px] normal-case ${errorClasses}`}
        >
          {errors[fieldName]?.message}
        </span>
      )}
    </div>
  );
};

export default PhoneBox;
