import { useApolloClient, useLazyQuery, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import InputField from "~/components/FormInputs/InputField";
import PhoneBox from "~/components/FormInputs/PhoneBox";
import { INSERT_POST } from "./graphql/Mutation";
import { toast } from "react-toastify";
import LocationBox from "~/components/FormInputs/LocationBox";
import AirlineBox from "~/components/FormInputs/AirlineBox";
import { ALLOWED_IMAGE_FORMATS } from "~/config/constants";
import { useFileUpload } from "@nhost/react";
import { useNavigate } from "react-router-dom";
import { GET_FLIGHT_PAGES } from "../../graphql/Query";
import CountryBox from "~/components/FormInputs/CountryBox";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const PostDetail = ({ brand }: any) => {
  const navigate = useNavigate();
  const contact = brand?.brd_contact_details?.at(0);
  const [insertPost] = useMutation(INSERT_POST);
  const [getFlightPages] = useLazyQuery(GET_FLIGHT_PAGES);
  const { upload: uploadLogo, progress: logoUploadProgress } = useFileUpload();
  const client = useApolloClient();
  const [logoFile, setLogoFile] = useState<any | null>(null);
  const [featuredFile, setFeatureFile] = useState<any | null>(null);
  const [featured, setFeatured] = useState<any | null>(false);
  const [content, setContent] = useState("");
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    setValue,
    reset,
  } = useForm();

  const onSubmit = async (inpData: any) => {
    console.log("xwt inpdata ===>", inpData);

    if (logoFile) {
      const logoUploadRes = await uploadLogo(logoFile);
      inpData["image"] = logoUploadRes?.id || null;
    }

    const res = await insertPost({
      variables: {
        data: [
          {
            brd_id: brand.id,
            slug: inpData?.post_title?.replaceAll(" ", "-").toLowerCase(),
            post_title: inpData?.post_title,
            featured_image: inpData?.image || null,
            content: content,
            show_in_flight_page: inpData?.show_in_flight_page,
            country: inpData?.country?.label || null,
            excerpt: inpData?.excerpt,
          },
        ],
      },
    });
    console.log("res", res);
    if (res?.data?.insert_brd_posts?.affected_rows > 0) {
      reset();
      toast.success("Post added successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      await client.refetchQueries({
        include: "all",
      });
      navigate(
        `/admin/brands/${res?.data?.insert_brd_posts.returning?.[0].brd_id}/posts`
      );
    }
  };

  const handleLogoUpload = async (event: any) => {
    const file = event.target.files[0];

    if (
      !ALLOWED_IMAGE_FORMATS.includes(file.type.split("/").at(1).toLowerCase())
    ) {
      toast.error("Invalid File format");
    } else {
      console.log("etc file selected");
      setLogoFile({ file });
    }
  };

  return (
    <div className="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800">
      <h3 className="mb-4 text-xl font-semibold dark:text-white">
        Post Detail
      </h3>
      <form onSubmit={handleSubmit(onSubmit)} action="#">
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6 sm:col-span-3">
            <InputField
              register={register}
              errors={errors}
              fieldName="post_title"
              type="text"
              inputClasses="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 py-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              label="Post Title"
              placeHolder="Enter Post Title"
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <div className="col-span-6 sm:col-span-3 overflow-hidden">
              <label className="mb-2 block">Image for post hero section</label>
              <input
                type="file"
                onChange={handleLogoUpload}
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              />
              {logoUploadProgress && (
                <p className="font-thin">
                  Image Upload Progress: {logoUploadProgress}%
                </p>
              )}
              <p
                className="mt-1 text-sm text-gray-500 dark:text-gray-300"
                id="light_logo_help"
              >
                SVG, PNG, JPG or GIF (MAX. 800x400px).
              </p>
            </div>
          </div>

          <div className="col-span-6 sm:col-span-3">
            <span className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Placements
            </span>
            <ul className="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
              <li className="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                <div className="flex items-center pl-3">
                  <input
                    id="react-checkbox-list-3"
                    type="checkbox"
                    onClick={() => {
                      setFeatured(!featured);
                    }}
                    {...register("show_in_flight_page")}
                    value=""
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                  />
                  <label
                    htmlFor="react-checkbox-list-3"
                    className="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Show in Destination Page
                  </label>
                </div>
              </li>
            </ul>
          </div>
          {featured ? (
            <div className="col-span-6 sm:col-span-3">
              <div className="col-span-6 sm:col-span-3">
                <CountryBox
                  control={control}
                  errors={errors}
                  label="Country"
                  fieldName="country"
                  inputClasses="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  setValue={setValue}
                  placeholder="Country"
                />
              </div>
            </div>
          ) : (
            <div className="col-span-6 sm:col-span-3"></div>
          )}

          <div className="col-span-6 sm:col-full">
            <div className="col-span-6 sm:col-span-3 overflow-hidden">
              <label className="mb-2 block">Post Excerpt</label>
              <textarea
                {...register("excerpt", {
                  required: true ? `* This is required` : false,
                })}
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 py-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              ></textarea>
            </div>
          </div>

          <div className="col-span-6 sm:col-full">
            <label className="mb-2 block">Post Content</label>
            <CKEditor
              editor={ClassicEditor}
              data={content}
              onReady={(editor) => {
                // You can store the "editor" and use when it is needed.
                console.log("Editor is ready to use!", editor);
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                setContent(data);
              }}
            />
          </div>
          <div className="col-span-6 sm:col-full">
            <button
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
              type="submit"
            >
              Save Post
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default PostDetail;
