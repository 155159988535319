import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  useMutation,
  useApolloClient,
  useQuery,
  useLazyQuery,
} from "@apollo/client";
import { toast } from "react-toastify";
import {
  useUserDefaultRole,
  useUserDisplayName,
  useUserId,
} from "@nhost/react";
import { EDIT_INQUIRY, INSERT_INQUIRY } from "./graphql/Mutation";
import LocationBox from "components/FormInputs/LocationBox";
import { CalendarBox } from "components/FormInputs/CalendarBox";
import SelectBox from "components/FormInputs/SelectBox";
import { CabinClasses } from "~/data/cabinClasses";
import AirlineBox from "~/components/FormInputs/AirlineBox";
import InputField from "~/components/FormInputs/InputField";
import moment from "moment";
import useNotifications from "~/hooks/useNotifications";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import {
  GET_BRANDS,
  GET_BRANDS_THP,
  GET_PAYMENT_METHODS,
  GET_USER,
} from "./graphql/Query";
import useEmail from "~/hooks/emails/useEmail";
import SimpleSelect from "~/components/FormInputs/SimpleSelect";
import { generateRandomNumberString } from "~/utils/generateRandomId";
import useAssignedBrands from "~/hooks/useAssignedBrands";

export default function InquiryAdd({ setSidePopup }: any) {
  const { travelHouse }: any = travelHouseStore();
  const userDefaultRole = useUserDefaultRole();
  const [brdData, setBrdData] = useState<any>([]);
  const [getBrandsMembers] = useLazyQuery(GET_BRANDS);
  const [getAllMembers] = useLazyQuery(GET_BRANDS_THP);
  let dataRes: any = null;

  const [getUsers] = useLazyQuery(GET_USER);
  const [insertInquiry] = useMutation(INSERT_INQUIRY);
  const [getPayments] = useLazyQuery(GET_PAYMENT_METHODS);
  const [brands, setbrands] = useState<any>([]);
  const [users, setUsers] = useState<any>([]);
  const [paymentOptions, setPaymentOptions] = useState<any>([]);
  const assignedBrands = useAssignedBrands();
  // get data based on query

  const userId = useUserId();

  const fetchMembers = async () => {
    console.log("xwt user id ===>", userId);
    console.log("xwt ===> brands ===>", assignedBrands);
    if (assignedBrands) {
      dataRes = await getBrandsMembers({
        variables: { brands: assignedBrands },
      });
      console.log("xwt data check ===>", dataRes);
      setBrdData(dataRes);
    } else {
      dataRes = await getAllMembers({
        variables: { thp_id: travelHouse?.id },
      });

      console.log("xwt data check 2 ===>", dataRes);
      setBrdData(dataRes);
    }

    console.log("xwt data ===>", dataRes);
  };

  useEffect(() => {
    fetchMembers();
  }, []);

  const client = useApolloClient();
  const alert = useNotifications();
  const role = useUserDefaultRole();
  const userDisplayName = useUserDisplayName();
  const emailSend = useEmail();
  const {
    formState: { errors },
    register,
    handleSubmit,
    control,
    setValue,
    watch,
  } = useForm();

  const brd_id = watch("brd_id");

  const fetchUsers = async (brd_id: string) => {
    if (brd_id) {
      const { loading: userLoading, data: userData } = await getUsers({
        variables: {
          brd_id: brd_id,
          role: "user",
        },
      });

      const defaultOpt = [
        {
          title: "Choose Brands",
          value: "",
        },
      ];
      if (userData) {
        // console.log("xwt user ===>", userData);
        const userObj: any = userData?.users?.map((usr: any) => {
          return {
            title: usr?.displayName,
            value: usr?.id,
          };
        });

        setUsers([...defaultOpt, ...userObj]);
      }

      const { data: paymentData }: any = await getPayments({
        variables: {
          th_id: travelHouse?.id,
          brd_id: brd_id,
        },
      });

      if (paymentData) {
        const paymentMethods: any = [
          {
            id: "",
            value: "",
            title: "--Select Payment Method--",
          },
        ];

        console.log("xwt payment ==>", paymentData);
        paymentData?.acc_payment_methods
          .filter((pay: any) => pay.type === "bank")
          .forEach((pay: any) => {
            const singlePay: any = {};
            singlePay["id"] = pay.id;
            singlePay["title"] = pay.name;
            singlePay["value"] = pay.id;
            paymentMethods.push(singlePay);
          });

        setPaymentOptions(paymentMethods);
      }
    }
  };

  useEffect(() => {
    // console.log("xwt this is selected brand", brd_id);
    fetchUsers(brd_id);
  }, [brd_id]);

  useEffect(() => {
    if (brdData?.data) {
      const defaultOpt = [
        {
          title: "Choose Brands",
          value: "",
        },
      ];
      if (brdData?.data) {
        console.log("xwt bradat ===>", brdData?.data);
        const brdObj = brdData?.data?.brd_list?.map((brd: any) => {
          return {
            title: brd?.name,
            value: brd?.id,
          };
        });

        setbrands([...defaultOpt, ...brdObj]);
      }
    }
  }, [brdData]);

  const onSubmit = async (data: any) => {
    // console.log("inp data ===>", data);
    // return 0;
    try {
      const res = await insertInquiry({
        variables: {
          data: {
            from: data?.from?.iata_code,
            to: data?.to?.iata_code,
            flexible_arrival: data?.flexible_arrival == "yes" ? true : false,
            flexible_departure: data?.flexible_arrival == "yes" ? true : false,
            direct_flight: data?.direct_flight == "yes" ? true : false,
            preferred_airline: data?.preffered_airline?.iata,
            approx_budget: data?.approx_budget,
            payment_method_id: data?.payment_method_id,
            trip_type: data?.cabin,
            status: "pending",
            brd_id: data?.brd_id,
            inquiry_no: generateRandomNumberString(),
            thp_id: travelHouse?.id,
            user_id: data?.user_id,
            departure_datetime: moment(data?.departure)?.format("YYYY-MM-DD"),
            arrival_datetime: moment(data?.arrival)?.format("YYYY-MM-DD"),
            inq_passengers: {
              data: {
                first_name: "",
                type: "adult",
              },
            },
          },
        },
      });
      if (res?.data?.insert_inq_list_one?.id) {
        toast.success("Inquiry  inserted  successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setSidePopup(false);
        await client.refetchQueries({
          include: "all",
        });
      }
    } catch (error) {
      console.log("Error ===>", error);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-full p-4">
      <h2 className="flex justify-center font-extrabold	">Add Inquiry</h2>
      <hr />
      <div>
        <div className="flex w-full">
          <div className="flex-1 m-2">
            <SimpleSelect
              register={register}
              errors={errors}
              fieldName="brd_id"
              options={brands}
              label="Select Brand"
            />
          </div>
          <div className="flex-1 m-2">
            <SimpleSelect
              register={register}
              errors={errors}
              fieldName="user_id"
              options={users}
              label="Select User"
            />
          </div>
        </div>
        <div className="flex w-full">
          <div className="flex-1 m-2">
            <LocationBox
              control={control}
              errors={errors}
              setValue={setValue}
              fieldName={`from`}
              label={"from"}
              labelClasses="capitalize"
              placeholder="Choose your location"
            />
          </div>
          <div className="flex-1 m-2">
            <LocationBox
              control={control}
              errors={errors}
              setValue={setValue}
              fieldName={`to`}
              label={"To"}
              labelClasses="capitalize"
              placeholder="Choose your location"
            />
          </div>
        </div>
        <div className="flex w-full">
          <div className="flex-1 m-2">
            <SelectBox
              control={control}
              fieldName={"cabin"}
              label="Cabin Class"
              options={CabinClasses}
              selectedOption={CabinClasses[2]}
              inputClasses="border rounded border-gray-200 pl-3"
            />
          </div>
          <div className="flex-1 m-2">
            <AirlineBox
              control={control}
              errors={errors}
              setValue={setValue}
              fieldName={`preferred_airline`}
              label="Airline"
              placeholder="Choose your location"
              inputClasses={"p-[0px] m-[0px]"}
            />
          </div>
        </div>
        <div className="flex w-full">
          <div className="flex-1 m-2">
            <CalendarBox
              control={control}
              setValue={setValue}
              errors={errors}
              fieldName="departure"
              label="Departure Date"
            />
          </div>
          <div className="flex-1 m-2">
            <CalendarBox
              control={control}
              setValue={setValue}
              errors={errors}
              fieldName="arrival"
              label="Arrival Date"
            />
          </div>
        </div>

        {userDefaultRole !== "user" && (
          <>
            {paymentOptions?.length > 0 && (
              <div className="flex-1 m-2">
                <SelectBox
                  control={control}
                  fieldName={"payment_method_id"}
                  label="Payment Method"
                  options={paymentOptions}
                  inputClasses="border rounded border-gray-200 pl-3"
                  containerClasses="static"
                />
              </div>
            )}
          </>
        )}
        <div className="flex w-full">
          <div className="flex-1 m-2">
            <label className="">Flexible Departure</label>
            <div className="flex-1 self-stretch flex flex-row items-center justify-start gap-[30px]">
              <div className="flex flex-row items-center justify-center gap-[5px]">
                <InputField
                  label="Yes"
                  register={register}
                  type="radio"
                  errors={errors}
                  defaultValue={"yes"}
                  fieldName="flexible_departure"
                  containerClasses="flex"
                />
              </div>
              <div className="flex flex-row items-center justify-center gap-[5px]">
                <InputField
                  label="No"
                  register={register}
                  type="radio"
                  errors={errors}
                  defaultValue={"no"}
                  fieldName="flexible_departure"
                  containerClasses="flex"
                />
              </div>
            </div>
          </div>
          <div className="flex-1 m-2">
            <label className="">Flexible Arrival</label>
            <div className="flex-1 self-stretch flex flex-row items-center justify-start gap-[30px]">
              <div className="flex flex-row items-center justify-center gap-[5px]">
                <InputField
                  label="Yes"
                  register={register}
                  type="radio"
                  errors={errors}
                  defaultValue={"yes"}
                  fieldName="flexible_arrival"
                  containerClasses="flex"
                />
              </div>
              <div className="flex flex-row items-center justify-center gap-[5px]">
                <InputField
                  label="No"
                  register={register}
                  type="radio"
                  defaultValue={"no"}
                  errors={errors}
                  fieldName="flexible_arrival"
                  containerClasses="flex"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex w-full">
          <div className="flex-1 m-2">
            <InputField
              type="number"
              register={register}
              errors={errors}
              label="Approx Budget"
              fieldName="approx_budget"
              placeHolder="Approx Budget"
            />
          </div>
          <div className="flex-1 m-2">
            <label className="">Direct Flight</label>
            <div className="flex-1 self-stretch flex flex-row items-center justify-start gap-[30px]">
              <div className="flex flex-row items-center justify-center gap-[5px]">
                <InputField
                  label="Yes"
                  register={register}
                  type="radio"
                  errors={errors}
                  defaultValue={"yes"}
                  fieldName="direct_flight"
                  containerClasses="flex"
                />
              </div>
              <div className="flex flex-row items-center justify-center gap-[5px]">
                <InputField
                  label="No"
                  register={register}
                  type="radio"
                  errors={errors}
                  defaultValue={"no"}
                  fieldName="direct_flight"
                  containerClasses="flex"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex gap-2 w-full mt-2">
        <button
          className="w-full btn bg-green-500 text-white rounded-md p-2"
          type="submit"
        >
          Add
        </button>
      </div>
    </form>
  );
}
