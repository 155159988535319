import React from "react";
import InputField from "~/components/FormInputs/InputField";
import { useForm } from "react-hook-form";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { INSERT_TIPS, UPDATED_TIPS } from "./graphql/Mutation";
import { toast } from "react-toastify";
import { useFileUpload } from "@nhost/react";
import TipsFormCard from "./components/TipsFormCard";

const TipsSection = ({ page }: any) => {
  const { brd_id } = useParams();
  const { upload, progress: logoUploadProgress } = useFileUpload();
  const [updateTip] = useMutation(UPDATED_TIPS);
  const [insertTip] = useMutation(INSERT_TIPS);
  const client = useApolloClient();
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();
  console.log("xwt page ===>", page);
  const tips = page?.brd_flights_pages_tips;
  const onSubmit = async (inpData: any) => {
    console.log("xwt ipnData", inpData);
    inpData?.tips?.map(async (ft: any) => {
      if (ft?.id) {
        if (ft?.title !== "") {
          const finalObj: any = {
            heading: ft?.title,
            description: ft?.content,
          };
          if (ft?.icon?.length > 0) {
            const iconRes = await upload(ft?.icon[0]);
            finalObj["icon_url"] = iconRes?.id || null;
          }
          const res = await updateTip({
            variables: {
              id: { id: ft.id },
              data: finalObj,
            },
          });

          if (res?.data?.update_brd_flights_pages_tips_by_pk?.id) {
            toast.success("tips data updated successfully");
            await client.refetchQueries({
              include: "all",
            });
          }
        }
      } else {
        if (ft?.title) {
          const finalObj: any = {
            heading: ft?.title,
            description: ft?.content,
            flight_page_id: page?.id,
          };
          if (ft?.icon?.length > 0) {
            console.log("xwt icno upload ===>", ft?.icon[0]);
            const iconRes = await upload(ft?.icon[0]);
            console.log("xwt icno res ===>", iconRes);
            finalObj["icon_url"] = iconRes?.id || null;
          }
          const res = await insertTip({
            variables: {
              data: finalObj,
            },
          });
          if (res?.data?.insert_brd_flights_pages_tips_one?.id) {
            toast.success("tips data updated successfully");
            await client.refetchQueries({
              include: "all",
            });
          }
        }
      }
    });
    // console.log("xwt settings id", settings.id, settings?.id);
  };
  return (
    <div className="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800">
      <h3 className="mb-4 text-xl font-semibold dark:text-white">
        Important Tips section
      </h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-full flex space-x-9">
            <TipsFormCard
              key={0}
              ft={tips?.at(0)}
              index={0}
              register={register}
              errors={errors}
              setValue={setValue}
            />
            <TipsFormCard
              key={1}
              ft={tips?.at(1)}
              index={1}
              register={register}
              errors={errors}
              setValue={setValue}
            />
            <TipsFormCard
              key={2}
              ft={tips?.at(2)}
              index={2}
              register={register}
              errors={errors}
              setValue={setValue}
            />
            <TipsFormCard
              key={3}
              ft={tips?.at(3)}
              index={3}
              register={register}
              errors={errors}
              setValue={setValue}
            />
          </div>

          <div className="col-span-6 sm:col-full">
            <button
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
              type="submit"
            >
              Save Value Section
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default TipsSection;
