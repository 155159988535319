import SimpleSelect from 'components/FormInputs/SimpleSelect';
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { userReportOptions, userTypes } from './data/reportFormOptions';
import travelHouseStore from 'store/travelHouse/travelHouseStore';
import { useLazyQuery } from '@apollo/client';
import { FETCH_USERS } from './graphql/Query';
import { DateTimeBox } from 'components/FormInputs/DateTimeBox';
import { CalendarBox } from 'components/FormInputs/CalendarBox';

const ReportForm = ({onSubmit}:any) => {
  const [userList, setUserList] = useState([{
    title: 'Choose user...',
    value: ""
  }]);
  const {
    watch,
    formState: {errors},
    register,
    setValue,
    control,
    handleSubmit
  } = useForm()
  const {travelHouse}:any = travelHouseStore();
  const [getUsers, {loading, error, data:users}] = useLazyQuery(FETCH_USERS);

  const userType = watch("userType");
  const userID = watch("userID");
  const reportType = watch("reportType");


  const fetchUser = async (userRole:string) => {
    const {data} = await getUsers({variables: {th_id: travelHouse.id, role: userRole}})
    const finalObj = data?.user_travel_house.map((usr:any) => { 
      console.log(usr)
      const obj = {
        title: usr?.user?.displayName,
        value: usr?.user?.id
      }
      return obj;
    })

    // console.log('xwt users ====>', finalObj); 
    if(finalObj){
      setUserList([{title: 'Choose user', value: ''}, ...finalObj])
    }
   
  }


  useEffect(() => {
    fetchUser(userType)
  },[userType, userID, reportType])
  return (
    <div className="w-full">
      <form onSubmit={handleSubmit(onSubmit)} action="">
        <div className="@container">
          <div className="grid grid-cols-1 @md:grid-cols-2 @lg:grid-cols-5 gap-4 items-center">
              <SimpleSelect 
                fieldName='userType'
                register={register}
                errors={errors}
                options={userTypes}
                label='User type'
                required={true}
              />
              <SimpleSelect 
                fieldName='userID'
                register={register}
                errors={errors}
                options={userList}
                label='Users'
                required={true}
              />
              <SimpleSelect 
                fieldName='reportType'
                register={register}
                errors={errors}
                options={userReportOptions[userType]}
                label='Report type'
                required={true}
              />
              <CalendarBox 
                control={control}
                errors={errors}
                type='range'
                fieldName='date'
                setValue={setValue}
                label='Date range'
              />
              <input type="submit" value="Submit" />
          </div>
        </div>
      </form>
    </div>
  )
}

export default ReportForm