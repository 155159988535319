import { gql } from "@apollo/client";

export const GET_PAGE_DATA = gql`
  query MyQuery($id: uuid, $slug: String) {
    brd_pages(where: { slug: { _eq: $slug }, brd_id: { _eq: $id } }) {
      id
      created_at
      page_heading
      page_sub_heading
      page_sub_heading_2
      featured_image
      content_heading
      content_description
      name
      brd_seo_settings {
        id
        canonical_url
        author_company
        default_keywords
        default_description
        default_title
        locale
        og_image_url
        site_name
        twitter_card_type
        twitter_creator_handle
        twitter_site_handle
        page_id
        flight_page_id
        created_at
      }
      brd_list {
        brd_features {
          content
          brd_id
          created_at
          icon_url
          id
          link
          title
        }
      }
    }
  }
`;
